import NewBanner from "components/common/HomePage/Banner/NewBanner.js";
import ContinueWatching from "components/common/HomePage/ContinueWatching";
import LowerCard from "components/common/HomePage/LowerCard";
import StaticBanner from "components/common/HomePage/StaticBanner";
import BasicTabs from "components/common/HomePage/UpperTab";
import { useState, useEffect } from "react";
import SportsFixture from "./SportsFixture/SportsFixture";
import Reels from "../components/common/Reels";
import ReelsButton from 'components/common/HomePage/ReelsButton';
export default function Home() {
  const[isReelsVisible, setIsReelsVisible] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ margin: "0 0 40px 0" }}>
      <ReelsButton setIsReelsVisible={setIsReelsVisible}/>
      <NewBanner />
      <BasicTabs />
      <SportsFixture />
      <ContinueWatching />
      <StaticBanner />
      <LowerCard />
      <Reels isReelsVisible={isReelsVisible} setIsReelsVisible={setIsReelsVisible}/>
    </div>
  );
}
