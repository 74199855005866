import React, { Fragment } from "react";
import { Grid } from "@mui/material";

function MatchOfficialsData({isBrandingAvailable}) {
  return (
    <Fragment>
      <Grid className={`key-icon-container ${isBrandingAvailable ? 'match-official-corner-branding': ''}`}>
        <h2 style={{ marginTop: "20px" }}>KEY</h2>
        <div className="key-container">
          <div className="keykey-item-container">
            <img src="/images/Goal scored.svg" />
            <p>Goal Scored</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Own goal.svg" />
            <p>Own Goal</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Penalty missed.svg" />
            <p>Penalty Missed</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Assist.svg" />
            <p>Assist</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Subbed on.svg" />
            <p>Subbed On</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Subbed off.svg" />
            <p>Subbed Off</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/redcard.svg" />
            <p>Red Card</p>
          </div>
          <div className="keykey-item-container">
            <img src="/images/Yellow card (1).svg" />
            <p>Yellow Card</p>
          </div>
        </div>
      </Grid>
      <Grid className={`match-official-corner ${isBrandingAvailable ? 'match-official-corner-branding': ''}`}>
        <h2 className="match-official" style={{ marginTop: "20px" }}>
          MATCH OFFICIALS
        </h2>
        <Grid>
          <table>
            <tr>
              <td>Refree: </td>
              <td>Andre Marriner</td>
            </tr>
            <tr>
              <td>Assistants: </td>
              <td> Scott Ledger, Simon Long</td>
            </tr>
            <tr>
              <td>Fourth Officials: </td>
              <td> James Linington</td>
            </tr>
            <tr>
              <td>Var: </td>
              <td> Lee Mason</td>
            </tr>
            <tr>
              <td>Assistant VAR: </td>
              <td> Mark Scholes</td>
            </tr>
          </table>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MatchOfficialsData;
