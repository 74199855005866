import { Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMonthFromIndex } from "store/utils";
import SocialShare from "../../components/common/SocialShare";

export default function MatchInfo(props) {
  const { team1, team2, matchdata, isLive } = props;
  const Title = localStorage.getItem("team");
  const Description = localStorage.getItem("description");

  const url = `https://www.tothenew.com/`;

  const [clicked, setClicked] = useState(false);
  const showDiv = () => {
    setClicked(!clicked);
  };

  const getMatchDate = (date) => {
    let isoString = date
      .split("+")[0]
      .split("")
      .filter((x) => x.match(/\d/))
      .join("");
    isoString = isoString.replace(
      /^(....)(..)(..)(..)(..)(.*)$/,
      "$1-$2-$3T$4:$5:$6"
    );

    const dt = new Date(isoString);
    return (
      <>
        <span>{dt.getDate()}</span>
        <p>{getMonthFromIndex(dt.getMonth())}</p>
      </>
    );
  };

  const today = new Date();
  const currentDate = String(today.getDate()).padStart(2, '0');
  const currentMonth = today.toLocaleString('default', { month: 'long' });

  return (
    <Row id="pipVideo" style={{ marginBottom: "40px" }}>
    <Col span={22} offset={1} className="match-info">
      <Row wrap={false}>
        <Col span={20}>
          <div className="left-match-info-div">
            {isLive && <div className="match-date">
              <div className="match-info-date">
                <span>{currentDate}</span>
                <p>{currentMonth}</p>
              </div>
            </div>}
            <div className="match-info-detail">
              {Title != null && !isLive ? "" :<p className="match-info-detail-series">Emirates FA Cup 2022</p>
}              <p className="match-info-detail-name">
                  {Title != null && !isLive
                    ? `${Title}`
                    : Title != null && isLive
                    ? `${Title}`
                    : `${team1.teamName} vs ${team2.teamName}`}
                </p>
                <p className="match-info-detail-detail">
                  {Title != null && !isLive
                    ? `${Description}`
                    : Title != null && isLive
                    ? `${Description}`
                    : Title == null && isLive
                    ?`${team1.teamName} is leading on ${team2.teamName} in the third Emirates FA Cup 2022 at ${matchdata.matchdata.Stat[0]["@value"]}`
                    :`${Description}`
                    ?`${team1.teamName} is leading on ${team2.teamName} in the third Emirates FA Cup 2022 at ${matchdata.matchdata.Stat[0]["@value"]}`
                    :`${Description}`}
                </p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="add-and-share-icon">
              <Tooltip
                placement="top"
                align={{ offset: [0, 5] }}
                title={"Add to WatchList"}
              >
                <div className="icon">
                  <img src="/images/watchlist.svg" />
                </div>
              </Tooltip>

              <SocialShare url={url} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
