import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from "./Card";
import { liveStreamData, upcomingStreamData } from "./LiveStreamingdata";
import "./style.scss";

let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              // //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};
function UpperCard(props) {
  const { category, data, activeCategory, genreList, value } = props;
  const settings = {
    className: "slider variable-width",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    horrizontalSwiping: true,
    swipeToSlide: true
  };

  return (
    <div id="upper-cards-category" className="small-slider">
      <div className="live-streaming-container">
        <div className="card-container horrizontal-card-container">
          <Slider {...settings}>
            {/* {genreList.map((card, index) => (
              <div key={`genreList` + index}>
                <Card
                  image="/images/Potrait Card.png"
                  title={card.name}
                  genre="Paytm T20 Match"
                  type="landscape"
                />
              </div>
            ))} */}
            {value == "0"
              ? liveStreamData.map((livecard, index) => (
                  <div key={index}>
                    <Card
                      isLive={livecard.sportLive}
                      image={livecard.sportImg}
                      title={livecard.sportCategory}
                      ctitle={livecard.sportTitle}
                      csubtitle={livecard.sportSubtitle}
                      genre="Paytm T20 Match"
                      type="landscape"
                      showCategory= "true"
                    />
                  </div>
                ))
              : upcomingStreamData.map((livecard, index) => (
                  <div key={index}>
                    <Card
                      isLive={livecard.sportLive}
                      image={livecard.sportImg}
                      title={livecard.sportCategory}
                      ctitle={livecard.sportTitle}
                      csubtitle={livecard.sportSubtitle}
                      genre="Paytm T20 Match"
                      type="landscape"
                      showCategory= "true"
                    />
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  );

  // return (
  //   <Fragment>
  //     <div id="category-1">
  //       <div className="live-streaming-container">
  //         <div className="card-container">
  //           <Carousel
  //             cols={2}
  //             slidesToScroll={1}
  //             gap={30}
  //             containerStyle={{ width: "100%" }}
  //             scrollSnap
  //             responsiveLayout={[
  //               {
  //                 breakpoint: 1920,
  //                 cols: 5
  //               },
  //               {
  //                 breakpoint: 1600,
  //                 cols: 4
  //               },
  //               {
  //                 breakpoint: 1280,
  //                 cols: 3
  //               },
  //               {
  //                 breakpoint: 990,
  //                 cols: 2
  //               }
  //             ]}
  //             mobileBreakpoint={767}
  //           >
  //             {genreList.map((card, index) => (
  //               <Carousel.Item
  //                 key={`genreList` + index}
  //               >
  //                 <Card
  //                   image={img}
  //                   title={card.name}
  //                   genre="Paytm T20 Match"
  //                   type="landscape"
  //                 />
  //               </Carousel.Item>
  //             ))}
  //           </Carousel>
  //         </div>
  //       </div>
  //     </div>
  //   </Fragment>
  // );
}

export default UpperCard;
