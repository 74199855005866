import React from "react";
import PotraitCard from "./PotraitCard";
import data from "./continueWatching.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useHistory } from "react-router-dom";


let slidesToShow = 3;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              // //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function ContinueWatching() {
  const history = useHistory();

  const onCWCLick = (_item)=>{
    console.log("Metadata item",_item);
    localStorage.setItem("team", _item.metadata.title);
    localStorage.setItem("description", _item.metadata.subTitle);
    history.push(`/voddetail-${_item.id}-0`);
  }
  const settings = {
    className: "slider variable-width",
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    swipeToSlide: true,
    swipeable: true,
    horrizontalSwiping: true,
    swipeToSlide: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />
  };
  return (
    <div className="continue-watching small-slider">
      <div className="category-header">
        <div className="category-header-text">Continue Watching</div>
      </div>
      <div className="potrait-category-card">
        <Slider {...settings}>
          {data.map((_item) => (
            <PotraitCard
              image={_item.imageSource}
              description={_item.metadata.subTitle}
              percen={_item.progress}
              time={_item.timer}
              item={_item}
              setVODDetails={onCWCLick}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ContinueWatching;
