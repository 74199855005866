import React, { useEffect, useRef, useState } from "react";
import {
  convertHMStoSeconds,
  createDOMElement,
  toHHMMSS
} from "../../../store/utils";
import Slider from "@mui/material/Slider";
import "./style.scss";
import { timeLineMockData1, timeLineMockData2 } from "assets/timeline.mock.data";
import { timelineMarkerMockDAta } from "assets/tlm.assest.data";
import TLMMarker from "../TLMMarker";
import { connect } from "react-redux";
import { fetchTLMMarkers } from "store/actions/tlmActions";

function SeekBar(props) {
  const tlmData = useRef();
  const [stateTLMData, setStateTLMData] = useState([]);
  const [TLMPopupList, setTLMPopupList] = useState([]);
  // const [showSeekbar, setShowSeekbar] = useState(false);
  const [value, setValue] = useState(0);
  const { player, setTLMPlayer, isTLMEnabled, closeTLMPops, TLMCloseCallback, showGoLive } =
    props;
  const { isLive } = player.dataModel;
  const { NATIVE_EVENTS, CUSTOM_EVENTS } = props.videoPlayerEvents;
  const matchStartTime = player?.options?.playerMetadata?.startTime;
  const [duration, setDuration] = useState(0);
  const streamURL =  player?.options?.playerMetadata?.url;
  // console.log("Props on Seekbar:", props);
  const timeLineMockData = streamURL === 'https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/2-football/chelsea-vs-manu-fa-cup-2020-21.m3u8' ? timeLineMockData2 : timeLineMockData1;
  const playerCallback = (eventName, eventData) => {
    switch (eventName) {
      case NATIVE_EVENTS.LOADEDDATA:
        // console.log("Duration ", player.playerObj.duration());
        setDuration(player.playerObj.duration());
        toHHMMSS(duration);
        break;
      case NATIVE_EVENTS.SEEKBAR_UPDATE:
        // console.log("SeekBar Update : ", player.currentTime())
        if (!isLive) setValue(Math.floor(player.currentTime()));
        break;
      case NATIVE_EVENTS.TIMEUPDATE:
        if (!isLive) {
          // console.log(
          //   "TIMEUPDATE | current time : ",
          //   Math.floor(player.currentTime())
          // );
          // setValue(Math.floor(player.currentTime()));
        }
        break;
    }
  };

  const playerCustomCallback = (eventName, eventData) => {};

  const adCallback = (eventName, eventData) => {};

  useEffect(() => {
    if (player) {
      player.subscribeExternal({
        playerCallback,
        playerCustomCallback,
        adCallback,
        subscriberName: "SEEKBAR"
      });
    }
  }, [player]);

  useEffect(() => {
    if (isLive) {
      const interval = setInterval(() => {
        const currentEpochTimeTest = Math.floor(Date.now());
        const streamDurationTest =
          currentEpochTimeTest - parseInt(matchStartTime);
        const newTLMData = timeLineMockData.resultObj.containers.filter(
          (data) =>
            convertHMStoSeconds(
              data.metadata.emfAttributes.tl_marker.split("|")[1]
            ) *
              1000 <
            streamDurationTest
        );
        // console.log(
        //   "%cNew Temp Data : ",
        //   "color:white;font-size:20px",
        //   newTLMData.length,
        //   tlmData
        // );
        if (newTLMData.length > 0) {
          // setShowSeekbar(true);
        }
        tlmData.current = newTLMData;
        setStateTLMData(tlmData.current);
        props.fetchTLMMarkers(newTLMData);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (isLive && closeTLMPops) {
      closeAllTLMPopups();
      TLMCloseCallback();
    }
  }, [closeTLMPops]);

  const getMarkerCSS = (tlMarker) => {
    return (
      timelineMarkerMockDAta.find(
        (obj) =>
          obj.marker_id.toLowerCase() == tlMarker.split("|")[0].toLowerCase()
      ) || null
    );
  };

  const registerPopup = (data) => {
    const list = TLMPopupList;
    list.push(data);
    setTLMPopupList(list);
  };

  const closeAllTLMPopups = () => {
    TLMPopupList.forEach((data) => {
      data(false);
    });
  };

  const handleChange = (event, value) => {
    setValue(value);
    player.seek(value);
  };

  const handleGoLive = () => {
    setTLMPlayer({ enabled: false, playerConfig: "" });
  };

  const calculateTime = (value) => {
    if (value) {
      const { hours, minutes, seconds } = toHHMMSS(value);
      return hours ? [`${hours} : ${minutes} : ${seconds}`] : [`${minutes} : ${seconds}`]
    }
    return ["00:00:00"]
  };
  return (
    <div className="seekbar-wrapper" style={{ width: isLive ? "" : "100%" }}>
      {isLive ? (
        <div className="seekbar" id="seekbar">
          {stateTLMData &&
            stateTLMData.map((keyMoment, index) => {
              const currentEpochTime = Math.floor(Date.now());
              const streamDuration =
                currentEpochTime - parseInt(matchStartTime);
              const tl_marker = keyMoment.metadata.emfAttributes.tl_marker;
              const tl_time = tl_marker.split("|")[1];
              const markerCSS = getMarkerCSS(tl_marker);
              const plots = convertHMStoSeconds(tl_time);
              const percentage = (
                ((plots * 1000) / streamDuration) *
                100
              ).toFixed(2);
              if (markerCSS) {
                return (
                  <TLMMarker
                    left={percentage}
                    tl_marker={tl_marker}
                    keyMoment={keyMoment}
                    key={index}
                    registerPopup={registerPopup}
                    closeAllTLMPopups={closeAllTLMPopups}
                    setTLMPlayer={setTLMPlayer}
                    player={player}
                    index={index + 1 > 4 ? index - 3 : index + 1}
                  />
                );
              }
            })}
        </div>
      ) : (
        !isLive && (
          <div className="seekbar-vod">
            {isTLMEnabled && showGoLive && (
              <div className="go-live" onClick={handleGoLive}>
                Go Live
              </div>
            )}
            <Slider
              size="small"
              defaultValue={0}
              aria-label="Small"
              valueLabelDisplay="auto"
              min={0}
              max={duration}
              value={value}
              onChange={handleChange}
              scale={calculateTime}
            />
            <div className="video-time-duration">{`${
              toHHMMSS(parseInt(value)).minutes
            }:${toHHMMSS(parseInt(value)).seconds} / ${
              toHHMMSS(duration).minutes
            }:${toHHMMSS(duration).seconds}`}</div>
          </div>
        )
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchTLMMarkers: (markers) => dispatch(fetchTLMMarkers(markers))
});

export default connect((state) => {
  const { tlmMarkers } = state;
  return {
    tlmMarkers
  };
}, mapDispatchToProps)(SeekBar);
