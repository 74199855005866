import styled from "@emotion/styled";
import {Button, Popover} from "antd";

export const ButtonStyled = styled(Button)`
  display: flex;
  flex-direction: column;
  margin: 5px;
  background: #2f3b4e 0 0 no-repeat padding-box;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  border: none;
  
  :hover, :active, :focus{
    background: #2f3b4e 0 0 no-repeat padding-box;
  }

  img {
    margin: 10px;
    width: 29px;
    height: 29px;
  }
`;

export const PopoverStyled = styled(Popover)`
  
`;