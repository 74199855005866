import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import data from "./fbdata";
import MatchOfficialsData from "./MatchOfficialsData";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

export default function LineUpData(props) {
  const { team1,team2,isBrandingAvailable} = props;
  const { matchId } = useParams();
  useEffect(() => {
  }, []);

  const playingEleven = () => {
    return (
      <>
        <Grid>
          {data.argentina.playingEleven.map((data, index) => (
            <div style={{ display: "flex" }} key={index}>
              <p>{data.jerseyNumber}</p>
              <span>{data.playerName}</span>
              <span className="goal">{renderGoalType(data)}</span>
            </div>
          ))}
        </Grid>
      </>
    );
  };
  const substitue = () => {
    return (
      <Grid>
        {data.brazil.substitute.map((data, index) => (
          <div style={{ display: "flex" }} key={index}>
            <p>{data.jerseyNumber}</p>
            <span>{data.playerName}</span>
            <span className="goal">{renderGoalType(data)}</span>
          </div>
        ))}
      </Grid>
    );
  };

  const renderGoalType = (data) => {
    return (
      <>
        <div className="goal-area-detail">
          {data.redCard.time}
          <img
            src={data.redCard.image}
          />
        </div>

        <div className="goal-area-detail">
          {data.ownGoal.time}{" "}
          <img
            src={data.ownGoal.image}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="line-up-container">
        <div className="LineUp-container-div">
          <Row style={{ width: "100%", marginTop: "40px" }}>
            <Col span={9} className="position-div">
              <h2>Player's Position</h2>
              <img
                src="/images/football-ground.jpg"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
            <Col span={15} className="in-match-details">
              <Grid className="main-players">
                <div className="first-teams">
                  {/* <h2>{team1.teamName}</h2> */}
                    <h2>
                      {matchId == "g2257442" ? "Manchester City" : "Manchester United"}
                    </h2>
                  {playingEleven()}
                </div>
                <div className="second-teams">
                  {/* <h2>{team2.teamName}</h2> */}
                  <h2>
                    {matchId == "g2257442"
                                  ? "Chelsea"
                                  : "Middlesrough"}
                  </h2>
                  {playingEleven()}
                </div>
              </Grid>
              <Grid className="main-players">
                <div className="first-teams">
                  <Grid>
                    <h2 className="title-substitute">Substitutes</h2>
                  </Grid>
                  {substitue()}
                </div>
                <div className="second-teams">
                  <Grid>
                    <h2 className="title-substitute">Substitutes</h2>
                  </Grid>
                  {substitue()}
                </div>
              </Grid>
              <MatchOfficialsData isBrandingAvailable={isBrandingAvailable}/>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
