import React from "react";

function StaticBanner() {
  return (
    <>
      <img className = "static_banner" src="images/staticBanner.png" />
    </>
  );
}

export default StaticBanner;
