import CloseIcon from "@mui/icons-material/Close";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import GroupsSharpIcon from "@mui/icons-material/GroupsSharp";
import MessageSharpIcon from "@mui/icons-material/MessageSharp";
import PersonAddAlt1SharpIcon from "@mui/icons-material/PersonAddAlt1Sharp";
import SendIcon from "@mui/icons-material/Send";
import { blue } from "@mui/material/colors";
import WT from "@sscale/wtsdk";
import { Button, Col, Input, Row } from "antd";
import Picker from "emoji-picker-react";
import PopUp from "pages/MatchPlayer/WatchParty/PopUp.js";
import { useEffect, useRef, useState } from "react";
import ParticipantVideo from "./ParticipantVideo.js";
import "./style.scss";

/**
 * Watch Together Session
 *
 * WT.Session.onConnected - Method for setting up session when it ready
 * @param {onConnectedCallback} callback - Callback fired when session is ready
 * @callback onConnectedCallback
 * @param {Array} Participants - callback function contain an array of participants in the session
 *
 * WT.SessionListeners.onStreamCreated - Metod will allow you get information about new participants
 * @param {onStreamCreated} callback - Callback fired everytime when participant stream is reade
 * @callback onStreamCreated
 * @param {Object} ParticipantInfo - callback function contain object with participant information
 *
 * WT.ParticipantListeners.onParticipantLeft - Method will allow you get information about participant which left the session
 * @param {onParticipantLeftCallback} callback - Callback fired everytime when participant leave from the session
 * @callback onParticipantLeftCallback
 *
 * WT.Session.disconnect - Method will allow you disconnecting from the Session
 *
 * WT.Session.connect - Method will allow you connecting to the Session
 * @param {Object} SessionSettings - Information for creation the session
 * @param SessionSettings.sessionToken - session token
 * @param SessionSettings.wsUrl - WebSocket Url
 * @param SessionSettings.sessionId - The identifier of the session
 * @param {Object} UserInformation - Information about user
 * @param UserInformation.displayed_name - User ID
 *
 * WT.ErrorListeners.onSessionError - Method will allow you handling session errors
 * @param {onSesionErrorCallback} callback - Callbeck fired everytime when arises error with session
 * @callback onSesionErrorCallback
 * @param {Object} ErrorObject - Information about sesion error
 * @param code - error code
 * @param message - error message
 * @param requestTime - error time in unix timestamp
 */

export default function WatchTogether({
  roomName,
  syncToken,
  streamToken,
  enableSidebar,
  setWatchPartyEnabled
}) {
  const [currentParticipantId, setCurrentParticipantId] = useState("");
  const [showMessages, setShowMessages] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [incomingMsg, setIncomingMsg] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [currentMsg, setCurrentMsg] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [messages, addMessages] = useState([]);
  const [chatSelected, setChatSelected] = useState(false);

  const sampleNames = ["Caroline", "Jakob", "Robin", "Arthur", "Noah"];
  const displayName = "UserName";
  const isViewerMode = false;
  const participantsRef = useRef(participants);

  const pushNewMessage = (message) => {
    // addMessages([...messages, message]);
    setIncomingMsg(message);
  };

  // useEffect(() => {
  //   return () => {
  //     handleNavigateBack();
  //   };
  // });

  useEffect(() => {
    if (incomingMsg) {
      addMessages([...messages, incomingMsg]);
      setIncomingMsg(null);
    }
  }, [incomingMsg]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    console.log(participants);
    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    if (streamToken) {
      console.log(WT);
      WT.ErrorsListeners.onSessionError(onSessionError);
      WT.SessionListeners.onConnected(() => {
        setIsLoading(false);
        WT.SessionListeners.onStreamCreated(onStreamCreated);
        WT.SessionListeners.onMessageReceived(pushNewMessage);
        WT.ParticipantListeners.onParticipantLeft(onParticipantLeft);
        WT.ParticipantListeners.onParticipantMediaStreamChanged(
          onParticipantMediaStreamChanged
        );
      });
      startConnecting();
    }

    return () => {
      handleNavigateBack();
    };
  }, [streamToken]);

  function startConnecting() {
    const token = streamToken;
    if (!token) {
      throw new Error(`token is ${token}`);
    }
    const connect = isViewerMode
      ? WT.Session.connectAsAViewer
      : WT.Session.connect;
    connect(token, displayName);
  }

  function onStreamCreated(participant) {
    console.log({ participant });
    const { stream, participantId, local } = participant;
    if (local) {
      setCurrentParticipantId(participantId);
    }

    let participants = [
      ...participantsRef.current,
      { stream, participantId, isLocal: local }
    ];

    participants = participants.map((user, index) => {
      if (index < 6) {
        user.name = sampleNames[index];
      } else {
        user.name = "Test";
      }
      return user;
    });

    setParticipants(participants);
  }

  function onParticipantLeft(participant) {
    setParticipants(
      participantsRef.current.filter(
        (p) => p.participantId !== participant.participantId
      )
    );
  }

  function onParticipantMediaStreamChanged(mediaStreamChanged) {
    console.log(mediaStreamChanged);
  }

  function onSessionError(event) {
    const errorCode = event.code;
    switch (errorCode) {
      case 1100:
        const error = {
          message: "Party has ended."
        };
        console.log("Token Expired :: ", event);
        setError(error);
        break;
      default:
        setError(event);
        break;
    }
  }

  function handleNavigateBack() {
    WT.Session.disconnect();
  }

  const toggleAudio = () => {
    if (WT.Participant.isAudioEnabled()) {
      WT.Participant.disableAudio();
    } else {
      WT.Participant.enableAudio();
    }
  };

  const toggleVideo = () => {
    if (WT.Participant.isVideoEnabled()) {
      WT.Participant.disableVideo();
    } else {
      WT.Participant.enableVideo();
    }
  };

  const togglePopUp = () => {
    setIsPopUpOpen(true);
  };

  const shareScreen = () => {
    WT.Participant.startScreenSharing("test-video");
  };

  const toggleMessages = () => {
    setShowMessages(!showMessages);
    setChatSelected(!chatSelected);
    if(!chatSelected){
      document.getElementById("chat").classList.add('chat-active')
    }
    else{
      document.getElementById("chat").classList.remove('chat-active')
    }
  };

  const sendMessage = (e) => {
    if (currentMsg) {
      WT.Session.sendMessage(currentMsg);
      addMessages([
        ...messages,
        {
          message: currentMsg,
          participantId: currentParticipantId
        }
      ]);
      setCurrentMsg("");
    }
  };

  const getUserName = (id) => {
    const participant = participants.find((pcpt) => pcpt.participantId == id);
    if (participant) {
      return participant.name;
    }
    return "Test";
  };

  const renderMessage = (msg, index) => {
    const username = getUserName(msg.participantId);
    return (
      <div
        key={index}
        class={`${
          msg.participantId == currentParticipantId ? "current-user" : ""
        }`}
      >
        <span title={username} className="user-img">
          {username.split("")[0].toUpperCase()}
        </span>
        <span className="message-text" style={{ color: "white" }}>
          {msg.message}
        </span>
      </div>
    );
  };

  const onEmojiClick = (e, v) => {
    const { emoji } = v;
    setCurrentMsg(`${!currentMsg ? "" : currentMsg}${emoji}`);
  };

  const toggleEmojis = () => {
    setShowEmoji(!showEmoji);
  };

  return (
    <>
      <div className="watch-party-container watch-together-top">
        <Row className="watch-party-title">
          <div className="participant-left-div">
            <div className="icon-container">
              <GroupsSharpIcon className="icons" />
            </div>
            <span>Watch Party</span>
          </div>
          {true && (
            <div
              className="participant-right-div"
              style={{ marginLeft: "auto" }}
            >
              <Button
                type="text"
                style={{ padding: 0, verticalAlign: "middle" }}
              >
                <MessageSharpIcon
                  sx={{ color: "white" }}
                  onClick={toggleMessages}
                  style={{ verticalAlign: "middle" }}
                  id= "chat"
                />
              </Button>
              <Button
                className="banner-watch-now"
                onClick={() => {
                  handleNavigateBack();
                  setWatchPartyEnabled(false);
                  enableSidebar(false);
                }}
              >
                End Party
              </Button>
            </div>
          )}
        </Row>
      </div>
      {isLoading && (
        <div className="watch-together-loading">
          {/* Loading <span className="dot-flashing"></span> */}
          Connecting <span className="dot-pulse"></span>
        </div>
      )}
      {!isLoading && error && (
        <div className="watch-together-loading">{error.message}</div>
      )}
      {!isLoading && !error && (
        <div className="watch-party-container watch-together-bottom">
          <Row>
            <Col flex={"auto"} className="participantsCount">
              <GroupsOutlinedIcon sx={{ color: "white" }} />
              {participants && participants.length > 0
                ? `${participants.length} PEOPLE`
                : ""}
            </Col>
            <Col>
              <div style={{ display: "flex" }}>
                <button className="watch-party-invite-btn" onClick={togglePopUp}>
                  Invite people
                  <PersonAddAlt1SharpIcon
                    sx={{
                      color: blue[500],
                      float: "right",
                      marginLeft: "10px"
                    }}
                  />
                </button>
                {isPopUpOpen && (
                  <PopUp roomName={roomName} setIsPopUpOpen={setIsPopUpOpen} />
                )}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              maxHeight: "calc(100% - 20px)"
            }}
          >
            <Col span={24} className={"h-100"}>
              <Row
                gutter={16}
                className={"h-100"}
                style={{ overflow: "hidden auto" }}
              >
                {participants.length
                  ? participants.map((user, index) =>
                      user.stream ? (
                        <Col
                          span={showMessages ? 12 : 24}
                          key={user.participantId + 1}
                        >
                          <ParticipantVideo
                            name={sampleNames[index]}
                            participant={user}
                            isMuted={
                              user.isLocal
                                ? WT.Participant.isAudioEnabled()
                                : null
                            }
                            isVideoEnabled={
                              user.isLocal
                                ? WT.Participant.isVideoEnabled()
                                : null
                            }
                            participantListeners={WT.ParticipantListeners}
                            toggleAudio={toggleAudio}
                            toggleVideo={toggleVideo}
                          />
                        </Col>
                      ) : null
                    )
                  : null}
              </Row>
            </Col>
          </Row>
          <Row
            className={`${
              showMessages
                ? "messaging-wrapper"
                : "messaging-wrapper display-none"
            }`}
            style={{ marginTop: "auto" }}
          >
            <Col span={24}>
              <div className="messages-wrapper">
                <div className="messages-title">
                  Chat
                  <Button
                    type="text"
                    style={{ padding: 0, color: "white", height: "24px" }}
                    onClick={() => {
                      setShowMessages(false);
                      setChatSelected(false);
                      document.getElementById("chat").classList.remove('chat-active')
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                <div className="messages-list">
                  {messages && messages.map(renderMessage)}
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setShowEmoji(false);
                  sendMessage();
                }}
              >
                <Input.Group
                  compact
                  style={{
                    background: "#191924 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 36px #00000080"
                  }}
                >
                  <div className={`${showEmoji ? "" : "display-none"}`}>
                    <Picker onEmojiClick={onEmojiClick} />
                  </div>
                  <Button
                    type="text"
                    className="toggle-emoji-btn"
                    style={{
                      height: "40px",
                      width: "40px",
                      padding: "5px"
                    }}
                    onClick={toggleEmojis}
                  >
                    😊
                  </Button>
                  <Input
                    bordered={false}
                    className="message-input"
                    placeholder="Type Message"
                    value={currentMsg}
                    onClick={() => {
                      setShowEmoji(false);
                    }}
                    onChange={(e, v) => setCurrentMsg(e.target.value)}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={sendMessage}
                    style={{
                      height: "40px",
                      border: 0
                    }}
                  >
                    <SendIcon style={{ marginTop: "4px" }} />
                  </Button>
                </Input.Group>
              </form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
