import { Progress } from 'antd';
import { useHistory } from "react-router-dom";

export default function PotraitCard(props) {
  const { image, description, percen,time, item } = props;
  return (
    <div className="potrait-category" onClick = {props.setVODDetails.bind(null,item)}>
      <img src={image} style= {{    opacity: "0.59"}} />
      <div className="potrait-category-description ellipsis-text" style={{width: "100%"}}>{description}</div>
      <div className='potrait-category-progress'>
          <Progress percent={percen} showInfo={false}/>
        <span>{time}</span>
      </div>
      
    </div>
  );
}
