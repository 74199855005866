import styled from "@emotion/styled";
import { Col, Modal as AntModal } from "antd";

export const Modal = styled(AntModal)`
	.ant-modal-content {
		background: none;
		box-shadow: none;

		.ant-modal-body {
			padding: 0;
			}
		}
		.ant-modal-close{
			right: 14%;
			border-radius:50%;
			border: 1px solid white;
			
			&-x{
				display:flex;
				justify-content:center;
				align-items:center;
				width:35px;
				height:35px;
			}
			.anticon-close{
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
`;

export const ReelsVideoWrapper = styled.div`
	height: 69.5vh;
	border-radius: 10px;
	position: relative;
`;

export const ReelsMetaDataContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: end;
	color: white;
	font: normal normal medium 18px/24px Roboto;

	.reels-meta-play-btn, .reels-meta-action-btn, .reels-meta-actions-container, .reels-meta-details-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		color: unset;
		z-index: 4;
		}

	.reels-meta-details-container {
		width: 100%;
		padding: 1.5rem;
		display: flex;
		flex-direction: row;
		column-gap: 0.8rem;
		z-index: 4;

		.reels-meta-details-logo {

			> img {
				object-fit: contain;
				height: 50px;
				width: 50px;
				}
			}

		.reels-meta-details-title {
			font-weight: bold;
			font: normal normal bold 1.1rem/24px Roboto;
			}

		.reels-meta-details-sub-title {
			font: normal normal normal 1.3rem/24px Roboto;
			margin-top: 5px;
			}

		.reels-meta-details-title, .reels-meta-details-sub-title {
			// text-shadow: 1px 1px 3px grey;
			}
		}

	.reels-meta-actions-container {
		text-align: center;
		flex-direction: column;
		row-gap: 0.8rem;
		padding: 1.5rem;
		}

	.reels-meta-play-btn {
		height: 4rem;
		width: 4rem;
		background: #266dde;

		}

	.reels-meta-action-btn {
		height: 3rem;
		width: 3rem;
		background: rgba(255, 255, 255, 0.1);
		}

	.reels-meta-play-progress-btn-container {
		position: relative;

		.ant-progress {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 4;

			.ant-progress-inner {
				height: 4rem !important;
				width: 4rem !important;
				}
			}
		}
		.reels-player-shadow{
			position: absolute;
			height: 50%;
    		width: 100%;
			background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
			border-radius: 0px 0px 10px 10px;
			z-index: 3;
		}
		.reels-player-frame-image{
			position: absolute;
			height: 100%;
    		width: 100%;
			border-radius: 0px 0px 10px 10px;
			z-index: 2;
		}
`;

export const ReelsCarouselCol = styled(Col)`
	display: flex;
	justify-content: center;
	align-items: center;

	.reel-slide-nav-btn {
		border-radius: 8px;
		outline: none;
		box-shadow: none;
		color: unset;
		width: 3.25rem;
		height: 3.313rem;

		> span > svg {
			height: 1.5em;
			width: 1.5rem;
			}

		:active, :focus, :hover, :after, :before {
			transition: none;
			outline: none;
			box-shadow: none;
			border: none;
			}
			&:disabled {
				opacity: 0.7;
			}
		}

	.ant-carousel {
		height: 100%;
		width: 100%;
		border: 10px solid white;
		border-radius: 10px;
		background: white;
		}
`;

export const ReelsLoader= styled.div`
z-index: 9;
  width: 5vw;
  height: 5vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

