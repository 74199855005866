const liveStreamData = [
  {
    sportCategory: "Cricket",
    sportImg: "/images/cricket.png",
    sportTitle: "Paytm 5 T20 2022",
    sportSubtitle: "India vs South Africa",
    sportLive: "true"
  },

  {
    sportCategory: "Football",
    sportImg: "/images/football2.png",
    sportTitle: "EURO Cup",
    sportSubtitle: "Brazil vs Argentina",
    sportLive: "true"
  },
  {
    sportCategory: "WWE",
    sportImg: "/images/wwe2.png",
    sportTitle: "Wrestlemania 2022",
    sportSubtitle: "JohnCena vs Brock Lesnar",
    sportLive: "true"
  },
  {
    sportCategory: "Tennis",
    sportImg: "/images/tennis.png",
    sportTitle: "Davis Cup",
    sportSubtitle: "Andy Murray vs Stefenos",
    sportLive: "true"
  },
  {
    sportCategory: "UFC",
    sportImg: "/images/UFC.png",
    sportTitle: "UFC Title Fight",
    sportSubtitle: "Usman vs Namajunas",
    sportLive: "true"
  },
  {
    sportCategory: "Cycling",
    sportImg: "/images/LS1.png",
    sportTitle: "Cyclo-Cross",
    sportSubtitle: "Cycle race in New York this Sunday",
    sportLive: "true"
  },
  {
    sportCategory: "Rugby",
    sportImg: "/images/LS2.png",
    sportTitle: "Rugby League",
    sportSubtitle: "Ball Grabbers vs Grassy Nowells",
    sportLive: "true"
  },
  {
    sportCategory: "Ice Hockey",
    sportImg: "/images/LS3.png",
    sportTitle: "Women’s Hockey Match NY",
    sportSubtitle: "Canada vs U.S.",
    sportLive: "true"
  },
  {
    sportCategory: "Chess",
    sportImg: "/images/LS4.png",
    sportTitle: "Meltwater Champions Chess Tour 2022",
    sportSubtitle: "Norway vs Russia",
    sportLive: "true"
  },
  {
    sportCategory: "Baseball",
    sportImg: "/images/LS5.png",
    sportTitle: "MLB Game of the Week",
    sportSubtitle: "Guardians vs Mariners",
    sportLive: "true"
  },
  {
    sportCategory: "Volleyball",
    sportImg: "/images/LS6.png",
    sportTitle: "CEV EuroVolley 2023 Qualifiers",
    sportSubtitle: "Hungary vs Ukraine",
    sportLive: "true"
  }
];

const upcomingStreamData = [
  {
    sportCategory: "Tennis",
    sportImg: "/images/tennis.png",
    sportTitle: "Davis Cup",
    sportSubtitle: "Andy Murray vs Stefenos",
    sportLive: "false"
  },
  {
    sportCategory: "UFC",
    sportImg: "/images/UFC.png",
    sportTitle: "UFC Title Fight",
    sportSubtitle: "Usman vs Namajunas",
    sportLive: "false"
  },
  {
    sportCategory: "Cricket",
    sportImg: "/images/cricket.png",
    sportTitle: "Paytm 5 T20 2022",
    sportSubtitle: "India vs South Africa",
    sportLive: "false"
  },
  {
    sportCategory: "Football",
    sportImg: "/images/football2.png",
    sportTitle: "EURO Cup",
    sportSubtitle: "Real Madrid vs Argentina",
    sportLive: "false"
  },
  {
    sportCategory: "WWE",
    sportImg: "/images/wwe2.png",
    sportTitle: "Wrestlemania 2022",
    sportSubtitle: "JohnCena vs Brock Lesnar",
    sportLive: "false"
  },
  {
    sportCategory: "Cricket",
    sportImg: "/images/cricket.png",
    sportTitle: "Paytm 5 T20 2022",
    sportSubtitle: "India vs South Africa",
    sportLive: "false"
  },
  {
    sportCategory: "Ice Hockey",
    sportImg: "/images/LS3.png",
    sportTitle: "Women’s Hockey Match NY",
    sportSubtitle: "Canada vs U.S.",
    sportLive: "false"
  },
  {
    sportCategory: "Chess",
    sportImg: "/images/LS4.png",
    sportTitle: "Meltwater Champions Chess Tour 2022",
    sportSubtitle: "Norway vs Russia",
    sportLive: "false"
  },
];

export { upcomingStreamData, liveStreamData };
