import { makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Cards from "./fixtureCards";
import stats from "./Stats";
import LiveCardData from "./LiveData.json";
let slidesToShow = 3;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIosIcon
            style={{ color: "black", fontSize: "30px", padding: "2px" }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIosIcon
            style={{ color: "black", fontSize: "30px", padding: "2px" }}
          />
        </div>
      )}
    </>
  );
};

const useTabStyles = makeStyles({
  root: {
    justifyContent: "left",
    marginTop: "20px"
  },
  scroller: {
    flexGrow: "0"
  },
  customStyleOnTab: {
    color: "#ffffff;",
    opacity: "0.7",
    fontWeight: "400",
    textTransform: "none"
  },

  activeTab: {
    fontWeight: "500",
    color: "#ffffff",
    textTransform: "none"
  },
  box: {
    marginTop: "0px"
  }
});

export default function SportsFixture() {
  const history = useHistory();
  // console.log(stats.SoccerFeed.SoccerDocument, "Stats");

  const [fixtureData, setFixtureData] = useState({});
  const [upcomingData, setUpcomingData] = useState([]);
  const [highlightsData, setHighlightsData] = useState([]);
  const [currentTab, setCurrentTab] = useState("Live");
  const [liveData, setLiveData] = useState([]);
  const classes = useTabStyles();
  const SPORT = "Football";
  const MATCH_TYPE = {
    UPCOMING: "PreMatch",
    HIGHLIGHTS: "FullTime",
    LIVE: "Live"
  };

  useEffect(() => {
    axios({
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      url: "https://omo.akamai.opta.net/competition.php?competition=1&season_id=2021&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
      // url: "http://omo.akamai.opta.net/competition.php?competition=1&season_id=2021&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
      // url: "http://omo.akamai.opta.net/?game_id=2282505&feed_type=F7&user=ToTheNew&psw=ToTheNew2022&json"
      // F1:
      // url: "http://omo.akamai.opta.net/competition.php?competition=1&season_id=2021&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
      // url: "https://omo.akamai.opta.net/competition.php?competition=208&season_id=2022&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
      // F40:
      // url: "http://omo.akamai.opta.net/competition.php?competition=1&season_id=2021&feed_type=F40&user=ToTheNew&psw=ToTheNew2022&json"
      // url: "https://omo.akamai.opta.net/competition.php?competition=208&season_id=2022&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
    })
      .then((response) => {
        if ("SoccerFeed" in response.data) {
          setFixtureData(response.data.SoccerFeed.SoccerDocument);
          // console.log(response, "response in fixture card");
        } else {
          setFixtureData(stats.SoccerFeed.SoccerDocument);
        }
      })
      .catch((error) => {
        console.log(error, "error in fixture card");
        setFixtureData(stats.SoccerFeed.SoccerDocument);
        console.log(stats.SoccerFeed, "data in fixture card");
      });
  }, []);

  useEffect(() => {
    // console.log("fixtureData",fixtureData);
    if (fixtureData && "MatchData" in fixtureData) {
      const upcomingDataFiltered = [];
      const highlightsDataFiltered = [];
      fixtureData.MatchData.forEach((matchData) => {
        switch (matchData.MatchInfo["@attributes"].Period) {
          case MATCH_TYPE.UPCOMING:
            upcomingDataFiltered.push(matchData);
            break;
          case MATCH_TYPE.HIGHLIGHTS:
            highlightsDataFiltered.push(matchData);
            break;
        }
      });
      setHighlightsData(highlightsDataFiltered);
      setUpcomingData(upcomingDataFiltered);
    }
    if (LiveCardData && LiveCardData.length) {
      setLiveData(LiveCardData.map((item) => item.state.matchdata));
    }
  }, [fixtureData]);

  const redirect = (data, Teams) => {
    data = { ...data, Teams };
    // console.log("Teams", data);
    history.push("/matchdetail", data);
  };

  const settings = {
    className: "slider variable-width",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    variableWidth: true,
    infinite: false,
    speed: 300,
    horrizontalSwiping: true,
    swipeToSlide: true
  };

  const getCardsByType = (matchType, sports) => {
    let matchData = [];
    if (matchType == MATCH_TYPE.HIGHLIGHTS) {
      matchData = highlightsData;
    } else if (matchType == MATCH_TYPE.UPCOMING) {
      matchData = upcomingData;
    } else if (matchType == MATCH_TYPE.LIVE) {
      matchData = liveData;
      // console.log("Checking LiveData", LiveCardData[0].state.Teams);
    }
    return matchData.map((_item, key) => {
      return (
        <div key={key}>
          <Cards
            _item={_item}
            Teams={matchType === MATCH_TYPE.LIVE ? LiveCardData[0].state.Teams :fixtureData.Team} 
            matchType={matchType}
            sports={sports}
            redirect={redirect}
            competitionName={matchType === MATCH_TYPE.LIVE ? LiveCardData[0].state["@attributes"] : fixtureData["@attributes"]}
            live={matchType === MATCH_TYPE.LIVE}
          />
        </div>
      );
    });
  };

  const renderCarousel = (matchType, sports) => {
    return (
      <div id="upper-cards-category" className="small-slider">
        <div className="card-container sportsfixture-card-container">
          <Slider {...settings}>
            {getCardsByType(matchType, sports)}
            {/* {fixtureData &&
              fixtureData.MatchData &&
              fixtureData.MatchData.map((_item, key) => {
                if (_item.MatchInfo["@attributes"].Period === matchType) {
                  return (
                    <div key={key}>
                      <Cards
                        _item={_item}
                        // key={key}
                        Teams={fixtureData.Team}
                        matchType={matchType}
                        sports={sports}
                        redirect={redirect}
                        competitionName={fixtureData["@attributes"]}
                      />
                    </div>
                  );
                }
              })} */}
          </Slider>
        </div>
      </div>
    );
  };

  const renderLive = (sports) => {
    return (
      <div id="upper-cards-category" className="small-slider">
        <div className="card-container">
          <Slider {...settings}>
            <div>
              <Cards live={true} sports={sports} />
            </div>
          </Slider>
        </div>
      </div>
    );

    // return(
    //     <Carousel cols={3}
    //     rows={1}
    //     gap={20}
    //     containerStyle={{ width: "100%" }}
    //     scrollSnap>
    //         <Carousel.Item onClick={() => renderDetailPage()}>
    //             <Cards onClick={() => renderDetailPage()} live={true} sports={sports}  />
    //         </Carousel.Item>
    //     </Carousel>
    // )
  };

  const ACCOUNT_TABS = [
    {
      value: "Live",
      component: renderCarousel(MATCH_TYPE.LIVE, SPORT)
    },
    highlightsData.length > 0 && {
      value: "Highlights",
      component: renderCarousel(MATCH_TYPE.HIGHLIGHTS, SPORT)
    },
    upcomingData.length > 0 && {
      value: "Upcoming",
      component: renderCarousel(MATCH_TYPE.UPCOMING, SPORT)
    }
  ];

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Grid className="sport-fixture-container">
        <div className="sport-fixture-heading">Sports Fixture</div>
        <div>
          <Stack spacing={1}>
            <Tabs
              classes={{
                root: classes.root,
                scroller: classes.scroller,
                height: classes.height
              }}
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
              TabIndicatorProps={{
                style: { width: "35px", marginLeft: "17px" }
              }}
            >
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  className="fixture-tabs"
                  key={tab.value}
                  value={tab.value}
                  label={
                    <span
                      className={
                        tab.value == currentTab
                          ? classes.activeTab
                          : classes.customStyleOnTab
                      }
                    >
                      {tab.value}
                    </span>
                  }
                />
              ))}
            </Tabs>

            {ACCOUNT_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Stack>
        </div>
      </Grid>
    </>
  );
}
