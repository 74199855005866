import axios from "axios";
import BestMatch from "pages/Cards/BestMatch";
import SportCard from "pages/Cards/SportCard";
import TopLeague from "pages/Cards/TopLeague";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from "./Card";
import setVODDetail from '../../../store/actions/vodDataAction';
import { useDispatch } from "react-redux";

let slidesToShow = 6;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              // //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function LowerCard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [genreList, setGenreList] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedRail, setSelectedRail] = useState(null);

  const settings = {
    className: "slider variable-width",
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    swipeToSlide: true,
    swipeable: true,
    horrizontalSwiping: true,
    swipeToSlide: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />
  };
  useEffect(() => {
    axios({
      method: "get",
      url: "https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/category/5f45233ff0a4ca2277d30ca1?limit=10&offset=0"
    })
      .then((response) => {
        if (response?.data?.data?.results) {
          const filtereddata = response.data.data.results.filter((_item, index) => {
            if (_item.railType !== "HERO_BANNER") {
              return _item.contentEditorial;
            }
          });
          setGenreList(response.data.data.results);
          dispatch(setVODDetail(filtereddata));
        } else {
          setGenreList([]);
          dispatch(setVODDetail([]));
        }
      })
      .catch((error) => {
        console.log(error, "error in LowerCard--------");
        setGenreList([]);
        dispatch(setVODDetail([]));
      });
  }, []);

  const genreLists =
    genreList &&
    genreList.filter((_item, index) => {
      if (_item.railType !== "HERO_BANNER") {
        return _item.contentEditorial;
      }
    });
  const toggleActive = (e) => {
    document.getElementById(activeTab).classList.remove("active");
    document.getElementById(e.target.id).classList.add("active");
    setActiveTab(e.target.id);
  };
  const getCardDetail = (items, contentId) => {
    localStorage.setItem("team", items.content.title);
    localStorage.setItem("description", items.content.description);
    history.push(`/voddetail-${contentId}-1`);
  };

  return (
    <div id="lower-cards-category" className="small-slider">
      <div className="lower-div-container vertical-small-card-container">
        {genreLists && genreLists.length
          ? genreLists.map((item, index) => (
            (item.name == "Recommendation for you" ? "" :  <div className="category-header" key={index}>
            {item.contentEditorial && item.contentEditorial.length > 0 && (
              <>
                <div>
                {item.name == "Tournaments" ? (
                  <div className="filters-div">
                    <div className="filter">
                      <div id="all" className="filterName active" onClick={(e) => toggleActive(e)}  >All</div>
                      <div id="football" className="filterName" onClick={(e) => toggleActive(e)} >Football</div>
                        <div id="wrestling" className="filterName" onClick={(e) => toggleActive(e)} >Wrestling</div>
                        <div id="basketBall" className="filterName" onClick={(e) => toggleActive(e)} >BasketBall</div>
                        <div id="tennis" className="filterName"onClick={(e) => toggleActive(e)} >Tennis</div>
                        <div id="ufc" className="filterName"onClick={(e) => toggleActive(e)} >UFC</div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="category-header-text">{item.name}</div>
                  <div className="category-card">
                    <Slider {...settings}>
                      {item.contentEditorial.map((items, itemIndex) => (
                        <div key={itemIndex}>
                          <Card
                            image={items.content.posterImage}
                            title={items.content.title}
                            yearOfRelease={items.content.yearOfRelease}
                            item={items}
                             id={items.content.id}
                             railId= {item.id}
                            getCardDetail={getCardDetail}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                
              </>
            )}
          </div>)
             
            ))
          : null}
        <TopLeague />
        <SportCard />
        <BestMatch />
      </div>
    </div>
  );
}

export default LowerCard;
