const results = [
  {
    sport_category: "Football",
    player_name: "Rafael Nadal",
    player_image: "/images/Rafael.png",
    background_colour: "#F50661",
  },
 
  {
    sport_category: "Tennis",
    player_name: "Novak Djokovic",
    player_image: "/images/Novak.png",
    background_colour: "#FED500",
  },
  {
    sport_category: "Basket ball",
    player_name: "Carlos Alcaraz",
    player_image: "/images/BasketBall.png",
    background_colour: "#268557",
  },
  {
    sport_category: "Football",
    player_name: "Christiano Ronaldo",
    player_image: "/images/Ronaldo.png",
    background_colour: "#8D2C39",
  },
  {
    sport_category: "Football",
    player_name: "Lionel Messi",
    player_image: "/images/Messi.png",
    background_colour: "#B1062B",
  },
  {
    sport_category: "Cricket",
    player_name: "Virat Kohli",
    player_image: "/images/Kohli.png",
    background_colour: "#064EB1",
  },
  ,
  {
    sport_category: "Rugby",
    player_name: "Brett Favre",
    player_image: "/images/Rugby.png",
    background_colour: "#00B274",
  },

  {
    sport_category: "Football",
    player_name: "Rafael Nadal",
    player_image: "/images/Rafael.png",
    background_colour: "#F50661",
  },

  {
    sport_category: "Cricket",
    player_name: "Virat Kohli",
    player_image: "/images/Kohli.png",
    background_colour: "#064EB1",
  },

  {
    sport_category: "Tennis",
    player_name: "Novak Djokovic",
    player_image: "/images/Novak.png",
    background_colour: "#FED500",
  },

  {
    sport_category: "Basket ball",
    player_name: "Carlos Alcaraz",
    player_image: "/images/BasketBall.png",
    background_colour: "#268557",
  },

  {
    sport_category: "Football",
    player_name: "Christiano Ronaldo",
    player_image: "/images/Ronaldo.png",
    background_colour: "#8D2C39",
  },

  {
    sport_category: "Football",
    player_name: "Rafael Nadal",
    player_image: "/images/Rafael.png",
    background_colour: "#F50661",
  },

  
  {
    sport_category: "Rugby",
    player_name: "Brett Favre",
    player_image: "/images/Rugby.png",
    background_colour: "#00B274",
  },

 
];

const sports = [
  {
    image: "/images/Image 352@2x.png",
    hover_text: "Boxing"
  },
  {
    image: "/images/sport_rail.png",
    hover_text: "Football"
  },
  {
    image: "/images/Image 348@2x.png",
    hover_text: "Tennis"
  },
  {
    image: "/images/Image 350@2x.png",
    hover_text: " Rugby"
  },
  {
    image: "/images/Image 351@2x.png",
    hover_text: "Tennis "
  },

  {
    image: "/images/football.png",
    hover_text: "Football"
  },

  {
    image: "/images/Image 352@2x.png",
    hover_text: " Boxing"
  },

  {
    image: "/images/sport_rail.png",
    hover_text: "Basket ball "
  },


];

export {sports, results};
