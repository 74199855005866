export function convertHMStoSeconds(hms) {
  let temp = hms.split(":");
  return (
    Math.floor(temp[0]) * 60 * 60 +
    Math.floor(temp[1]) * 60 +
    Math.floor(temp[2])
  );
}
const teamData = {
  t4: {
    flagUrl: "/images/Flag8.png"
  },
  t17: {
    flagUrl: "/images/Flag1.png"
  },
  t38: {
    flagUrl: "/images/Flag2.png"
  },
  t585: {
    flagUrl: "/images/Flag3.png"
  },
  t30: {
    flagUrl: "/images/Flag4.png"
  },
  t3149: {
    flagUrl: "/images/Flag5.png"
  },
  t290: {
    flagUrl: "/images/Flag6.png"
  },
  t102: {
    flagUrl: "/images/Flag7.png"
  },
  t41: {
    flagUrl: "/images/Flag8.png"
  },
  t76: {
    flagUrl: "/images/Flag1.png"
  },
  t447: {
    flagUrl: "/images/Flag2.png"
  },
  t573: {
    flagUrl: "/images/Flag3.png"
  },
  t57: {
    flagUrl: "/images/Flag4.png"
  },
  t105: {
    flagUrl: "/images/Flag5.png"
  },
  t108: {
    flagUrl: "/images/Flag6.png"
  },
  t55: {
    flagUrl: "/images/Flag6.png"
  },
  t83: {
    flagUrl: "/images/Flag8.png"
  },
  t74: {
    flagUrl: "/images/Flag1.png"
  },
  t48: {
    flagUrl: "/images/Flag2.png"
  },
  t25: {
    flagUrl: "/images/Flag3.png"
  },
  t3: {
    flagUrl: "/images/Flag4.png"
  },
  t437: {
    flagUrl: "/images/Flag5.png"
  },
  t73: {
    flagUrl: "/images/Flag6.png"
  },
  t40: {
    flagUrl: "/images/Flag7.png"
  },
  t112: {
    flagUrl: "/images/Flag8.png"
  },
  t1525: {
    flagUrl: "/images/Flag1.png"
  },
  t288: {
    flagUrl: "/images/Flag2.png"
  },
  t285: {
    flagUrl: "/images/Flag3.png"
  },
  t31: {
    flagUrl: "/images/Flag4.png"
  },
  t69: {
    flagUrl: "/images/Flag5.png"
  },
  t2050: {
    flagUrl: "/images/Flag6.png"
  },
  t97: {
    flagUrl: "/images/Flag7.png"
  },
  t90: {
    flagUrl: "/images/Flag8.png"
  },
  t111: {
    flagUrl: "/images/Flag1.png"
  },
  t113: {
    flagUrl: "/images/Flag2.png"
  },
  t11: {
    flagUrl: "/images/Flag3.png"
  },
  t5: {
    flagUrl: "/images/Flag4.png"
  },
  t82: {
    flagUrl: "/images/Flag5.png"
  },
  t98: {
    flagUrl: "/images/Flag6.png"
  },
  t1: {
    flagUrl: "/images/Flag7.png"
  },
  t47: {
    flagUrl: "/images/Flag8.png"
  },
  t89: {
    flagUrl: "/images/Flag1.png"
  },
  t283: {
    flagUrl: "/images/Flag2.png"
  },
  t3448: {
    flagUrl: "/images/Flag3.png"
  },
  t45: {
    flagUrl: "/images/Flag4.png"
  },
  t20: {
    flagUrl: "/images/Flag5.png"
  },
  t51: {
    flagUrl: "/images/Flag6.png"
  },
  t2479: {
    flagUrl: "/images/Flag7.png"
  },
  t3120: {
    flagUrl: "/images/Flag8.png"
  },
  t2246: {
    flagUrl: "/images/Flag1.png"
  },
  t302: {
    flagUrl: "/images/Flag2.png"
  },
  t3265: {
    flagUrl: "/images/NewFlag3.png"
  },
  t50: {
    flagUrl: "/images/Flag4.png"
  },
  t21: {
    flagUrl: "/images/Flag5.png"
  },
  t3159: {
    flagUrl: "/images/Flag6.png"
  },
  t9: {
    flagUrl: "/images/Flag7.png"
  },
  t3145: {
    flagUrl: "/images/Flag8.png"
  },
  t1520: {
    flagUrl: "/images/Flag1.png"
  },
  t1493: {
    flagUrl: "/images/Flag2.png"
  },
  t35: {
    flagUrl: "/images/Flag3.png"
  },
  t2: {
    flagUrl: "/images/Flag4.png"
  },
  t296: {
    flagUrl: "/images/Flag5.png"
  },
  t37: {
    flagUrl: "/images/Flag6.png"
  },
  t72: {
    flagUrl: "/images/Flag7.png"
  },
  t103: {
    flagUrl: "/images/Flag8.png"
  },
  t2515: {
    flagUrl: "/images/Flag1.png"
  },
  t91: {
    flagUrl: "/images/Flag2.png"
  },
  t104: {
    flagUrl: "/images/Flag3.png"
  },
  t101: {
    flagUrl: "/images/Flag4.png"
  },
  t39: {
    flagUrl: "/images/Flag5.png"
  },
  t93: {
    flagUrl: "/images/Flag6.png"
  },
  t110: {
    flagUrl: "/images/Flag7.png"
  },
  t14: {
    flagUrl: "/images/Flag8.png"
  },
  t9368: {
    flagUrl: "/images/Flag1.png"
  },
  t820: {
    flagUrl: "/images/Flag2.png"
  },
  t77: {
    flagUrl: "/images/Flag3.png"
  },
  t2625: {
    flagUrl: "/images/Flag4.png"
  },
  t6: {
    flagUrl: "/images/Flag5.png"
  },
  t75: {
    flagUrl: "/images/Flag6.png"
  },
  t54: {
    flagUrl: "/images/Flag7.png"
  },
  t56: {
    flagUrl: "/images/Flag8.png"
  },
  t457: {
    flagUrl: "/images/Flag1.png"
  },
  t109: {
    flagUrl: "/images/Flag2.png"
  },
  t53: {
    flagUrl: "/images/Flag3.png"
  },
  t85: {
    flagUrl: "/images/Flag4.png"
  },
  t587: {
    flagUrl: "/images/Flag5.png"
  },
  t49: {
    flagUrl: "/images/Flag6.png"
  },
  t87: {
    flagUrl: "/images/Flag7.png"
  },
  t24: {
    flagUrl: "/images/Flag8.png"
  },
  t888: {
    flagUrl: "/images/Flag1.png"
  },
  t94: {
    flagUrl: "/images/Flag2.png"
  },
  t107: {
    flagUrl: "/images/Flag3.png"
  },
  t295: {
    flagUrl: "/images/Flag4.png"
  },
  t68: {
    flagUrl: "/images/Flag5.png"
  },
  t42: {
    flagUrl: "/images/Flag6.png"
  },
  t100: {
    flagUrl: "/images/Flag7.png"
  },
  t96: {
    flagUrl: "/images/Flag8.png"
  },
  t13: {
    flagUrl: "/images/Flag1.png"
  },
  t6152: {
    flagUrl: "/images/Flag2.png"
  },
  t7: {
    flagUrl: "/images/Flag3.png"
  },
  t671: {
    flagUrl: "/images/Flag4.png"
  },
  t99: {
    flagUrl: "/images/NewFlag1.png"
  },
  t586: {
    flagUrl: "/images/Flag6.png"
  },
  t22: {
    flagUrl: "/images/Flag7.png"
  },
  t33: {
    flagUrl: "/images/Flag8.png"
  },
  t3364: {
    flagUrl: "/images/Flag1.png"
  },
  t71: {
    flagUrl: "/images/MCity.png"
  },
  t46: {
    flagUrl: "/images/Flag3.png"
  },
  t92: {
    flagUrl: "/images/Flag4.png"
  },
  t88: {
    flagUrl: "/images/Flag5.png"
  },
  t80: {
    flagUrl: "/images/Flag6.png"
  },
  t44: {
    flagUrl: "/images/Flag7.png"
  },
  t106: {
    flagUrl: "/images/Flag8.png"
  },
  t78: {
    flagUrl: "/images/Flag1.png"
  },
  t81: {
    flagUrl: "/images/Flag2.png"
  },
  t2627: {
    flagUrl: "/images/Flag3.png"
  },
  t2623: {
    flagUrl: "/images/Flag4.png"
  },
  t43: {
    flagUrl: "/images/MCity.png"
  },
  t52: {
    flagUrl: "/images/Flag6.png"
  },
  t8: {
    flagUrl: "/images/Flag7.png"
  },
  t286: {
    flagUrl: "/images/Flag8.png"
  },
  t19: {
    flagUrl: "/images/Flag1.png"
  },
  t36: {
    flagUrl: "/images/Flag2.png"
  },
  t2048: {
    flagUrl: "/images/Flag3.png"
  },
  t6904: {
    flagUrl: "/images/Flag4.png"
  },
  t6907: {
    flagUrl: "/images/Flag5.png"
  },
  t1379: {
    flagUrl: "/images/Flag6.png"
  },
  t1376: {
    flagUrl: "/images/Flag7.png"
  },
  t1374: {
    flagUrl: "/images/Flag8.png"
  },
  t1377: {
    flagUrl: "/images/Flag1.png"
  },
  t10801: {
    flagUrl: "/images/Flag2.png"
  },
  t19066: {
    flagUrl: "/images/Flag3.png"
  },
  t1378: {
    flagUrl: "/images/Flag4.png"
  },
  t19317: {
    flagUrl: "/images/Flag5.png"
  },
  t6386: {
    flagUrl: "/images/Flag6.png"
  },
  t6905: {
    flagUrl: "/images/Flag7.png"
  },
  t5615: {
    flagUrl: "/images/Flag8.png"
  },
  t12275: {
    flagUrl: "/images/Flag1.png"
  },
  t7698: {
    flagUrl: "/images/Flag2.png"
  },
  t6903: {
    flagUrl: "/images/Flag3.png"
  },
  t11480: {
    flagUrl: "/images/Flag4.png"
  },
  t7697: {
    flagUrl: "/images/Flag5.png"
  }
};
export function getTeamFlag(tId) {
  if (tId in teamData) {
    return teamData[tId].flagUrl;
  }
  return "/images/flag1.png";
}
export function createDOMElement(dom) {
  const { innerHTML, attributes, ele, styles, event, innerText, parent } = dom;
  const element = document.createElement(ele);
  if (attributes) {
    Object.keys(attributes).forEach(function (key) {
      element.setAttribute(key, attributes[key]);
    });
  }
  if (styles) {
    Object.keys(styles).forEach(function (key) {
      element.style[key] = styles[key];
    });
  }
  if (innerHTML) {
    element.innerHTML = innerHTML;
  }
  if (innerText) {
    element.innerHTML = innerText;
  }
  if (event && !event.isDisabled) {
    element.addEventListener(event.type, event.listener, event.capturing);
  }
  parent && parent.appendChild(element);
  return element;
}

export function toHHMMSS(time, hourDigit = false) {
  time = time || 0;
  let sec_num = parseInt(time, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  if (hours < 10 && !hourDigit) {
    hours = hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return { hours, minutes, seconds };
}

export function stringToSlug(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "_")
    .replace(/^-+|-+$/g, "");
}

export function getMonthFromIndex(monthIndex) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return months[monthIndex];
}

const playerData = {
  p170437: {
    personName: {
      First: "Shamal",
      Last: "George"
    }
  },

  p439448: {
    personName: {
      First: "Ryan",
      Last: "Clampin"
    }
  },
  p15362: {
    personName: {
      First: "Luke",
      Last: "Chambers"
    }
  },
  p522058: {
    personName: {
      First: "Junior",
      Last: "Tchamadeu"
    }
  },
  p82689: {
    personName: {
      First: "Thomas",
      Last: "Eastman"
    }
  },
  p204381: {
    personName: {
      First: "Jorge Luiz",
      Known: "Jorginho"
    }
  },
  p438654: {
    personName: {
      First: "Brendan",
      Last: "Sarpong-Wiredu"
    }
  },
  p559096: {
    personName: {
      First: "Joshua",
      Last: "Blunkell"
    }
  },
  p559061: {
    personName: {
      First: "George",
      Last: "Keys"
    }
  },
  p559070: {
    personName: {
      First: "Joe",
      Last: "Grimwood"
    }
  },
  p87074: {
    personName: {
      First: "Reece",
      Last: "Harris"
    }
  },
  p559078: {
    personName: {
      First: "Jamie",
      Last: "Shaw"
    }
  },
  p204533: {
    personName: {
      First: "Marley",
      Last: "Andrews"
    }
  },
  p220635: {
    personName: {
      First: "Christian",
      Last: "Frimpong"
    }
  },
  p559068: {
    personName: {
      First: "Jake",
      Last: "Turner"
    }
  },
  p555906: {
    personName: {
      First: "Lewis",
      Known: "O'Malley"
    }
  },
  p481271: {
    personName: {
      First: "Shane",
      Last: "Temple"
    }
  },
  p559094: {
    personName: {
      First: "James",
      Last: "Askew"
    }
  },
  p559064: {
    personName: {
      First: "Ellis",
      Last: "Girling"
    }
  },
  p559081: {
    personName: {
      First: "Jake",
      Last: "Clowsley"
    }
  },
  p559085: {
    personName: {
      First: "Daniel",
      Last: "Gilchrist"
    }
  },
  p559093: {
    personName: {
      First: "Luke",
      Last: "Hipkin"
    }
  },
  p560169: {
    personName: {
      First: "Ethan",
      Last: "Mayhew"
    }
  },
  p560170: {
    personName: {
      First: "Alfie",
      Last: "Adams"
    }
  },
  p19910: {
    personName: {
      First: "Skuse",
      Last: "Cole"
    }
  },
  p445774: {
    personName: {
      First: "Noah",
      Last: "Chilvers"
    }
  },
  p49997: {
    personName: {
      First: "Freddie",
      Last: "Sears"
    }
  },
  p439278: {
    personName: {
      First: "Armando",
      Last: "Dobra"
    }
  },
  p223335: {
    personName: {
      First: "Sylvester",
      Last: "Jasper"
    }
  },
  p235192: {
    personName: {
      First: "Jake",
      Last: "Turner"
    }
  },
  p231826: {
    personName: {
      First: "Luke",
      Last: "Hannant"
    }
  },
  p461197: {
    personName: {
      First: "Chay",
      Last: "Cooper"
    }
  },
  p70755: {
    personName: {
      First: "Frank",
      Last: "Nouble"
    }
  },
  p221378: {
    personName: {
      First: "Shawn",
      Last: "McCoulsky"
    }
  },
  p231805: {
    personName: {
      First: "Miles",
      Last: "Welch-Hayes"
    }
  },
  p470259: {
    personName: {
      First: "Gene",
      Last: "Kennedy"
    }
  },
  p173812: {
    personName: {
      First: "Cameron",
      Last: "Coxe"
    }
  },
  p45099: {
    personName: {
      First: "Tommy",
      Last: "Smith"
    }
  },
  man53028: {
    personName: {
      First: "Hayden",
      Last: "Mullins"
    }
  },
  p446692: {
    personName: {
      First: "Christian",
      Last: "Benteke"
    }
  },
  p162818: {
    personName: {
      First: "Jordan",
      Last: "Ayew"
    }
  },
  p581255: {
    personName: {
      First: "Patrick",
      Last: "Vieira"
    }
  },
  p41184: {
    personName: {
      First: "Olivier",
      Last: "Paul"
    }
  },
  p113336: {
    personName: {
      First: "Alex",
      Last: "Marcus"
    }
  },
  p113300: {
    personName: {
      First: "Laurent",
      Last: "Antonio"
    }
  },
  p234295: {
    personName: {
      First: "Claudio",
      Last: "Joe"
    }
  },
  p86140: {
    personName: {
      First: "Launt",
      Last: "Antio"
    }
  },
  p468207: {
    personName: {
      First: "Juan",
      Last: "Claudio"
    }
  },
  p478798: {
    personName: {
      First: "Erik",
      Last: "Claudio"
    }
  },
  p429699: {
    personName: {
      First: "Ander",
      Last: "Ryan"
    }
  },
  p205801: {
    personName: {
      First: "Henrikh",
      Last: "Toby"
    }
  },
  p84461: {
    personName: {
      First: "Paul",
      Last: "Dele"
    }
  },
  p113143: {
    personName: {
      First: "Marcus",
      Last: "Eric"
    }
  },
  p83752: {
    personName: {
      First: "Joe",
      Last: "Christi"
    }
  },
  p230381: {
    personName: {
      First: "Marko",
      Last: "Harry"
    }
  },
  p196375: {
    personName: {
      First: "Peter",
      Last: "Hugo"
    }
  },
  p478739: {
    personName: {
      First: "Erik",
      Last: "Heung-M"
    }
  },
  p113467: {
    personName: {
      First: "Toby",
      Last: "Kyle"
    }
  },
  p113213: {
    personName: {
      First: "Ryan",
      Last: "Victor"
    }
  },
  p118372: {
    personName: {
      First: "Nacer",
      Last: "Iwobi"
    }
  },
  p155951: {
    personName: {
      First: "Ben",
      Last: "Jonny"
    }
  },
  p113200: {
    personName: {
      First: "Gareth",
      Last: "Ben"
    }
  },

  p84461: {
    personName: {
      First: "Matt",
      Last: "Gareth"
    }
  },
  p478859: {
    personName: {
      First: "Giround",
      Last: "Matt"
    }
  },
  p156047: {
    personName: {
      First: "Bellerin",
      Last: "Cech"
    }
  },
  p113099: {
    personName: {
      First: "Ozil",
      Last: "Giround"
    }
  },
  p206908: {
    personName: {
      First: "Koscielny",
      Last: "Bellerin"
    }
  },
  p210691: {
    personName: {
      First: "Ozil",
      Last: "Iwobi"
    }
  },
  p86547: {
    personName: {
      First: "Sanchez",
      Last: "Koscielny"
    }
  },
  p116731: {
    personName: {
      First: "Walcott",
      Last: "Ozil"
    }
  },
  p117225: {
    personName: {
      First: "Ollie",
      Last: "Sanchez"
    }
  },
  p113143: {
    personName: {
      First: "Alonso",
      Last: "Walcott"
    }
  },
  p169572: {
    personName: {
      First: "Azpilicue",
      Last: "Ollie"
    }
  },
  p169572: {
    personName: {
      First: "Slimani",
      Last: "Alonso"
    }
  },
  p181302: {
    personName: {
      First: "Aguero",
      Last: "Azpilicueta"
    }
  },
  p155951: {
    personName: {
      First: "De Bruynel",
      Last: "Cahill"
    }
  },
  p230532: {
    personName: {
      First: "Mahrez",
      Last: "Costa"
    }
  },
  p93984: {
    personName: {
      First: "Vardy",
      Last: "Courtois"
    }
  },
  p153460: {
    personName: {
      First: "Bravo",
      Last: "Hazard"
    }
  },
  p246414: {
    personName: {
      First: "Huth",
      Last: "Matic"
    }
  },
  p113240: {
    personName: {
      First: "Clichy",
      Last: "Moses"
    }
  },
  p246167: {
    personName: {
      First: "Iheanacho",
      Last: "Willian"
    }
  },
  p113062: {
    personName: {
      First: "Kolarov",
      Last: "Benteke"
    }
  },
  p243781: {
    personName: {
      First: "Sterlin",
      Last: "Amartey"
    }
  },
  p88177: {
    personName: {
      First: "Jonny",
      Last: "Huth"
    }
  },
  p88177: {
    personName: {
      First: "Victor",
      Last: "Mahrez"
    }
  },
  p180450: {
    personName: {
      First: "Kyle",
      Last: "Morgan"
    }
  },
  p80496: {
    personName: {
      First: "Kyle",
      Last: "Schmeichel"
    }
  },
  p446339: {
    personName: {
      First: "Heung-M",
      Last: "Slimani"
    }
  },
  p153460: {
    personName: {
      First: "Hugo",
      Last: "Vardy"
    }
  },
  p429593: {
    personName: {
      First: "Harry",
      Last: "Aguero"
    }
  },
  p180436: {
    personName: {
      First: "Eric",
      Last: "Bravo"
    }
  },
  p167858: {
    personName: {
      First: "Dele",
      Last: "Clichy"
    }
  },
  p116429: {
    personName: {
      First: "Phil",
      Last: "De Bruyne"
    }
  },
  p446551: {
    personName: {
      First: "Zlatan",
      Last: "Iheanacho"
    }
  },
  p180819: {
    personName: {
      First: "Ander",
      Last: "Jesus"
    }
  },
  p223925: {
    personName: {
      First: "Eric",
      Last: "Kolarov"
    }
  },
  p206908: {
    personName: {
      First: "Messi",
      Last: "Sane"
    }
  },
  p154308: {
    personName: {
      First: "Yaya",
      Last: "Silva"
    }
  },
  p185324: {
    personName: {
      First: "Raheem",
      Last: "Sterling"
    }
  },
  88177: {
    personName: {
      First: "David",
      Last: "Toure"
    }
  },

  p565075: {
    personName: {
      First: "Leroy",
      Last: "Bailly"
    }
  },
  p184911: {
    personName: {
      First: "Aleksan",
      Last: "De Gea"
    }
  },
  p116411: {
    personName: {
      First: "Gabriel",
      Last: "Herrera"
    }
  },
  p468212: {
    personName: {
      First: "Kelechi",
      Last: "Ibrahimovic"
    }
  },
  p209273: {
    personName: {
      First: "Kevin",
      Last: "Jones"
    }
  },
  p77940: {
    personName: {
      First: "Gael",
      Last: "Mata"
    }
  },

  p88177: {
    personName: {
      First: "Sergio",
      Last: "Mkhitaryan"
    }
  },

  p93984: {
    personName: {
      First: "Jamie",
      Last: "Eriksen"
    }
  },

  p180460: {
    personName: {
      First: "Islam",
      Last: "Pogba"
    }
  },
  p93984: {
    personName: {
      First: "Kasper",
      Last: "Rashford"
    }
  },

  p246167: {
    personName: {
      First: "Wes",
      Last: "Lloris"
    }
  },
  p113200: {
    personName: {
      First: "Riyad",
      Last: "Valencia"
    }
  },
  p37441: {
    personName: {
      First: "Robert",
      Last: "Wanyama"
    }
  },
  p468209: {
    personName: {
      First: "Daniel",
      Last: "Allen"
    }
  },
  p180436: {
    personName: {
      First: "Christi",
      Last: "Arnautovic"
    }
  },
  p180436: {
    personName: {
      First: "Victor",
      Last: "Foster"
    }
  },
  p55711: {
    personName: {
      First: "Nemanja",
      Last: "Crouch"
    }
  },
  p437523: {
    personName: {
      First: "Eden",
      Last: "Pieters"
    }
  },
  p475938: {
    personName: {
      First: "Thibaut",
      Last: "McAuley"
    }
  },
  p223981: {
    personName: {
      First: "Diego",
      Last: "Shawcross"
    }
  },
  p80502: {
    personName: {
      First: "Gary",
      Last: "Wanyama"
    }
  },
  p573317: {
    personName: {
      First: "Cesar",
      Last: "Alderweireld"
    }
  },
  p209273: {
    personName: {
      First: "Marcos",
      Last: "Cech"
    }
  },
  p113390: {
    personName: {
      First: "Theo",
      Last: "Alli"
    }
  },
  p249551: {
    personName: {
      First: "Alexis",
      Last: "Dier"
    }
  },
  p446692: {
    personName: {
      First: "Mesut",
      Last: "Eriksen"
    }
  }
};

export function getPlayerName(tId) {
  if (tId in playerData) {
    const { First, Last } = playerData[tId].personName;
    return `${First} ${Last}`;
  }
  return tId;
}
