import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../components/common/style.scss";
import { results } from "./sportsdata";
let slidesToShow = 6;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function BestMatch() {
  const settings = {
    className: "slider variable-width",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    horrizontalSwiping: true,
    swipeToSlide: true
  };


  return (
    <div id="lower-cards-category" className="small-slider best-matches">
      <div className="category-header">
        <div className="category-header-text">Best Matches of Sports Stars</div>
      </div>
      <div className="card-container category-card">
        <Slider {...settings}>
          {results.map((sportCard, index) => (
            <div key={index}>
              <div className="best-match-container">
                <div className="best-match-container-image" style = {{backgroundColor: sportCard.background_colour}}>
                  <div style={{ width: "100%", height: "100%"
 }}>
                    <img
                      src={sportCard.player_image}
                      className="sports-card-image"
                    />
                  </div>
                </div>
              </div>
              <div className="best-match-container-details">
                <p>{sportCard.player_name}</p>
                <span>{sportCard.sport_category}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );

  // return (
  //   <Fragment>
  //     <div className="lower-container">
  //       <div className="category-header">
  //         <div className="category-header-text">Best Matches</div>
  //         <div
  //           className="category-card"
  //           style={{ marginLeft: "40px", display: "flex" }}
  //         >
  //           {results.map((sportCard, index) => (
  //             <Grid key={index}>
  //               <div className="best-match-container">
  //                 <div className="best-match-container-image">
  //                   <img
  //                     src={sportCard.player_image}
  //                     className="sports-card-image"
  //                   />
  //                 </div>
  //               </div>
  //               <div className="best-match-container-details">
  //                 <p>{sportCard.player_name}</p>
  //                 <span>{sportCard.sport_category}</span>
  //               </div>
  //             </Grid>
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   </Fragment>
  // );
}

export default BestMatch;
