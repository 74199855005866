import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../components/common/style.scss";
import { sports } from "./sportsdata";
let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function SportCard() {
  const settings = {
    className: "slider variable-width",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    horrizontalSwiping: true,
    swipeToSlide: true
  };

  return (
    <div id="lower-cards-category" className="sports-card-slider">
      <div className="category-header" id="sport-card">
        <div className="category-header-text">Sports</div>
      </div>
      <div className="category-card card-container">
        <Slider {...settings}>
          {sports.map((sportCard, index) => (
            <div key={index}>
              <div className="card">
                <img
                  src={sportCard.image}
                  className="sports-card-image card-theme"
                />
                <div className="hovered-text">{sportCard.hover_text}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );

  // return (
  //   <Fragment>
  //     <Grid className="category-header" id="sport-card">
  //       <div className="category-header-text">Sports</div>
  //       <div className="category-card" style={{ marginLeft: "40px", display: "flex" }}>
  //         {results.map((sportCard, index) => (
  //           <div className="card" key={index}>
  //             <img
  //               src={sportCard.image}
  //               className="sports-card-image card-theme"
  //             />
  //             <div className="hovered-text">{sportCard.sport_category}</div>
  //           </div>
  //         ))}
  //       </div>
  //     </Grid>
  //   </Fragment>
  // );
}

export default SportCard;
