import React, { memo, useContext, useEffect, useState } from "react";
import PipContext from "../../../helpers/contexts/PipContext";
import adBottom from "../../../images/adBottom.png";
import adLeft from "../../../images/adLeft.png";
import axios from "axios";
import "./style.scss";

/**
 * AdLBand component renders add
 * This is wrapper component to render a L band advertisement.
 * take children and isForMatchDetailPlayer props
 * Note: isForMatchDetailPlayer is by default set to false and is to be set to true if MatchDetails video component
 * is being wrapped by this component due to css check cases.
 * @type {React.NamedExoticComponent<{readonly isForMatchDetailPlayer?: *, readonly children?: *}>}
 */

const AdLBand = memo(({ children, isForMatchDetailPlayer = false }) => {
    const { isVisible, setIsVisible, isAdVisible, setIsAdVisible } = useContext(PipContext);
    const [lBannerList, setLBannerList] = useState([]);
    const [currentLBannerIndex, setCurrentLBanner] = useState(0);
    const [lbannerIndex, setLBannerIndex] = useState(0);
    const imagePathPrefix = "https://dn8wbd49q0doa.cloudfront.net/";

    useEffect(async () => {
      try {
        const response = await axios({
          method: "get",
          url: "https://vrsports-tothenew.videoready.tv/gateway/editorial/lband/getactive"
        });
        if (response?.data?.data) {
          setLBannerList(response.data.data);
        } else {
          setLBannerList([]);
        }
      } catch (error) {
        setLBannerList([]);
      }
    }, []);

    useEffect(() => {
      if (lBannerList.length > 0) {
        const currentLBanner = lBannerList[currentLBannerIndex];
        const intervalInSec = getInterval(
          currentLBanner.frequency * 1000,
          currentLBanner.duration * 1000
        );
        const timeout = setTimeout(() => clearInterval(intervalInSec), (currentLBanner.frequency + 1) * 1000); // clear intervalInSec just after its executed

        return () => {
          clearTimeout(timeout);
          clearInterval(intervalInSec);
        };
      }
    }, [isVisible, lBannerList, currentLBannerIndex]);

    const getInterval = (intervalDelay, timeoutDelay) =>
      setInterval(() => {
        setLBannerIndex(currentLBannerIndex);
        toggleAd();
        setTimeout(() => {
          toggleAd();
          const index = lBannerList.length === currentLBannerIndex + 1 ? 0 : currentLBannerIndex + 1;
          setCurrentLBanner(index);
        }, timeoutDelay);
      }, intervalDelay);

    // HIDE SHOWS THE AD BAND

    const toggleAd = () => {
      setIsAdVisible((p) => {
        if (isForMatchDetailPlayer) {
          const dp = document.getElementsByClassName("player-height");
          const leftband = document.getElementById("leftBanner");
          const fulEl = document.fullscreenElement;
          const display = document.getElementsByClassName(
            "detail-player-wrapper"
          );
          if (fulEl && fulEl.getAttribute("id") === "player-with-lband") {
            if (!p && dp && dp.length) {
              leftband?.setAttribute("style", "width: 20%");
              dp[0].style.transition = "height 500ms linear";
              dp[0].style.height = "85vh";
            } else {
              leftband?.setAttribute("style", "width: 0");
              dp[0].style.height = "100vh";
            }
          } else {
            leftband?.removeAttribute("style");
            if (!p && dp && dp.length) {
              dp[0].style.transition = "height 500ms linear";
              dp[0].style.height = isVisible ? "55vh" : "36vh";
              dp[0].style.minHeight = isVisible ? "450px" : "0";
              display[0].style.minHeight = isVisible ? "450px" : "0";
            } else {
              dp[0].style.height = isVisible ? "85vh" : "36vh";
              dp[0].style.minHeight = isVisible ? "450px" : "0";
              display[0].style.minHeight = isVisible ? "450px" : "0";
            }
          }
          return !p;
        }
      });
    };
    return (
      <div id={"player-with-lband"} style={{ height: "100%", width: "100%" }}>
        {lBannerList.length && (
          <>
            <div className={"lBandContainer"}>
              <div
                className={`leftBanner ${isAdVisible ? "showLeft" : ""}`}
                id={"leftBanner"}
              >
                <a
                  href={lBannerList[lbannerIndex].ctaUrl}
                  target="_blank"
                >
                  <img
                    src={
                      imagePathPrefix +
                      lBannerList[lbannerIndex].verticalBannerImage
                    }
                    alt="advertisement"
                    width="100%"
                  />
                </a>
              </div>
              <div
                className={
                  isVisible
                    ? `${"lBandContentContainer"}`
                    : `${"pipContainer pipAdLBand"}`
                }
                id="pipAdBand"
              >
                {children}
              </div>
            </div>
            <div
              className={`bottomBanner ${isAdVisible ? "showBottom" : ""}`}
              id="bottomBanner"
            >
              <a href={lBannerList[lbannerIndex].ctaUrl} target="_blank">
                <img
                  src={
                    imagePathPrefix +
                    lBannerList[lbannerIndex].horizontalBannerImage
                  }
                  alt="image"
                  preview={false}
                  width={"100%"}
                />
              </a>
            </div>
          </>
        )}
      </div>
    );
  },
  (p, n) => p.children !== n.children
);

export default AdLBand;
