import { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import Card from "../../components/common/HomePage/Card";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
let slidesToShow = 4;

const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const settings = {
  className: "slider variable-width",
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: false,
  speed: 300,
  swipeToSlide: true,
  swipeable: true,
  horrizontalSwiping: true,
  swipeToSlide: true,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />
};

export default function Recommendations() {
  const [recommendation, setRecommendation] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: "https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/category/5f45233ff0a4ca2277d30ca1?limit=10&offset=0"
    })
      .then((response) => {
        if (response?.data?.data?.results) {
          setRecommendation(response.data.data.results);
        } else {
          setRecommendation([]);
        }
      })
      .catch((error) => {
        setRecommendation([]);
      });
  }, []);

  const recommendationLists =
    recommendation &&
    recommendation.filter((_item, index) => {
      if (_item.name == "Recommendation for you") {
        return _item.contentEditorial;
      }
    });

  return (
    <>
      {recommendationLists.map((item, index) => (
        <div id="recommendation">
          <div className="category-header" key={index}>
            {item.contentEditorial && item.contentEditorial.length > 0 && (
              <>
                <div className="category-header-text">{item.name}</div>
                <div className="category-card">
                  <Slider {...settings}>
                    {item.contentEditorial.map((items, itemIndex) => (
                      <div key={itemIndex}>
                        <Card
                          image={
                            "https://dn8wbd49q0doa.cloudfront.net/" +
                            items.content.coverImage
                          }
                          csubtitle={items.content.description}
                          item={items}
                          type="landscape"
                          showCategory= "false"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
