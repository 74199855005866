import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import StatsData from "./StatsData";
import LineUpData from "./LineUpData";

import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { connect } from "react-redux";

const useTabStyles = makeStyles({
  root: {
    justifyContent: "center",
    marginTop: "20px"
  },
  scroller: {
    flexGrow: "0"
  },

  customStyleOnTab: {
    color: "#BCBCBC;",
    textTransform: "none",
    fontWeight: "400"
  },

  activeTab: {
    fontWeight: "500",
    color: "#ffffff",
    textTransform: "none"
  }
});
const FootballTab = (props) => {
  const { matchdata,team1,team2, setSelectedTab, isBrandingAvailable } = props;


  let tabs = [
    {
      value: "Stats",
      component: <StatsData team1={team1} team2={team2} />
    },
    {
      value: "Lineups",
      component: <LineUpData team1={team1} team2={team2} isBrandingAvailable={isBrandingAvailable}/>
    }
  ];

  const classes = useTabStyles();

  const [active, setActive] = useState("Lineups");

  const handleChangeTab = (event, newValue) => {
    setActive(newValue);
    setSelectedTab(newValue)
  };



  return (
    <div id="football-tab">
      <Box
        sx={{
          // borderBottom: 1,
          // borderColor: "divider",
          // borderBlockColor: "#868692",
          margin: "0 20px"
        }}
      >
        <Tabs
          classes={{
            root: classes.root,
            scroller: classes.scroller,
            height: classes.height
          }}
          value={active}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{
            style: { width: "35px", marginLeft: "17px" }
          }}
          variant={"scrollable"}
          scrollButtons={true}
        >
          {tabs.map((tab, value) => {
            // console.log(tab);
            return (
              <Tab
                style={{ height: "62px" }}
                key={tab.value}
                value={tab.value}
                label={
                  <span
                    className={
                      tab.value == active
                        ? classes.activeTab
                        : classes.customStyleOnTab
                    }
                  >
                    {tab.value}
                  </span>
                }
              />
            );
          })}
        </Tabs>
      </Box>

      {tabs.map((tab) => {
        const isMatched = tab.value === active;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { matchdata } = state;
  return { matchdata };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FootballTab);
