import React, {useContext} from "react";
import PipContext from "../../../helpers/contexts/PipContext";
import "../MatchDetailPlayer/style.scss";
import "../VideoPlayer/style.scss";
import "../AdLBand/style.scss";

const Pip = ({children}) => {

  const {isVisible, setIsVisible} = useContext(PipContext);

  return (
    <div style={{height: "85vh"}}>
      {children}
    </div>
  )
}

export default Pip;