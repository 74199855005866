import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import Card from "./UperCard";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import UpperCard from "./UperCard";

const useTabStyles = makeStyles({
  root: {
    justifyContent: "left",
    marginTop: "30px"
  },
  scroller: {
    flexGrow: "0"
  },

  customStyleOnTab: {
    color: "#ffffff;",
    opacity: "0.7",
    fontWeight: "400",
    textTransform: "none"
  },

  activeTab: {
    fontWeight: "500",
    color: "#ffffff",
    textTransform: "none"
  }
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function BasicTabs() {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [genreList, setGenreList] = useState([]);
  const [value, setValue] = React.useState(0);
  const classes = useTabStyles();

  useEffect(() => {
    async function fetchCategories() {
      let response = await fetch(
        "https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/categorylist"
      );
      response = await response.json();
      const data = response.data;
      setActiveCategory(data[0]);
      setCategories(response.data);
    }
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!activeCategory.id) {
      return null;
    }
    async function fetchCategoriesList() {
      let response = await fetch(
        `https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/category/${activeCategory.id}?limit=10&offset=0`
      );
      response = await response.json();
      const data = response.data;
      setGenreList([...data.results]);
    }
    fetchCategoriesList();
  }, [activeCategory, JSON.stringify(genreList)]);

  const renderCategories = () => {
    return categories.map((category, index) => (
      <Tab
        key={category.name}
        value={index}
        label={
          <span
            className={
              value == index ? classes.activeTab : classes.customStyleOnTab
            }
          >
            {category.name}
          </span>
        }
      />
    ));
  };

  const handleChange = (event, value) => {
    setValue(value);
    setActiveCategory({ ...categories[value] });
  };

  return (
    <Fragment>
      {genreList && genreList.length > 0 ? (
        <div className="upper-tab-container">
          <Tabs
            classes={{
              root: classes.root,
              scroller: classes.scroller,
              height: classes.height
            }}
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            TabIndicatorProps={{
              style: { width: "35px", marginLeft: "17px" }
            }}
          >
            {renderCategories()}
          </Tabs>
          <TabPanel value={value} index={value}>
            {/* <UpperCard activeCategory={activeCategory} genreList={genreList} /> */}
            <UpperCard value={value} />

          </TabPanel>
        </div>
      ) : null}
    </Fragment>
  );
}
