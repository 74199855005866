import React, { memo, useEffect, useMemo, useState } from "react";
import { ReelsMetaDataContainer, ReelsLoader } from "./styled-commponents";
import { Button, Progress } from "antd";
import { FavoriteBorderOutlined, PlayArrowRounded, ShareOutlined } from "@mui/icons-material";
import Icon from "@ant-design/icons";
import { VolumeIcon, VolumeMuteIcon } from "./assets";
import useReelsHooks from "./hooks/useReelsHooks";
import loader from "../../../images/vr_loader.gif";

const ReelsMetaData = memo(({ metadata, videoPlayerInstance, playNextReel, isCurrentSlideActive }) => {
  const { isMute, muteToggle, share, like, playPause, reelDuration, reelCurrentPlayTime, isReelEnded, showReelsLoader } = useReelsHooks(videoPlayerInstance);
  const [hideLoader, setHideLoader] = useState(false);

  useEffect(() => {
    if(!showReelsLoader) {
      setHideLoader(true);
    }
  }, [showReelsLoader])

  if(isCurrentSlideActive && isReelEnded){
    playNextReel();
  }

  return (
    <ReelsMetaDataContainer
      id={"ttn-reels-meta-data-container"}
      key={metadata.id}
    >      
      { !hideLoader && <ReelsLoader><img src={loader} /></ReelsLoader> }
      <div className={"reels-meta-details-container"}>
        <div className={"reels-meta-details-logo"}>
          <img src={metadata.logo} alt={metadata.alt} />
        </div>
        <div className={"reels-meta-details-text"}>
          <div className={"reels-meta-details-title"}>{metadata.title}</div>
          <div className={"reels-meta-details-sub-title"}>
            {metadata.subTitle}
          </div>
        </div>
      </div>
      <div className={"reels-meta-actions-container"}>
        <span>
          <Button
            className={"reels-meta-action-btn"}
            icon={<FavoriteBorderOutlined style={{ color: "white" }} />}
            shape={"circle"}
            onClick={() => like()}
          />
          {metadata.likes}
        </span>
        <span>
          <Button
            className={"reels-meta-action-btn"}
            icon={<ShareOutlined style={{ color: "white" }} />}
            shape={"circle"}
            onClick={() => share()}
          />
          {metadata.shares}
        </span>
        <Button
          className={"reels-meta-action-btn"}
          icon={
            isMute ? (
              <Icon component={VolumeMuteIcon} />
            ) : (
              <Icon component={VolumeIcon} />
            )
          }
          onClick={() => muteToggle()}
          shape={"circle"}
        />
        <span className={"reels-meta-play-progress-btn-container"}>
          <Button
            className={"reels-meta-play-btn"}
            icon={
              <PlayArrowRounded style={{ color: "white", fontSize: "40px" }} />
            }
            shape={"circle"}
          />
          <Progress
            type={"circle"}
            strokeLinecap={"square"}
            showInfo={false}
            strokeColor={"white"}
            trailColor={"transparent"}
            onClick={() => {
              localStorage.setItem("team", metadata.title);
              playPause(metadata.id);
            }}
            percent={(reelCurrentPlayTime * 100) / reelDuration}
          />
        </span>
      </div>
      <div className={"reels-player-shadow"}></div>
      {!hideLoader && <div className={"reels-player-frame-image"}>
        <img src={metadata.frameImage}/>
      </div>}
    </ReelsMetaDataContainer>
  );
});

export default ReelsMetaData;
