import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../components/common/style.scss";

let slidesToShow = 6;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function TopLeague() {
  const settings = {
    className: "slider variable-width",
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    horrizontalSwiping: true,
    swipeToSlide: true
  };

  return (
    <div id="lower-cards-category" className="top-league-slider">
      <div className="category-header">
        <div className="category-header-text">Top Leagues</div>
      </div>
      <div className="card-container category-card">
        <Slider {...settings}>
          <div>
            <div className="circle">
              <img src="/images/wwe-raw.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 322.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 282.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 284.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 283.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 286.png" />
            </div>
          </div>
          <div>
            <div className="circle">
              <img
                src="/images/ecac.png"
                style={{
                  width: "138px",
                  objectFit: "contain"
                }}
              />
            </div>
          </div>
          <div>
            <div className="circle">
              <img src="/images/Image 322.png" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );

  // return (
  //   <Fragment>
  //     <div className="category-header" >
  //       <div className="category-header-text">Top Leagues</div>
  //       <Grid className="category-card" style={{ marginLeft: "40px", display: "flex"  }}>
  //         <div className="circle">
  //           <img src="/images/wwe-raw.png" />
  //         </div>
  //         <Grid className="circle">
  //           <img src="/images/Image 322.png" />
  //         </Grid>
  //         <Grid className="circle">
  //           <img src="/images/Image 282.png" />
  //         </Grid>
  //         <Grid className="circle">
  //           <img src="/images/Image 284.png" />
  //         </Grid>
  //         <Grid className="circle">
  //           <img src="/images/Image 283.png" />
  //         </Grid>
  //         <Grid className="circle">
  //           <img src="/images/Image 286.png" />
  //         </Grid>

  //       </Grid>
  //     </div>
  //   </Fragment>
  // );
}

export default TopLeague;
