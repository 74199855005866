export const timelineMarkerMockDAta = [
    {
      marker_id: "GOAL",
      background_colour: "2FB52F",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "G",
      letter_size: 0.8,
      size: 20,
      image:"/images/Goal scored.svg"

    },
    {
      marker_id: "SAVE",
      background_colour: "1d26b3",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "S",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "CHANCE",
      background_colour: "3f317d",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "C",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "RED_CARD",
      background_colour: "e80505",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "R",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "WICKET",
      background_colour: "e80505",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "W",
      letter_size: 0.8,
      size: 20,
    },
    {
      marker_id: "SIX",
      background_colour: "0C4EE3",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "6",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "HALF_CENTURY",
      background_colour: "175726",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "F",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "FOUR",
      background_colour: "38C775",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "4",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "CENTURY",
      background_colour: "780767",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "C",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "HEARTBEAT",
      background_colour: "1d632e",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "H",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "BLOCK",
      background_colour: "1d26b3",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "B",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "SMASH",
      background_colour: "317336",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "S",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "ACE",
      background_colour: "3f317d",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "A",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "SET_POINT",
      background_colour: "B34949",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "P",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "MATCH_POINT",
      background_colour: "e80505",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "M",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "YELLOW_CARD",
      background_colour: "EBC91A ",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "Y",
      letter_size: 0.8,
      size: 20,
      image:"/images/Yellow cardTLM.svg"
    },

    {
      marker_id: "RALLY",
      background_colour: "1f5a61",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "R",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "WINNER",
      background_colour: "42c74a",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "W",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "BREAK_POINT",
      background_colour: "780767",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "B",
      letter_size: 0.8,
      size: 20
    },
    {
      marker_id: "PENALTY_SCORED",
      background_colour: "386FEB ",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "P",
      letter_size: 0.8,
      size: 20,
      image:"/images/Goal scored.png"

    },
    {
      marker_id: "PENALTY_MISS",
      background_colour: "EB3702",
      highlight_colour: "ffc900",
      title_colour: "ffffff",
      letter: "M",
      letter_size: 0.8,
      size: 20,
      image:"/images/Goal scored.svg"
    },

  ];