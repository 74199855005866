import React, { Fragment, useEffect } from "react";
import { render } from "react-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import "./style.scss";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";


export default function NewBanner() {
  const [bannerImage, setBannerImage] = useState("");
  const [bannerData, setBannerData] = useState([]);
  const history = useHistory();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:5000
    // prevArrow: <PreviousBtn />,
    // nextArrow: <NextBtn />
  };
  useEffect(() => {
    axios({
      method: "get",
      url: "https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/category/5f45233ff0a4ca2277d30ca1?limit=5&offset=0"
    })
      .then((response) => {
        // console.log(response, "Response in Banner-------");
        let data = response?.data?.data?.results || [];
        data = data
          .filter(
            (item) =>
              item?.contentEditorial?.length &&
              item.id === "62df9a6da809ca12904dda0b"
          )
          .map((d) => d.contentEditorial);
        setBannerData(data);
      })
      .catch((error) => {
        console.log(error, "error in Banner--------");
      });
  }, []);

  // const banner =
  //   bannerData &&
  //   bannerData.map((_item, index) => {
  //     if (_item.railType == "HERO_BANNER") {
  //       return _item.contentEditorial;
  //     }
  //   });
  // const getCardDetail = (items) => {
  //   // history.push(`/details/${items.contentId}`);
  //   console.log("Itesm", items);
  //   localStorage.setItem("bannerTitle", items.content.title);
  //   localStorage.setItem("description", items.content.description);
  //   // history.push("/matchdetail-afc_wimbledon-vs-guiseley-g2257469-0");
  // };

  const onBannerClick = (item) => {
    localStorage.setItem("team", item.content.title);
    localStorage.setItem("description", item.content.description);
    history.push("/matchdetail-manu-vs-middlesb-g2257456-1");
  };
  const onSecondBannerClick = (item) => {
    localStorage.setItem("team", item.content.title);
    localStorage.setItem("description", item.content.description);
    history.push("/matchdetail-manchester-vs-chelsea-g2257442-1");
  };

  return (
    <Grid className="banner" style={{ position: "relative" }}>
      <Slider {...settings}>
        {bannerData &&
          bannerData.length > 0 &&
          bannerData[0].map((_item, index) => {
            return (
              <div key={index}>
                {index === 0 || index === 2 ? (
                  index === 0 ? (
                    <div>
                      <img
                        onClick={() => onBannerClick(_item)}
                        className="banner-image"
                        alt=""
                        src={
                          `https://dn8wbd49q0doa.cloudfront.net/` +
                          _item.content.coverImage
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        onClick={() => onSecondBannerClick(_item)}
                        className="banner-image"
                        alt=""
                        src={
                          `https://dn8wbd49q0doa.cloudfront.net/` +
                          _item.content.coverImage
                        }
                      />
                    </div>
                  )
                ) : (
                  <div>
                    <img
                      className="banner-image"
                      alt=""
                      src={
                        `https://dn8wbd49q0doa.cloudfront.net/` +
                        _item.content.coverImage
                      }
                    />
                  </div>
                )}
                {index === 0 ? (
                  <div className="banner-live-match-section">
                    <div
                      className="banner-watch-section"
                      onClick={() => onBannerClick(_item)}
                    >
                      <Grid
                        className="banner-watch-now"
                      >
                        <div className="banner-play">
                          <img
                            src="/images/play.png"
                            style={{ width: "17.5px", height: "20px" }}
                          />
                        </div>
                        <p>Watch Live</p>
                      </Grid>
                    </div>
                    <>
                      <div
                        className="score-card"
                        onClick={() => onBannerClick(_item)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            position: "relative"
                          }}
                        >
                          <div className="banner-live-score">Live Score</div>

                          <Grid className="batting-team">
                            <div>
                              <img
                                className="score-card-img"
                                src="/images/NewFlag3.png"
                              />
                            </div>

                            <span>
                              <p className="batting-team-name">MANU</p>
                              <p
                                className="batting-team-score"
                                style={{ textAlign: "left" }}
                              >
                                0
                              </p>
                            </span>
                          </Grid>
                          <Grid
                            className="batting-team"
                            style={{ flexDirection: "row-reverse", gap: "5px" }}
                          >
                            <div>
                              <img
                                className="score-card-img"
                                src="/images/NewFlag1.png"
                              />
                            </div>

                            <span>
                              <p className="batting-team-name">MIDD</p>
                              <p
                                className="batting-team-score"
                                style={{ textAlign: "right" }}
                              >
                                0
                              </p>
                            </span>
                          </Grid>
                        </div>
                      </div>
                    </>
                  </div>
                ) : null}
                {index === 2 ? (
                  <div className="banner-live-match-section">
                    <div
                      className="banner-watch-section"
                      onClick={() => onSecondBannerClick(_item)}
                    >
                      <Grid
                        className="banner-watch-now"
                      >
                        <div className="banner-play">
                          <img
                            src="/images/play.png"
                            style={{ width: "17.5px", height: "20px" }}
                          />
                        </div>
                        <p>Watch Live</p>
                      </Grid>
                    </div>
                    <>
                      <div
                        className="score-card"
                        onClick={() => onSecondBannerClick(_item)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            position: "relative"
                          }}
                        >
                          <div className="banner-live-score">Live Score</div>

                          <Grid className="batting-team">
                            <div>
                              <img
                                className="score-card-img"
                                src="/images/MCity.png"
                              />
                            </div>

                            <span>
                              <p className="batting-team-name">MANC</p>
                              <p
                                className="batting-team-score"
                                style={{ textAlign: "left" }}
                              >
                                0
                              </p>
                            </span>
                          </Grid>
                          <Grid
                            className="batting-team"
                            style={{ flexDirection: "row-reverse", gap: "5px" }}
                          >
                            <div>
                              <img
                                className="score-card-img"
                                src="/images/Flag6.png"
                              />
                            </div>

                            <span>
                              <p className="batting-team-name">CHEL</p>
                              <p
                                className="batting-team-score"
                                style={{ textAlign: "right" }}
                              >
                                0
                              </p>
                            </span>
                          </Grid>
                        </div>
                      </div>
                    </>
                  </div>
                ) : null}
              </div>
            );
          })}
      </Slider>
    </Grid>
  );
}

// {index === 2 ? (
//   <>
//     <div className="banner-watch-section" onClick={() => history.push("/matchdetail-afc_wimbledon-vs-guiseley-g2257469-1")}>
//       <Grid
//         className="banner-watch-now"
//         style={{ justifyContent: "space-around" }}
//       >
//         <div className="banner-play">
//           <img
//             src="/images/play.png"
//             style={{ width: "17.5px", height: "20px" }}
//           />
//         </div>
//         <p>Watch Live</p>
//       </Grid>
//     </div>
//       <div className="score-card">
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//             position: "relative"
//           }}
//         >
//           <div className="banner-live-score">Live Score</div>

//           <Grid className="batting-team">
//             <div>
//               <img
//                 className="score-card-img"
//                 src="/images/england-flag.png"
//               />
//             </div>

//             <span>
//               <span>
//                 <p className="batting-team-name">ENG</p>
//                 <p className="batting-team-score">280/6</p>
//                 <p className="batting-team-name">48.4</p>
//               </span>
//             </span>
//           </Grid>
//           <Grid
//             className="batting-team"
//             style={{ flexDirection: "row-reverse", gap: "5px" }}
//           >
//             <div>
//               <img
//                 className="score-card-img"
//                 src="/images/NZ.png"
//               />
//             </div>

//             <span>
//               <p className="yet-to-bat" style={{marginTop:"10px",width:"40px"}}>Yet to bat</p>{" "}
//             </span>
//           </Grid>
//         </div>
//       </div>
//   </>
// ) : null}
