import { Button } from "antd";

export default function ReelsButton(props) {
  const { setIsReelsVisible } = props;
  return (
    <Button
      className="reels-button"
      onClick={() => setIsReelsVisible(true)}
      size={"large"}
      icon={<img src="/images/reels.svg" />}
      shape={"circle"}
      style={{
        height: "80px",
        width: "80px"
      }}
    ></Button>
  );
}
