const data = {
  argentina: {
    playingEleven: [
      {
        jerseyNumber: "11",
        playerName: "Petr",
        scoreTime: "12:09",
        redCard: {
          value: true,
          time: "41'",
          image:"/images/redcard.svg"
        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: true,
          time: "70'",
          image:"/images/Penalty missed.svg"
          ,
        },
        substitue: {
          value: true,
          time: "33'",
        },
      },

      {
        ScoreTime: "12:09",
        jerseyNumber: "9",
        playerName: "Olivier",
        redCard: {
          value: true,
          time: "51'",
          image:"/images/redcard.svg"

        },
        yellow: {
          value: true,
          time:"91'",
          image:"/images/Yellow card.png"
        },
        ownGoal: {
          value: true,
          time: "23'",
          image:"/images/Penalty missed.svg"
        },
        substitue: {
          value: true,
          time: 33,
        },
      },
    ],
    substitute: [
      {
        jerseyNumber: "50",
        playerName: "Hector",
        scoreTime: "12:09",
        redCard: {
          value: true,
          time: "19'",
          image:"/images/redcard.svg"

        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: true,
          time: "10'",
          image:"/images/Penalty missed.svg"

        },
        substitue: {
          value: true,
          time: 33,
        },
      },

      {
        jerseyNumber: 27,
        playerName: "Alex",
        ScoreTime: "12:09",
        jerseyNumber: "9",
        redCard: {
          value: true,
          time: "17'",
          image:"/images/Red card.svg"

        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: true,
          time: "17'",
          image:"/images/Penalty missed.svg"

        },
        substitue: {
          value: true,
          time: "33'",
        },
      },
    ],
  },
  brazil:{
    playingEleven: [
      {
        jerseyNumber: "11",
        playerName: "Neymar",
        scoreTime: "12:09",
        redCard: {
          value: true,
          time: "36'",
          image:"/images/redcard.svg"

        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: true,
          time: "67'",
          image:"/images/Penalty missed.svg"

        },
        substitue: {
          value: true,
          time: 33,
        },
      },

      {
        ScoreTime: "12:09",
        jerseyNumber: "9",
        playerName: "Mbappe",
        redCard: {
          value: true,
          time: "43'",
          image:"/images/Own goal.svg"

        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: false,
          
        },
        substitue: {
          value: true,
          time: 23,
        },
      },
    ],
    substitute: [
      {
        jerseyNumber: "50",
        playerName: "Sarah",
        scoreTime: "12:09",
        redCard: {
          value: true,
          time: "40'",
          image:"/images/Own goal.svg"

        },
        yellow: {
          value: false,
        },
        ownGoal: {
          value: false,
        },
        substitue: {
          value: true,
          time: 33,
        },
      },

      {
        jerseyNumber: 27,
        playerName: "Max",
        ScoreTime: "12:09",
        jerseyNumber: "9",
        redCard: {
          value: true,
          time: "44'",
          image:"/images/Own goal.svg"

        },
        yellow: {
          value: true,
          value:21,
          image:"/images/Yellow card.png"
        },
        ownGoal: {
          value: true,
          time: "22'",
          image:"/images/Penalty missed.svg"

        },
        substitue: {
          value: true,
          time: 33,
        },
      },
    ],
  }
};
export default data;
