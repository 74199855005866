export const timeLineMockData1 = {
    resultCode: "OK",
    message: "",
    errorDescription: "200-10000",
    resultObj: {
      total: 7,
      stream_duration: 14400,
      // "stream_duration": 1637905099, // currentEpochTime manually obtained
      time_interval: 30,
      match_start_time: 1635924613,
      containers: [
        {
          id: 1000062136,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000062136,
            longDescription: "Wicket! Cheatle Inflicts Double Blow In An Over",
            shortDescription: "Penalty Miss",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Cheatle Inflicts Double Blow In An Over",
            genres: ["Cricket"],
            pictureUrl: "TOCH_16110586a4a3b4dc4ec0f79ae738d4ba",
            emfAttributes: {
              tv_background_image:
                "/images/MM_1.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba/thumbnail/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_1635670460_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_640_360_5.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba/thumbnail/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_1635670460_thumb.jpg",
              tl_marker: "PENALTY_MISS|00:25:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_192_108_5.jpg",
              videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_miss_02_27_56_to_02_28_00/Default/HLS/Penalty_miss_02_27_56_to_02_28_00.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162650,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162650,
            longDescription: "Wicket! Bolton Takes Her Third, Kapp Out",
            shortDescription: "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Bolton Takes Her Third, Kapp Out",
            genres: ["Cricket"],
            pictureUrl: "TOCH_446cc286fccc77938c5583ddf764903e",
            emfAttributes: {
              tv_background_image:
              "/images/MM_2.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_446cc286fccc77938c5583ddf764903e/thumbnail/TOCH_446cc286fccc77938c5583ddf764903e_1635669414_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_446cc286fccc77938c5583ddf764903e_640_360_1.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_446cc286fccc77938c5583ddf764903e/thumbnail/TOCH_446cc286fccc77938c5583ddf764903e_1635669414_thumb.jpg",
              tl_marker: "GOAL|00:31:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_446cc286fccc77938c5583ddf764903e_192_108_1.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Goal_25_33_to_25_41/Default/HLS/Goal_25_33_to_25_41.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000163287,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000163287,
            longDescription:
              "Wicket! Radha Bags Another, Graham Spoons One To Perry",
            shortDescription:
              "Yellow Card",
            title: "Women's Big Bash League 2021",
            episodeTitle:
              "Wicket! Radha Bags Another, Graham Spoons One To Perry",
            genres: ["Cricket"],
            pictureUrl: "TOCH_3b587518a015ff6b0af6f3f2e5bca6d5",
            emfAttributes: {
              tv_background_image:
              "/images/MM_3.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5/thumbnail/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_1635669996_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5/thumbnail/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_1635669996_thumb.jpg",
              tl_marker: "Yellow_Card|00:36:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_192_108_3.jpg",
              // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Yellow_Card_01_13_05_to_01_13_13/Default/HLS/Yellow_Card_01_13_05_to_01_13_13.m3u8",
              videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_miss_02_27_56_to_02_28_00/Default/HLS/Penalty_miss_02_27_56_to_02_28_00.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000163167,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000163167,
            longDescription:
              "Wicket! Cheatle Plucks A Blinder, Ends Devine's Stay",
            shortDescription:
              "Yellow Card",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Cheatle Plucks A Blinder, Ends Devine's Stay",
            genres: ["Cricket"],
            pictureUrl: "TOCH_5e8d508c11dc79bedc9e8cb89654f113",
            emfAttributes: {
              tv_background_image:
              "/images/MM_4.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_5e8d508c11dc79bedc9e8cb89654f113/thumbnail/TOCH_5e8d508c11dc79bedc9e8cb89654f113_1635666738_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_5e8d508c11dc79bedc9e8cb89654f113_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_5e8d508c11dc79bedc9e8cb89654f113/thumbnail/TOCH_5e8d508c11dc79bedc9e8cb89654f113_1635666738_thumb.jpg",
                tl_marker: "Yellow_Card|00:41:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_5e8d508c11dc79bedc9e8cb89654f113_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Yellow_card_42_05_to_42_18/Default/HLS/Yellow_card_42_05_to_42_18.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162950,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162950,
            longDescription: "Wicket! Radha Teases Mooney Into A Trap",
            shortDescription: "Penalty Miss",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Radha Teases Mooney Into A Trap",
            genres: ["Cricket"],
            pictureUrl: "TOCH_ec1f186bb15f9d29e95ddf004cd5348c",
            emfAttributes: {
              tv_background_image:
              "/images/MM_5.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_ec1f186bb15f9d29e95ddf004cd5348c/thumbnail/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_1635669536_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_ec1f186bb15f9d29e95ddf004cd5348c/thumbnail/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_1635669536_thumb.jpg",
                tl_marker: "PENALTY_MISS|00:46:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162991,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162991,
            longDescription: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            shortDescription: "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            genres: ["Cricket"],
            pictureUrl: "TOCH_642622540849cdf8e052a1797c84378f",
            emfAttributes: {
              tv_background_image:
              "/images/MM_6.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tl_marker: "GOAL|00:51:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Goal_01_06_13_to_01_06_21/Default/HLS/Goal_01_06_13_to_01_06_21.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_miss_02_27_56_to_02_28_00/Default/HLS/Penalty_miss_02_27_56_to_02_28_00.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162991,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162991,
            longDescription: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            shortDescription:
            "PENALTY_SCORED",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            genres: ["Cricket"],
            pictureUrl: "TOCH_642622540849cdf8e052a1797c84378f",
            emfAttributes: {
              tv_background_image:
              "/images/MM_4.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tl_marker: "Yellow_Card|00:55:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Score_Ronaldo_02_21_28_to_02_21_32/Default/HLS/Penalty_Score_Ronaldo_02_21_28_to_02_21_32.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        }
      ]
    },
    systemTime: 1637902842794
  };
  
export const timeLineMockData2 = {
    resultCode: "OK",
    message: "",
    errorDescription: "200-10000",
    resultObj: {
      total: 7,
      stream_duration: 14400,
      // "stream_duration": 1637905099, // currentEpochTime manually obtained
      time_interval: 30,
      match_start_time: 1635924613,
      containers: [
        {
          id: 1000062136,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000062136,
            longDescription: "Wicket! Cheatle Inflicts Double Blow In An Over",
            shortDescription: "Yellow Card",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Cheatle Inflicts Double Blow In An Over",
            genres: ["Cricket"],
            pictureUrl: "TOCH_16110586a4a3b4dc4ec0f79ae738d4ba",
            emfAttributes: {
              tv_background_image:
              "/images/CM_1.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba/thumbnail/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_1635670460_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_640_360_5.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba/thumbnail/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_1635670460_thumb.jpg",
              tl_marker: "Yellow_Card|00:25:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_16110586a4a3b4dc4ec0f79ae738d4ba_192_108_5.jpg",
              videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_01_32_38_Yellow_card/Default/HLS/key_moments_chelsea_vs_manchester_01_32_38_Yellow_card.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162650,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162650,
            longDescription: "Wicket! Bolton Takes Her Third, Kapp Out",
            shortDescription: "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Bolton Takes Her Third, Kapp Out",
            genres: ["Cricket"],
            pictureUrl: "TOCH_446cc286fccc77938c5583ddf764903e",
            emfAttributes: {
              tv_background_image:
              "/images/CM_2.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_446cc286fccc77938c5583ddf764903e/thumbnail/TOCH_446cc286fccc77938c5583ddf764903e_1635669414_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_446cc286fccc77938c5583ddf764903e_640_360_1.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_446cc286fccc77938c5583ddf764903e/thumbnail/TOCH_446cc286fccc77938c5583ddf764903e_1635669414_thumb.jpg",
              tl_marker: "GOAL|00:31:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_446cc286fccc77938c5583ddf764903e_192_108_1.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Goal_25_33_to_25_41/Default/HLS/Goal_25_33_to_25_41.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_01_35_13_Offside_Goal/Default/HLS/key_moments_chelsea_vs_manchester_01_35_13_Offside_Goal.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000163287,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000163287,
            longDescription:
              "Wicket! Radha Bags Another, Graham Spoons One To Perry",
            shortDescription:
              "Yellow Card",
            title: "Women's Big Bash League 2021",
            episodeTitle:
              "Wicket! Radha Bags Another, Graham Spoons One To Perry",
            genres: ["Cricket"],
            pictureUrl: "TOCH_3b587518a015ff6b0af6f3f2e5bca6d5",
            emfAttributes: {
              tv_background_image:
              "/images/CM_3.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5/thumbnail/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_1635669996_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5/thumbnail/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_1635669996_thumb.jpg",
              tl_marker: "Yellow_Card|00:36:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_3b587518a015ff6b0af6f3f2e5bca6d5_192_108_3.jpg",
              // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Yellow_Card_01_13_05_to_01_13_13/Default/HLS/Yellow_Card_01_13_05_to_01_13_13.m3u8",
              videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_01_37_32_Yellow_card/Default/HLS/key_moments_chelsea_vs_manchester_01_37_32_Yellow_card.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000163167,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000163167,
            longDescription:
              "Wicket! Cheatle Plucks A Blinder, Ends Devine's Stay",
            shortDescription:
              "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Cheatle Plucks A Blinder, Ends Devine's Stay",
            genres: ["Cricket"],
            pictureUrl: "TOCH_5e8d508c11dc79bedc9e8cb89654f113",
            emfAttributes: {
              tv_background_image:
              "/images/CM_4.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_5e8d508c11dc79bedc9e8cb89654f113/thumbnail/TOCH_5e8d508c11dc79bedc9e8cb89654f113_1635666738_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_5e8d508c11dc79bedc9e8cb89654f113_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_5e8d508c11dc79bedc9e8cb89654f113/thumbnail/TOCH_5e8d508c11dc79bedc9e8cb89654f113_1635666738_thumb.jpg",
                tl_marker: "GOAL|00:41:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_5e8d508c11dc79bedc9e8cb89654f113_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Yellow_card_42_05_to_42_18/Default/HLS/Yellow_card_42_05_to_42_18.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_05_35__Offside_Goal/Default/HLS/key_moments_chelsea_vs_manchester_05_35__Offside_Goal.m3u8",
              parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162950,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162950,
            longDescription: "Wicket! Radha Teases Mooney Into A Trap",
            shortDescription: "Yellow Card",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Radha Teases Mooney Into A Trap",
            genres: ["Cricket"],
            pictureUrl: "TOCH_ec1f186bb15f9d29e95ddf004cd5348c",
            emfAttributes: {
              tv_background_image:
              "/images/CM_5.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_ec1f186bb15f9d29e95ddf004cd5348c/thumbnail/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_1635669536_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_ec1f186bb15f9d29e95ddf004cd5348c/thumbnail/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_1635669536_thumb.jpg",
                tl_marker: "Yellow_Card|00:46:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_ec1f186bb15f9d29e95ddf004cd5348c_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Miss_20_33_to_20_39/Default/HLS/Penalty_Miss_20_33_to_20_39.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_34_13_Yellow_Card/Default/HLS/key_moments_chelsea_vs_manchester_34_13_Yellow_Card.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162991,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162991,
            longDescription: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            shortDescription: "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            genres: ["Cricket"],
            pictureUrl: "TOCH_642622540849cdf8e052a1797c84378f",
            emfAttributes: {
              tv_background_image:
              "/images/CM_6.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tl_marker: "GOAL|00:51:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Goal_01_06_13_to_01_06_21/Default/HLS/Goal_01_06_13_to_01_06_21.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_57_53_Goal/Default/HLS/key_moments_chelsea_vs_manchester_57_53_Goal.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        },
        {
          id: 1000162991,
          layout: "CONTENT_ITEM",
          metadata: {
            contentId: 1000162991,
            longDescription: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            shortDescription:
            "Goal",
            title: "Women's Big Bash League 2021",
            episodeTitle: "Wicket! Gardner Gets Peschel, Sixer Rout Scorchers",
            genres: ["Cricket"],
            pictureUrl: "TOCH_642622540849cdf8e052a1797c84378f",
            emfAttributes: {
              tv_background_image:
              "/images/CM_4.png",
              thumbnail:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tv_sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_640_360_3.jpg",
              landscape_thumb:
                "https://origin-staticv2.sonyliv.com/cms/TOCH_642622540849cdf8e052a1797c84378f/thumbnail/TOCH_642622540849cdf8e052a1797c84378f_1635670579_thumb.jpg",
              tl_marker: "GOAL|00:55:35",
              matchid: "sswhhw11032021203741:1:australian_domestic",
              sprite_image_url:
                "https://sprite.sonyliv.com/2021/10/31/TOCH_642622540849cdf8e052a1797c84378f_192_108_3.jpg",
                // videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/Penalty_Score_Ronaldo_02_21_28_to_02_21_32/Default/HLS/Penalty_Score_Ronaldo_02_21_28_to_02_21_32.m3u8",
                videoURL:"https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/key_moments_chelsea_vs_manchester_57_53_Goal/Default/HLS/key_moments_chelsea_vs_manchester_57_53_Goal.m3u8",
                parent_id: "1000142408"
            },
            isEncrypted: false,
            contentProvider: "TOCH"
          }
        }
      ]
    },
    systemTime: 1637902842794
  };
  