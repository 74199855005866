import { CloseOutlined } from "@ant-design/icons";
import DoneIcon from "@mui/icons-material/Done";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Slider from "@mui/material/Slider";
import { Button, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PipContext from "../../../helpers/contexts/PipContext";
import exitFullScreenIcon from "../../../images/fullscreen_icon.svg";
import LiveIcon from "../../../images/liveIcon.svg";
import marker from "../../../images/marker.svg";
import muteIcon from "../../../images/mute_icon.svg";
import pauseIcon from "../../../images/pause.svg";
import playIcon from "../../../images/play.svg";
import resolutionIcon from "../../../images/resolution.svg";
import subtitlesIcon from "../../../images/subtitle.svg";
import unMuteIcon from "../../../images/unmute_icon.svg";
import SeekBar from "../Seekbar/seekbar";
import "./style.scss";
import multiviewIcon from "../../../images/multiview-icon.svg";

const qualityConfig = [
  {
    height: 8192,
    title: "Auto",
    description: ""
  },
  {
    height: 1080,
    title: "Full HD",
    description: "Upto 1080p"
  },
  {
    height: 720,
    title: "HD",
    description: "Upto 720p"
  },
  {
    height: 480,
    title: "SD",
    description: "Upto 480p"
  }
];
const TLMfilterConfig = [
  {
    title: "All",
    enabled: true
  },
  {
    title: "Goal",
    enabled: false
  },
  {
    title: "Offside Goal",
    enabled: false
  },
  {
    title: "Yellow Card",
    enabled: false
  }
];

function VideoPlayerControls(props) {
  const {
    player,
    playerIndex,
    isMainPlayer,
    enableSidebar,
    enabledSidebar,
    videoWrapperID,
    // setSidebarOpen,
    videoPlayerEvents,
    toggleMultiViewSidebar,
    setTLMPlayer,
    teams,
    isLive,
    isTLMEnabled,
    toggleMultiView,
    pipFullScreenHandler,
    brandingData,
    showGoLive
  } = props;

  const [isMute, setIsMute] = useState(false);
  const [closeTLMPops, setCloseTLMPops] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const { NATIVE_EVENTS, CUSTOM_EVENTS } = videoPlayerEvents;
  const [showQualityPopupFlag, setShowQualityPopupFlag] = useState(false);
  const [showAudioPopupFlag, setShowAudioPopupFlag] = useState(false);
  const [showTLMfilterPopupFlag, setShowTLMfilterPopupFlag] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState("Auto");
  const [isFullScreen, setFullScreen] = useState(false);
  // const setPopupStateArr = [setShowAudioPopupFlag, setShowQualityPopupFlag];
  const [showControls, setShowControls] = useState(false);
  const [multiViewActive, setMultiViewActive] = useState(false);
  const [controlsTimeout, setControlsTimeout] = useState(null);
  const setPopupStateArr = [
    setShowAudioPopupFlag,
    setShowQualityPopupFlag,
    setShowTLMfilterPopupFlag
  ];
  const [showMultiViewIcons, setShowMultiViewIcons] = useState(false);
  const { isVisible, setIsVisible, isAdVisible, setAdVisible } =
    useContext(PipContext);
    const imagePathPrefix = "https://dn8wbd49q0doa.cloudfront.net/";

  const Title = localStorage.getItem("team");
  useEffect(() => {
    let controlsTimer;
    if (showControls && isPlaying) {
      controlsTimer = setTimeout(() => {
        setShowControls(false);
      }, 5000);
    }

    return () => {
      if (controlsTimer) {
        clearTimeout(controlsTimer);
      }
    };
  }, [showControls, isPlaying]);

  const playerCallback = (eventName, eventData) => {
    if (
      !(
        eventName == "seekbarupdate" ||
        eventName == "timeupdate" ||
        eventName == "progress"
      )
    ) {
      // console.info("playerCallback ::", videoWrapperID, eventName, eventData);
    }

    switch (eventName) {
      case NATIVE_EVENTS.PLAY:
        setIsPlaying(true);
        setShowControls(true);
        break;
      case NATIVE_EVENTS.PLAYING:
        setIsPlaying(true);
        break;
      case NATIVE_EVENTS.PAUSE:
        setIsPlaying(false);
        setShowControls(true);
        break;
      case NATIVE_EVENTS.MUTED:
        setIsMute(true);
        break;
      case NATIVE_EVENTS.LOADEDMETADATA:
        setIsMetadataLoaded(true);
      case NATIVE_EVENTS.LOADEDDATA:
        setIsMute(player.muted());
        setShowControls(true);
        break;
      case NATIVE_EVENTS.VOLUMECHANGE:
        setIsMute(player.muted());
        break;
    }
  };

  const playerCustomCallback = (eventName, eventData) => {
    switch (eventName) {
      case CUSTOM_EVENTS.BITRATE_CHANGED:
        console.log("first", eventName, eventData);
        break;
    }
  };

  const adCallback = (eventName, eventData) => {};

  useEffect(() => {
    if (player) {
      player.subscribeExternal({
        playerCallback,
        playerCustomCallback,
        adCallback,
        subscriberName: "PLAYER_CONTROLS" + playerIndex
      });
    }
  }, [player]);

  const closeAllPopups = () => {
    setCloseTLMPops(true);
    setPopupStateArr.forEach((setPopupState) => {
      setPopupState(false);
    });
  };

  const TLMCloseCallback = () => {
    setCloseTLMPops(false);
  };

  const play = () => {
    setCloseTLMPops(true);
    closeAllPopups();
    player.play();
    // setCloseTLMPops(false);
  };

  const pause = () => {
    setCloseTLMPops(true);
    closeAllPopups();
    player.pause();
    // setCloseTLMPops(false);
  };

  const currentTime = () => {};

  const toggleMute = () => {
    closeAllPopups();
    setCloseTLMPops(true);
    const isMuted = player.muted(!isMute);
    setIsMute(isMuted);
    localStorage.setItem("muted", isMuted);
  };

  const setVolumes = (event, value) => {
    let volume = 0;
    if (value > 0) {
      volume = value / 100;
    }
    localStorage.setItem("volume", Math.floor(volume * 100));
    player.volume(volume);
    setCloseTLMPops(true);
  };

  const setQuality = (qualitySelected, updateState = true) => {
    if (!player) {
      return;
    }
    const { height, title } = qualitySelected;
    const qualities = player.getQualityLevels();
    let bitrate = 0;
    qualities.forEach((quality) => {
      if (quality.height <= height && bitrate < quality.bitrate) {
        bitrate = quality.bitrate;
      }
    });

    console.log("Bitrate Selected", bitrate, height);
    if (bitrate > 0) {
      player.setQualityLevel(bitrate);
    }
    if (updateState) {
      setSelectedQuality(title);
    }
    closeAllPopups();
  };

  const handleQualityButtonClick = () => {
    closeAllPopups();
    setShowQualityPopupFlag(!showQualityPopupFlag);
  };

  const handleAudioButtonClick = () => {
    closeAllPopups();
    setCloseTLMPops(true);
    setShowAudioPopupFlag(!showAudioPopupFlag);
  };
  const handleTLMfilterButtonClick = () => {
    closeAllPopups();
    setCloseTLMPops(true);
    setShowTLMfilterPopupFlag(!showTLMfilterPopupFlag);
  };

  const showQualityPopup = () => {
    return (
      <div className="quality-options popup">
        <h4 className="popup-title">VIDEO QUALITY</h4>
        {qualityConfig.map((quality, index) => {
          return (
            <div
              className="popup-option"
              onClick={() => {
                setQuality(quality);
              }}
              key={index}
            >
              <h3 className="popup-option-title">
                {quality.title}
                {selectedQuality == quality.title ? (
                  <DoneIcon className="selected-icon" />
                ) : (
                  ""
                )}
              </h3>
              <span>{quality.description}</span>
            </div>
          );
        })}
      </div>
    );
  };
  const showTLMfilterPopup = () => {
    return (
      <div className="quality-options popup">
        <h4 className="popup-title">KEY MOMENT</h4>
        {TLMfilterConfig.map((moments, index) => {
          return (
            <div
              className="popup-option"
              onClick={() => {
                setKeyMoment(moments);
              }}
              key={index}
            >
              <h3 className="popup-option-title">
                {moments.title}
                {moments.enabled ? <DoneIcon className="selected-icon" /> : ""}
              </h3>
            </div>
          );
        })}
      </div>
    );
  };

  const setAudioLanguage = (id) => {
    player.setAudioTrack(id);
    console.log("Audio Selected", id);
    closeAllPopups();
    setCloseTLMPops(true);
  };
  const setKeyMoment = () => {
    closeAllPopups();
  };

  const showAudioPopup = () => {
    const audio = player.getAudioTracks();
    // console.log(audio, "audio");
    return (
      <div className="audio-options popup">
        <h4 className="popup-title">AUDIO LANGUAGES</h4>

        {audio.map((audio, index) => {
          return (
            <div
              className="popup-option"
              onClick={() => {
                setAudioLanguage(audio.id);
              }}
              key={index}
            >
              <h3 className="popup-option-title">
                {audio.label}
                {audio.enabled ? <DoneIcon className="selected-icon" /> : ""}
              </h3>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (isMetadataLoaded) {
      let quality;
      if (isMainPlayer) {
        quality = qualityConfig.find((q) => q.title == selectedQuality);
      } else {
        quality = qualityConfig[3];
      }

      setQuality(quality, false);
    }
  }, [isMetadataLoaded, isMainPlayer]);
  /*
   Player Methods

   player.currentTime()
   player.seek()
   player.muted() getter
   player.muted(true) setter
   player.volume() getter
   player.volume(0.5) setter
   player.load()
   player.isLive()
   player.isStalled()
   player.fastSeekToPosition()

   player.getQualityLevels()
   Sample Response:
   [
   {
   bitrate: 258157,
   enabled: true,
   height: 180,
   width: 422
   }
   ...
   ]
   player.setQualityLevel(bitrate)

   player.getAudioTracks()
   Sample Response:
   [
   {
   enabled: false,
   id: "English",
   kind: "main",
   label: "English",
   language: "en"
   }
   ...
   ]
   player.setAudioTrack(id)

   */

  const toggleSidebar = (type) => {
    setCloseTLMPops(true);
    if (type == enabledSidebar) {
      enableSidebar(false);
    } else {
      enableSidebar(true, type);
    }
  };
  const handleVideo = () => {
    if (!isPlaying) {
      play();
    } else {
      pause();
    }
  };

  const dp = document.getElementsByClassName("player-height");

  const handleFullScreen = (event) => {
    setCloseTLMPops(true);
    // var elem = document.getElementById("video-player-wrapper1");
    var elem = document.getElementById("player-with-lband");
    const leftband = document.getElementById("leftBanner");
    const pipContainer = document.getElementsByClassName("pipContainer");
    // console.log(elem, "Event");
    setFullScreen(!isFullScreen);
    pipFullScreenHandler(!isFullScreen);
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // current working methods
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }

      // <<<<<<< TO HANDLE LBANDAd
      if (dp) {
        dp[0].style.transition = "height 200ms linear";
        dp[0].style.height = "100vh";
        if (pipContainer.length) {
          pipContainer[0].style.position = "inherit";
        }
        if (leftband && leftband.classList.contains("showLeft")) {
          leftband?.setAttribute("style", "width: 20%");
          dp[0].style.height = "85vh";
          if (pipContainer.length) {
            pipContainer[0].style.width = "100%";
          }
        } else {
          leftband?.removeAttribute("style");
          dp[0].style.height = "100vh";
          if (pipContainer.length) {
            pipContainer[0].style.width = "100%";
          }
        }
      }
      // TO HANDLE LBANDAd >>>>>>>>>
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }

      // <<<<<<< TO HANDLE LBANDAd
      if (dp) {
        dp[0].style.transition =
          isVisible && isAdVisible ? "height 200ms linear" : "none";
        dp[0].style.height = isVisible ? "75vh" : "36vh";
        if (pipContainer.length) {
          pipContainer[0].style.width = "30%";
          pipContainer[0].style.position = "fixed";
        }
        if (leftband && !leftband.classList.contains("showLeft")) {
          leftband?.removeAttribute("style");
        } else {
          dp[0].style.height = isVisible ? "65vh" : "36vh";
          leftband?.removeAttribute("style");
        }
      }
      // TO HANDLE LBANDAd >>>>>>>>>
    }
  };

  const history = useHistory();
  const clickOnBack = () => {
    // console.log("checking", props);
    setCloseTLMPops(true);
    if (props.videoWrapperID === "video-player-wrapper-tlm") {
      setTLMPlayer({ enabled: false, playerConfig: "" });
    } else {
      history.push(`/home`);
    }
  };
  const onMouseMove = () => {
    if (multiViewActive) {
      return;
    }
    if (isPlaying) {
      setShowControls(true);
    } else {
      setShowControls(true);
    }
  };

  const showMultiView = () => {
    setCloseTLMPops(true);
    toggleMultiView();
    // setShowMultiViewIcons(!showMultiViewIcons);
  };

  const onMouseEnter = (e, id) => {
    // alert(e.target.id);
    document.getElementById(id).style.opacity = "0.5";
  };

  const onMouseLeave = (e, id) => {
    document.getElementById(id).style.opacity = "1";
  };
  return (
    <div
      className={`player-controls-wrapper ${
        showControls ? "" : "hide-background"
      }`}
    >
      <div
        className="video-player-screen-size"
        onClick={handleVideo}
        onMouseMove={onMouseMove}
      ></div>
      <Space
        className={`controls-top-btns ${showControls ? "" : "display-none"}`}
      >
        <div id="controls-top-left" className="controls-top-left">
          <div className="controls-top-left-div">
            {isVisible || isFullScreen ? (
              <Button
                size="large"
                shape="circle"
                type="text"
                className="back-icons hoverClass"
                id="backicon"
              >
                <img
                  src="/images/Group 9495.svg"
                  onClick={clickOnBack}
                  style={{ maxWidth: "100%" }}
                />
              </Button>
            ) : null}
            {teams && teams.length > 0 && (
              <div className="matchTitle">
                {Title != null && !isLive ? "" : <span>{`FA Cup 2022`}</span>}
                {Title != null && !isLive ? (
                  <p>{`${Title}`}</p>
                ) : Title != null && isLive ? (
                  <p>{`${Title}`}</p>
                ) : (
                  <p>{`${teams[0].teamName} vs ${teams[1].teamName}`}</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div id="controls-top-right" className="controls-top-right">
          {isVisible || isFullScreen ? (
            <Button
              size="large"
              shape="circle"
              type="text"
              id="multiscreenview"
              onMouseEnter={(e) => onMouseEnter(e, "multiscreenview")}
              onMouseLeave={(e) => onMouseLeave(e, "multiscreenview")}
              onClick={showMultiView}
            >
              <img
                src="images/camera angles.svg"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Button>
          ) : null}

          {isLive && (isVisible || isFullScreen) ? (
            <Button
              size="large"
              shape="circle"
              type="text"
              onClick={() => {
                toggleSidebar("MultiView");
              }}
              id="multiview"
              // onMouseEnter={(e) => onMouseEnter(e)}
              // onMouseLeave={(e) => onMouseLeave(e)}
              className={`hoverClass ${
                enabledSidebar == "MultiView" ? "hover-enabled" : ""
              }`}
            >
              <img
                src={multiviewIcon}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Button>
          ) : null}
          {isVisible || isFullScreen ? (
            <Button
              size="large"
              shape="circle"
              type="text"
              className="watch-party-icon"
              onClick={() => {
                toggleSidebar("WatchParty");
              }}
              id="watchparty"
              // onMouseEnter={(e) => onMouseEnter(e,"watchparty")}
              // onMouseLeave={(e) => onMouseLeave(e,"watchparty")}
            >
              {enabledSidebar !== "WatchParty" ? (
                <PeopleAltOutlinedIcon
                  style={{ color: "white" }}
                  className={`hoverClass ${
                    enabledSidebar == "WatchParty" ? "hover-enabled" : ""
                  }`}
                />
              ) : (
                <span className="close-side-bar-text">CLOSE</span>
              )}
            </Button>
          ) : (
            <Button
              size="large"
              shape="circle"
              type="text"
              onClick={() => {
                dp[0].style.display = "none";
              }}
            >
              <CloseOutlined style={{ color: "white" }} />
            </Button>
          )}
        </div>
      </Space>
      <div
        id="controls-bottom"
        className={`controls-bottom ${showControls ? "" : "display-none"}`}
      >
        {isVisible || isFullScreen ? (
          <div>
            {isPlaying && isLive && (
              <div className="live-badge-player">
                <div className="icon"></div>
                <div className="live-text">Live</div>
              </div>
            )}
            {!isPlaying && isLive && (
              <div className="go-live" onClick={play}>
                Go Live
              </div>
            )}
            {player && (
              <SeekBar
                player={player}
                videoPlayerEvents={videoPlayerEvents}
                setTLMPlayer={setTLMPlayer}
                isTLMEnabled={isTLMEnabled}
                closeTLMPops={closeTLMPops}
                TLMCloseCallback={TLMCloseCallback}
                showGoLive={showGoLive}
              ></SeekBar>
            )}
          </div>
        ) : null}
        <Space className="controls-bottom-btns">
          <div id="controls-bottom-left" className="controls-bottom-left">
            {!isPlaying && (
              <Button
                size="large"
                shape="circle"
                type="text"
                style={{ padding: "0" }}
                onClick={play}
                id="playicon"
                className="hoverClass"
              >
                <img src={playIcon} style={{ width: "26px", height: "26px" }} />
              </Button>
            )}
            {isPlaying && (
              <Button
                size="large"
                shape="circle"
                type="text"
                style={{ padding: "0" }}
                onClick={pause}
                id="pauseicon"
                className="hoverClass"
              >
                <img
                  src={pauseIcon}
                  style={{ width: "26px", height: "26px" }}
                />
              </Button>
            )}
            {isMute && (
              <Button
                size="large"
                shape="circle"
                type="text"
                style={{ padding: "0" }}
                onClick={toggleMute}
                id="muteicon"
                className="hoverClass"
              >
                <img src={muteIcon} style={{ width: "26px", height: "26px" }} />
              </Button>
            )}
            {!isMute && (
              <div className="volume-wrapper">
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  onClick={toggleMute}
                  id="unmuteicon"
                  className="hoverClass"
                >
                  <img
                    src={unMuteIcon}
                    style={{ width: "26px", height: "26px" }}
                  />
                </Button>
                <div className="volume-slider">
                  <Slider
                    size="medium"
                    defaultValue={Number(localStorage.getItem("volume")) || 70}
                    aria-label="Small"
                    valueLabelDisplay="none"
                    onChange={setVolumes}
                  />
                </div>
              </div>
            )}
          </div>
          <div id="controls-bottom-right" className="controls-bottom-right">
            {(isVisible || isFullScreen) && isLive ? (
              <div
                className="position-relative"
                onMouseEnter={handleTLMfilterButtonClick}
                onMouseLeave={closeAllPopups}
              >
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  id="tlmfiltericon"
                  // onMouseEnter={(e) => onMouseEnter(e)}
                  // onMouseLeave={(e) => onMouseLeave(e)}
                  className={`hoverClass ${
                    showTLMfilterPopupFlag ? "hover-enabled" : ""
                  }`}
                >
                  <img src={marker} style={{ width: "26px", height: "26px" }} />
                </Button>
                {showTLMfilterPopupFlag && showTLMfilterPopup()}
              </div>
            ) : null}
            {isVisible || isFullScreen ? (
              <div
                className="position-relative"
                onMouseEnter={handleQualityButtonClick}
                onMouseLeave={closeAllPopups}
              >
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  id="qualityicon"
                  className={`hoverClass ${
                    showQualityPopupFlag ? "hover-enabled" : ""
                  }`}
                  // onMouseEnter={(e) => onMouseEnter(e)}
                  // onMouseLeave={(e) => onMouseLeave(e)}
                >
                  <img
                    src={resolutionIcon}
                    style={{ width: "26px", height: "26px" }}
                  />
                </Button>
                {showQualityPopupFlag && showQualityPopup()}
              </div>
            ) : null}
            {isVisible || isFullScreen ? (
              <div
                className="position-relative"
                onMouseEnter={handleAudioButtonClick}
                onMouseLeave={closeAllPopups}
              >
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  className={`hoverClass ${
                    showAudioPopupFlag ? "hover-enabled" : ""
                  }`}
                  id="subtitleicon"
                  // onMouseEnter={(e) => onMouseEnter(e)}
                  // onMouseLeave={(e) => onMouseLeave(e)}
                >
                  <img
                    src={subtitlesIcon}
                    style={{ width: "26px", height: "26px" }}
                  />
                </Button>
                {showAudioPopupFlag && showAudioPopup()}
              </div>
            ) : null}
            <div className="position-relative">
              {!isFullScreen ? (
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  onClick={handleFullScreen}
                  id="fullscreenison"
                  className="hoverClass"
                >
                  <img
                    src="/images/fullscreen_icon.svg"
                    style={{ width: "26px", height: "26px" }}
                  />
                </Button>
              ) : (
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  style={{ padding: "0" }}
                  onClick={handleFullScreen}
                  id="exitfullscreenison"
                  className="hoverClass"
                >
                  <img src={exitFullScreenIcon} style={{ maxWidth: "60%" }} />
                </Button>
              )}
            </div>
          </div>
        </Space>
      </div>
      {brandingData.length > 0 && isLive && isVisible && (
        <img className={`branding-logo-position ${showControls ? "display-none" : ""}`} src={imagePathPrefix + brandingData[0].partnerPlayerLogo} />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({});

export default connect((state) => {
  const { teams } = state;
  return { teams };
}, mapDispatchToProps)(VideoPlayerControls);
