const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "matchdata":
      if (action.payload) {
        return { ...action.payload };
      }
      return null;
    default:
      return state;
  }
};

export default reducer;
