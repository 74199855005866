import React, { useState, Fragment, useEffect } from "react";
// import "antd/dist/antd.css";
import { Space, Col, Row } from "antd";
import { Radio } from "antd";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import {  useParams } from "react-router-dom";



export default function SportsPoll(props) {
  const { matchId } = useParams();
  const { team1,team2, matchdata} = props;
  const [value, setValue] = useState("Argentina");
  const [votes, setVote] = React.useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [showProgress, setShowProgress] = React.useState(false);


  useEffect(() => {
    if(team1 && team2 ){
      var dummyVotes = [
        { name: matchId == "g2257442" ? "Manchester City" : "Manchester United", vote: 3, value: "Argentina" },
        { name: matchId == "g2257442" ? "Chelsea" : "Middlesrough", vote: 6, value: "Brazil" }
      ];
      setVote(dummyVotes);
    }

 
  
    
  }, [team1,team2])
  

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//widget.tagembed.com/embed.min.js";
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const renderProgressBar = (vote) => {
    const { percentage } = vote;
    return (
      <div className="progress" style={{marginBottom:"15px"}}>
        <Progress
          percent={percentage}
          theme={{
            default: {
              symbol: percentage + "%",
              color: "#ffffff"
            },
            success: {
              symbol: percentage + "%",
              color: "green"
            }
          }}
        />
      </div>
    );
  };
  const renderAllProgress = () => {
    if (!showProgress) {
      return null;
    }
    return votes.map((vote) => {
      return (
        <Fragment key={vote.value}>
          <div className="teams-option" style={{ width: "50%" }}>
            {vote.name}
            {renderProgressBar(vote)}
          </div>
        </Fragment>
      );
    });
  };
  const submitVote = () => {
    const newVotes = votes.map((vote) => {
      vote.vote += vote.value === value ? 1 : 0;
      return vote;
    });
    const votesWithPercentage = newVotes.map((vote) => {
      vote.percentage = Math.round((vote.vote / getTotalVote()) * 100);
      return vote;
    });
    setVote(votesWithPercentage);
    setShowProgress(true);
    setIsClicked(true);
  };

  const getTotalVote = () => {
    return votes.reduce((acc, vote) => acc + vote.vote, 0);
  };

  const renderVote = () => {
    if (showProgress) {
      return null;
    }
    return (
      <Radio.Group onChange={handleChange} value={value} >
        {votes.map((vote) => {
          return (
            <Radio key={vote.value} value={vote.value} className="abc">
              <p className="teams-option">{vote.name}</p>
            </Radio>
          );
        })}
      </Radio.Group>
    );
  };
  return (
    <Row style={{ marginBottom: "50px" }}>
      <Col span={22} offset={1}>
        <Row mb={3}>
          <div className="sports-poll-left-div">
            <div className="sports-poll">
              <div>
                <p className="sports-poll-series">
                {team1.teamName} Vs {team2.teamName} 3rd match England FA Cup 2022
                </p>
                <span className="sports-poll-question">
                  Who will win today’s match between {team1.teamName} and {team2.teamName} in {matchdata.matchdata.Stat[0]["@value"]}?
                </span>
              </div>
              <div className="sports-poll-options">
                {renderVote()}
                {!showProgress && (
                  <button className="vote-button" onClick={submitVote}>
                    VOTE
                  </button>
                )}
              </div>
              {isClicked && renderAllProgress()}
            </div>
          </div>
          <div className="sports-poll-right-div">
            <div
              className="tagembed-container"
              style={{ width: "500", height:"800",overflow: "auto" }}
            >
              <div
                className="tagembed-socialwall"
                data-wall-id="62503"
                view-url="https://widget.tagembed.com/62503?view"
              ></div>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
}
