import { CloseOutlined } from "@ant-design/icons";
import { width } from "@mui/system";
import { memo } from "react";
import ReelsCarousel from "./ReelsCarousel";
import { Modal } from "./styled-commponents";

const Reels = memo(({ isReelsVisible, setIsReelsVisible }) => {
  return (
    <Modal
      width={"100%"}
      centered
      closable={true}
      title={null}
      footer={null}
      visible={isReelsVisible}
      onCancel={() => setIsReelsVisible(false)}
      closeIcon={<CloseOutlined style = {{color:"white", height:"50px", width:"50px"}}/>}
      destroyOnClose
    >
      <ReelsCarousel />
    </Modal>
  );
});

export default Reels;
