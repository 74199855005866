import React, { createRef, memo, useEffect, useState } from "react";
import { Button, Carousel, Row } from "antd";
import ReelsPlayer from "./ReelsPlayer";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ReelsCarouselCol, ReelsLoader } from "./styled-commponents";
import reels from "./assets/reels.mock.json";

const ReelsCarousel = memo(() => {
  const [currentReelSlide, setCurrentReelSlide] = useState(0);
  const [videoPlayers, setVideoPlayers] = useState([]);
  const carouselRef = createRef();

  useEffect(() => {
    // set player for 1st reel
    const firstReelPlayerOptions = setOptionsForPlayer(0, true);
    const firstReelPlayer = new window.TTNPlayer(firstReelPlayerOptions);
    // set player for 2nd reel
    const nextReelVideoPlayerOptions = setOptionsForPlayer(1);
    const nextReelVideoPlayer = new window.TTNPlayer(
      nextReelVideoPlayerOptions
    );
    setVideoPlayers([firstReelPlayer, nextReelVideoPlayer]);
  }, []);

  const setOptionsForPlayer = (index, autoPlay = false) => {
    return {
      muted: true,
      preload: true,
      autoplay: autoPlay,
      videoId: reels[index].id,
      videoWrapperID: reels[index].wrapperId,
      playerMetadata: {
        url: reels[index].url,
        isLive: false,
        isEncrypted: false,
        playerCallback: () => {}
      }
    };
  };
  const updatePlayers = (index, isNextReelPlay = true, isLastReel = false) => {
    if (isLastReel) {
      videoPlayers[1].play();
      setVideoPlayers([videoPlayers[1]]);
      return;
    }
    // if next reel to be played
    if (isNextReelPlay) {
      const options = setOptionsForPlayer(index);
      const nextReelVideoPlayer = new window.TTNPlayer(options);
      videoPlayers[1].play();
      setVideoPlayers([videoPlayers[1], nextReelVideoPlayer]);
    } else {
      // if prev reel to be played
      const options = setOptionsForPlayer(index, true);
      const prevReelVideoPlayer = new window.TTNPlayer(options);
      videoPlayers[0].pause();
      setVideoPlayers([prevReelVideoPlayer, videoPlayers[0]]);
    }
  };

  const onSlideChange = () => {
    const currentSlideIndex =
      carouselRef.current?.innerSlider?.state?.currentSlide;
    // If the current reel is last
    if (currentSlideIndex === reels.length - 1 && currentSlideIndex !== currentReelSlide) {
      setCurrentReelSlide(
        carouselRef.current?.innerSlider?.state?.currentSlide
      );
      videoPlayers[0].destroy();
      updatePlayers(
        carouselRef.current?.innerSlider?.state?.currentSlide,
        true,
        true
      );
    }
    // if next reel to be played
    else if (currentSlideIndex === currentReelSlide + 1) {
      setCurrentReelSlide(
        carouselRef.current?.innerSlider?.state?.currentSlide
      );
      videoPlayers[0].destroy();
      updatePlayers(carouselRef.current?.innerSlider?.state?.currentSlide + 1);
    }
    // if prev reel to be played
    else if (currentSlideIndex === currentReelSlide - 1) {
      setCurrentReelSlide(
        carouselRef.current?.innerSlider?.state?.currentSlide
      );
      videoPlayers[1] && videoPlayers[1].destroy();
      updatePlayers(
        carouselRef.current?.innerSlider?.state?.currentSlide,
        false
      );
    }
  };

  const playNextReel = () => {
    document.getElementsByClassName("right-btn-reel")[0].click();
  };

  const fetchPlayerforReel = (index) => {
    if (currentReelSlide === index) return videoPlayers[0];
    else if (index === currentReelSlide + 1) return videoPlayers[1];
    else return null;
  };

  return (
    <Row align={"middle"} justify={"center"}>
      <ReelsCarouselCol span={2}>
        <Button
          icon={<LeftOutlined />}
          className={"reel-slide-nav-btn"}
          onClick={() => carouselRef.current.prev()}
          disabled={currentReelSlide === 0}
        />
      </ReelsCarouselCol>
      <ReelsCarouselCol span={15}>
        <Carousel
          afterChange={onSlideChange}
          ref={carouselRef}
          dots={false}
          draggable={true}
          initialSlide={0}
          infinite={false}
          swipe={true}
          swipeToSlide={true}
        >
          {reels.map((reel, index) => (
            <ReelsPlayer
              key={reel.id + currentReelSlide}
              reel={reel}
              isCurrentSlideActive={currentReelSlide === index}
              isNextSlide={index === currentReelSlide + 1}
              playNextReel={playNextReel}
              videoPlayer={fetchPlayerforReel(index)}
            />
          ))}
        </Carousel>
      </ReelsCarouselCol>
      <ReelsCarouselCol span={2}>
        <Button
          icon={<RightOutlined />}
          className={"reel-slide-nav-btn right-btn-reel"}
          onClick={() => carouselRef.current.next()}
          disabled={currentReelSlide === reels.length - 1}
        />
      </ReelsCarouselCol>
    </Row>
  );
});

export default ReelsCarousel;
