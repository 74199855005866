const initialState = {data: []};

const reducer = (state = initialState, action) => {
  switch (action.type) {
      case "voddata":
        if (action.payload) {
          return { data: [...action.payload] };
        }
        return null;
    default:
      return state;
  }
};

export default reducer;
