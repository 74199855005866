import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Grid } from "@mui/material";
import { message } from "antd";
import { stringToSlug } from "store/utils";
import "./style.scss";

export default function PopUp({ roomName, setIsPopUpOpen }) {
  const closePopUp = () => {
    setIsPopUpOpen(false);
  };

  const copyUrl = () => {
    const { origin, pathname } = window.location;
    const url = `${origin}${pathname}?room=${roomName}`;
    navigator.clipboard
      .writeText(url)
      .then((d) => {
        message.success("Link copied to clipboard!").then();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="pop-up-container" id="pop-up">
      <span style={{ color: "white" }}>Share Link</span>
      <img
        src="/images/Cross.svg"
        style={{
          float: "right",
          width: "20px",
          height: "20px",
          cursor: "pointer"
        }}
        onClick={closePopUp}
      />
      <Grid>
        <div className="invite-text">
          Invite your friends to enjoy the watch party
        </div>
        <div className="line-2" style={{ margin: "2vw 0" }}></div>
        <div className="invite-by-link">
          <div className="link-icon">
            <InsertLinkIcon style={{ color: "white" }} />
          </div>
          <p style={{ marginTop: "15px" }}>Copy and share the link</p>
          <button className="banner-watch-now" onClick={copyUrl}>
            Copy
          </button>
        </div>

        <div className="or-text">OR</div>
        <div className="invite-on-social-media">
          <div>
            <p>Invite on social media</p>
            <Grid>
              <a href="" target={"_blank"} className="socialLink">
                <img src="/images/facebook.svg" alt="Instagram" />
              </a>
              <a href="" target={"_blank"} className="socialLink">
                <img src="/images/youtube.svg" alt="Instagram" />
              </a>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
}
