import { Button, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
// import "../../../pages/MatchPlayer/WatchParty/style.scss";

const ParticipantVideo = ({
  name,
  toggleAudio,
  toggleVideo,
  participant,
  participantListeners
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const [isLocal, setIsLocal] = useState(false);
  const [currentParticipantId, setCurrentParticipantId] = useState("");
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const video = useRef(null);

  const onParticipantMediaStreamChanged = (mediaStreamChanged) => {
    const { participantId, mediaType, mediaState } = mediaStreamChanged;
    // console.log("Participant ::", participantId, currentParticipantId);
    if (participantId == currentParticipantId) {
      // console.log("Participant ::", mediaStreamChanged);
      switch (mediaType) {
        case "AUDIO":
          if (mediaState == "ENABLED") {
            setIsMuted(false);
          } else if (mediaState == "DISABLED") {
            setIsMuted(true);
          }
          break;
        case "VIDEO":
          if (mediaState == "ENABLED") {
            setIsVideoEnabled(true);
          } else if (mediaState == "DISABLED") {
            setIsVideoEnabled(false);
          }
          break;
      }
    }
  };

  useEffect(() => {
    if (currentParticipantId) {
      participantListeners.onParticipantMediaStreamChanged(
        onParticipantMediaStreamChanged
      );
    }
  }, [currentParticipantId]);

  useEffect(() => {
    if (participant) {
      const { isLocal, stream, participantId } = participant;
      setCurrentParticipantId(participantId);
      setIsLocal(isLocal);
      console.log("Participant ::", participant);
      if (stream && video) {
        video.current.srcObject = stream;
        video.current.onclick = () => false;
        video.current.ondblclick = toggleFullscreen;
      }
    }
  }, [participant]);

  const togglePopUp = () => {
    setIsPopUpOpen(true);
  };

  const toggleFullscreen = (e) => {
    if (!document.fullscreenElement) {
      const fullScreenFunc =
        e.target.requestFullscreen ||
        e.target.mozRequestFullScreen ||
        e.target.webkitRequestFullscreen ||
        e.target.msRequestFullscreen;

      if (fullScreenFunc) {
        fullScreenFunc();
      }

      e.target.controls = false;
    } else {
      document.exitFullscreen();
      e.target.controls = true;
    }
  };

  return (
    <div
      id={`video-${currentParticipantId}`}
      className={`video ${isLocal ? "local" : ""}`}
      style={{ marginTop: "15px" }}
    >
      <div className="participant-wrapper" id={currentParticipantId}>
        {isLocal ? (
          <Space
            style={{ position: "absolute", top: "5%", right: "3%", zIndex: 1 }}
          >
            <Button
              shape="circle"
              style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
              className={`toggleVideo ${
                isVideoEnabled ? "videoOff" : "videoOn"
              }`}
              onClick={toggleVideo}
            >
              {isVideoEnabled ? (
                <VideocamOutlinedIcon />
              ) : (
                <VideocamOffOutlinedIcon />
              )}
            </Button>
            <Button
              shape="circle"
              style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
              className={`toggleAudio ${isMuted ? "muted" : "unmuted"}`}
              onClick={toggleAudio}
            >
              {isMuted ? <MicOffIcon /> : <MicIcon />}
            </Button>
          </Space>
        ) : (
          ""
        )}
        <video
          className="participant-video"
          id={`native-video-${currentParticipantId}`}
          ref={video}
          autoPlay
          muted={isLocal}
          controls={false}
          disablePictureInPicture
          playsInline
        />
      </div>
    </div>
  );
};

export default ParticipantVideo;
