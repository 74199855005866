import React from "react";
import { Grid } from "@mui/material";
import statTabData from "./WatchParty/StatTabData";
import { Fragment } from "react";

function StatsData(props) {
  const { team1, team2 } = props;
  return (
    <Fragment>
      <div style={{ padding: "30px" }}>
        <Grid className="stat-heading">
          <Grid style={{ marginBottom: "40px" }}>
            <span>Team Stats</span>
          </Grid>
          {statTabData.map((stat, index) => (
            <Grid className="stat-names" key={index}>
              <div className="stat_value">
                <p>{stat.teamA}</p>
                <p>{stat.statName}</p>

                <p>{stat.teamB}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Fragment>
  );
}

export default StatsData;
