import React, {memo} from "react";
import {Popover, Tooltip} from "antd";
import SocialShareList from "./SocialShareList";
import {ButtonStyled} from "./styled-components";

/**
 * Social share component
 * @description Used to show the share button for sharing the url to social platforms.
 * This component renders a share button on click of which the social sharing popover is visible
 * @props url {string}
 * @type {React.NamedExoticComponent<{readonly url?: *}>}
 */
const SocialShare = memo(({url}) => {
  return (
    <Popover color={"#2f3b4e"} overlayInnerStyle={{borderRadius:"7px"}} autoAdjustOverflow={true} arrowPointAtCenter={false} destroyTooltipOnHide placement={"bottomLeft"} title={null} content={<SocialShareList url={url}/>} trigger={"click"}>
      <Tooltip placement="top" align={{ offset: [0, 5]  }} title={"Share"}>
      <ButtonStyled size={"large"}>
        <img src="/images/share.svg" className={"anticon"} alt={"Share Button"}/>
      </ButtonStyled>
      </Tooltip>

    </Popover>
  )
}, (p, n) => p.url !== n.url);

export default SocialShare;