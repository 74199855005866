import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "hooks";
import { userLogout } from "store/userSlice";
import Grid from "@mui/material/Grid";
import "./style.scss";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Input } from "antd";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useTabStyles = makeStyles({
  tabLabel: {
    textTransform: "none",
    letterSpacing: "0.8px",
    fontWeight: "400"
  }
});

const { Header } = Layout;

export function findUrlPathId(path) {
  switch (path) {
    case "/signup":
      return "2";
    case "/login":
      return "3";
    case "/tasks":
      return "4";
    case "/dashboard":
      return "5";
    case "/tasks-list":
      return "6";
    default:
      return "1";
  }
}

function HeaderCustom() {
  const [value, setValue] = React.useState("one");
  const userData = useAuth();
  const dispatch = useDispatch();
  const [key, setKey] = useState();
  const location = useLocation();
  const history = useHistory();
  const classes = useTabStyles();

  useEffect(() => {
    const pathId = findUrlPathId(location?.pathname);
    setKey(pathId);
  }, [location?.pathname, userData, userData?._id]);

  const handleLogout = () => {
    console.log("handle logout called");
    dispatch(userLogout());
  };
  const navigateToHome = () => {
    history.push("/Home");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // if (userData && userData?._id) {
  // 	return (
  // 		<Header className='header'>
  // 			<Menu theme='dark' mode='horizontal' selectedKeys={key}>
  // 				<Menu.Item key='1'>
  // 					<Link to='/home' className='link'>
  // 						Home
  // 					</Link>
  // 				</Menu.Item>
  // 				<Menu.Item key='4'>
  // 					<Link to='/tasks' className='link'>
  // 						Tasks
  // 					</Link>
  // 				</Menu.Item>
  // 				<Menu.Item key='5'>
  // 					<Link to='/dashboard' className='link'>
  // 						Dashboard
  // 					</Link>
  // 				</Menu.Item>
  // 				<Menu.Item key='6'>
  // 					<Link to='/tasks-list' className='link'>
  // 						Tasks List
  // 					</Link>
  // 				</Menu.Item>

  // 				<Menu.Item key='99' onClick={handleLogout} className='link'>
  // 					<PoweroffOutlined />
  // 					Logout
  // 				</Menu.Item>
  // 			</Menu>
  // 		</Header>
  // 	);
  // }

  return (
    <div style={{ height: "auto" }}>
      <div className="header">
        <img src="/images/logo.png" onClick={navigateToHome} />

        <div className="header-tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab
              // icon={<img src="/images/Home.png" />}
              onClick={navigateToHome}
              label={<span className={classes.tabLabel}>Home</span>}
              value="one"
            />
            <Tab
              // icon={<img src="/images/football-1.svg" />}
              label={<span className={classes.tabLabel}>Football</span>}
              value="two"
            />
            <Tab
              // icon={<img src="/images/wwe.png" />}
              label={<span className={classes.tabLabel}>Wrestling</span>}
              value="three"
            />
            <Tab
              // icon={<img src="/images/Tennis.svg" />}
              label={<span className={classes.tabLabel}>Tennis</span>}
              value="four"
            />
            <Tab
              // icon={<img src="/images/football-1.svg" />}
              label={<span className={classes.tabLabel}>Basketball</span>}
              value="five"
            />
            <Tab
              // icon={<img src="/images/cricket.svg" />}
              label={<span className={classes.tabLabel}>Cricket</span>}
              value="six"
            />
            <Tab
              // icon={<img src="/images/vod.svg" />}
              label={<span className={classes.tabLabel}>VOD</span>}
              value="seven"
            />
            <Tab
              // icon={<img src="/images/more.svg" />}
              label={<span className={classes.tabLabel}>More</span>}
              value="eight"
            />
            <ArrowDropDownIcon
              style={{ marginLeft: "-15px", marginTop: "12px" }}
            />
          </Tabs>
        </div>
        <div className="right-nav">
          <Input
            placeholder="Search Sports, Teams or Players"
            className="search-bar"
            prefix={
              <img
                src="/images/search.svg"
                style={{ opacity: 1, height: "20px", width: "20px" }}
              />
            }
          />
          <img src="/images/profile.svg" />
        </div>
      </div>
    </div>
  );
}

export default HeaderCustom;
