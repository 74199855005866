import React, { memo } from "react";
import ReelsMetaData from "./ReelsMetaData";
import { ReelsVideoWrapper } from "./styled-commponents";

const ReelsPlayer= memo((props)=>{
  const {reel, isCurrentSlideActive, playNextReel, isNextSlide, videoPlayer} = props;

  if(!reel || !reel.id || !reel?.wrapperId || !reel?.url || (!isCurrentSlideActive && !isNextSlide)){
    return <></>;
  }

  // const options={
  //   muted: true,
  //   preload: true,
  //   autoplay: isCurrentSlideActive,
  //   videoId: reel.id,
  //   videoWrapperID: reel.wrapperId,
  //   playerMetadata: {
  //     url: reel.url,
  //     isLive: false,
  //     isEncrypted: false,
  //     playerCallback:()=>{},
  //   }
  // }

  // const videoPlayer = new window.TTNPlayer(options);

  return (
    <ReelsVideoWrapper id={reel.wrapperId} key={reel.id}>
      <ReelsMetaData metadata={reel.metadata} videoPlayerInstance={videoPlayer} playNextReel={playNextReel} isCurrentSlideActive={isCurrentSlideActive}/>
    </ReelsVideoWrapper>
  )
});

export default ReelsPlayer;
