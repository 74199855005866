const stats = {
    SoccerFeed: {
        SoccerDocument: {
          MatchData: [
            {
              MatchInfo: {
                Date: "2021-11-05 19:55:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t99",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4377"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Referee",
                      uID: "o45993"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Smith",
                      Type: "Lineman#1",
                      uID: "o49279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Webb",
                      Type: "Lineman#2",
                      uID: "o43311"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Young",
                      Type: "Fourth official",
                      uID: "o43475"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "MEL Group Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Sudbury", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t3265"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p49997",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p438654",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p221378",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p223335",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t99"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-05T22:24:38+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257456"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t2623",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "15235"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Woods",
                      Type: "Referee",
                      uID: "o48027"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Cooper",
                      Type: "Lineman#1",
                      uID: "o43577"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Lee",
                      Type: "Lineman#2",
                      uID: "o48517"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Aji",
                      LastName: "Ajibola",
                      Type: "Fourth official",
                      uID: "o48719"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Cherry Red Records Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p90938",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t2623"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t1520"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T12:04:21+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257469"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 17:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t573",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4314"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Rebecca",
                      LastName: "Welch",
                      Type: "Referee",
                      uID: "o47827"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Aylott",
                      Type: "Lineman#1",
                      uID: "o43414"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Hristo",
                      LastName: "Karaivanov",
                      Type: "Lineman#2",
                      uID: "o49220"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Hicks",
                      Type: "Fourth official",
                      uID: "o43403"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Banbury Plant Hire Community Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Banbury", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t3149"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p249931",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p87111",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p434138",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200731",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t573"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-08T09:19:43+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257454"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t2479",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4259"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sunny Sukhvir",
                      LastName: "Gill",
                      Type: "Referee",
                      uID: "o49211"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Simpson",
                      Type: "Lineman#1",
                      uID: "o57192"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jack",
                      LastName: "Fagg",
                      Type: "Lineman#2",
                      uID: "o52569"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gerry",
                      LastName: "Heron",
                      Type: "Fourth official",
                      uID: "o48917"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Meadow Park", "@attributes": { Type: "Venue" } },
                { "@value": "Borehamwood", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p55801",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p55801",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t2479"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2625"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T12:15:56+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257465"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "71"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Seb",
                      LastName: "Stockbridge",
                      Type: "Referee",
                      uID: "o42105"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Clayton",
                      Type: "Lineman#1",
                      uID: "o43340"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stephen",
                      LastName: "Wade",
                      Type: "Lineman#2",
                      uID: "o43918"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Locker",
                      Type: "Fourth official",
                      uID: "o52909"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "University of Bradford Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Bradford", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p36907",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t55"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p232271",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t71"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T12:30:12+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257442"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t68",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "19"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lewis",
                      LastName: "Smith",
                      Type: "Referee",
                      uID: "o49095"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Avent",
                      Type: "Lineman#1",
                      uID: "o43471"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Conor",
                      LastName: "Brown",
                      Type: "Lineman#2",
                      uID: "o48636"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adam",
                      LastName: "Williamson",
                      Type: "Fourth official",
                      uID: "o48916"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Brunton Park", "@attributes": { Type: "Venue" } },
                { "@value": "Carlisle", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p109432",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p475495",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t68"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2515"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T12:48:16+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257448"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t33",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "49"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Brook",
                      Type: "Referee",
                      uID: "o43332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nick",
                      LastName: "Dunn",
                      Type: "Lineman#1",
                      uID: "o48724"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Vallance",
                      Type: "Lineman#2",
                      uID: "o49425"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joel",
                      LastName: "Mannix",
                      Type: "Fourth official",
                      uID: "o49576"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The Valley", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78154",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78154",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p534392",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p495722",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t33"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t586"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T13:10:12+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257475"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t98",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "6631"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Simpson",
                      Type: "Referee",
                      uID: "o52118"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steve",
                      LastName: "Durnall",
                      Type: "Lineman#1",
                      uID: "o52137"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Chalkley",
                      Type: "Lineman#2",
                      uID: "o52810"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gareth",
                      LastName: "Thomas",
                      Type: "Fourth official",
                      uID: "o60456"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Technique Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Chesterfield", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p489543",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p138005",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p135715",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t98"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p59941",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t81"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T13:38:28+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257461"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t44",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4223"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Charles",
                      LastName: "Breakspear",
                      Type: "Referee",
                      uID: "o42352"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Samuel",
                      LastName: "Ogles",
                      Type: "Lineman#1",
                      uID: "o49212"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Bennett",
                      Type: "Lineman#2",
                      uID: "o42742"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Louis",
                      LastName: "Marks",
                      Type: "Fourth official",
                      uID: "o46876"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Broadfield Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Crawley", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t1525"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p61538",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t44"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:06:47+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257477"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t46",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "67"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Trevor",
                      LastName: "Kettle",
                      Type: "Referee",
                      uID: "o40918"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gary",
                      LastName: "Hilton",
                      Type: "Lineman#1",
                      uID: "o43344"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Wilson",
                      Type: "Lineman#2",
                      uID: "o43522"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dale",
                      LastName: "Baines",
                      Type: "Fourth official",
                      uID: "o49115"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Mornflake Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Crewe", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t51"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p179792",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p476773",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p179792",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t46"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T14:16:01+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257473"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t69",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "20"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jacob",
                      LastName: "Miles",
                      Type: "Referee",
                      uID: "o48716"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Oliver",
                      LastName: "Noonan",
                      Type: "Lineman#1",
                      uID: "o60569"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Morland",
                      Type: "Lineman#2",
                      uID: "o54273"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Blake",
                      LastName: "Antrobus",
                      Type: "Fourth official",
                      uID: "o48715"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The MBi Shay Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Halifax", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p211202",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p232951",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p195850",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p134614",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p89328",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p134614",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p109431",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "4",
                    Score: "7",
                    Side: "Home",
                    TeamRef: "t69"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p464597",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p464597",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p243481",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p243481",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "3",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t820"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T14:35:09+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257460"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t587",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "3306"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Reeves",
                      Type: "Referee",
                      uID: "o48725"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Freeman",
                      Type: "Lineman#1",
                      uID: "o52068"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Graham",
                      Type: "Lineman#2",
                      uID: "o42738"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Aspinall",
                      Type: "Fourth official",
                      uID: "o49339"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Highbury Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Fleetwood", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p40662",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t2048"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p195860",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p523700",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t587"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T14:44:30+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257455"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "270"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Thomas",
                      LastName: "Parsons",
                      Type: "Referee",
                      uID: "o49657"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Parker",
                      Type: "Lineman#1",
                      uID: "o60887"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Sowerby",
                      Type: "Lineman#2",
                      uID: "o52672"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Taylor",
                      Type: "Fourth official",
                      uID: "o57045"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Gateshead International Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Gateshead", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p132931",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p155509",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t447"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p42529",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p249084",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t295"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T14:51:01+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257481"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "98"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Yates",
                      Type: "Referee",
                      uID: "o43584"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Cooper",
                      Type: "Lineman#1",
                      uID: "o43491"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joseph",
                      LastName: "Stokes",
                      Type: "Lineman#2",
                      uID: "o53378"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Bonneywell",
                      Type: "Fourth official",
                      uID: "o49545"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Priestfield Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Gillingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p536566",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t100"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p467311",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t87"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:38:43+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257452"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t671",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "2875"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Oldham",
                      Type: "Referee",
                      uID: "o44065"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joe",
                      LastName: "Simpson",
                      Type: "Lineman#1",
                      uID: "o42017"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Ward",
                      Type: "Lineman#2",
                      uID: "o43907"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Benjamin",
                      LastName: "Wyatt",
                      Type: "Fourth official",
                      uID: "o55826"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "EnviroVent Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Harrogate", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p221285",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p443745",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t671"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p242701",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t109"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:05:56+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257470"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "87"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Drysdale",
                      Type: "Referee",
                      uID: "o40946"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ivan",
                      LastName: "Stankovic",
                      Type: "Lineman#1",
                      uID: "o43624"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graeme",
                      LastName: "Fyvie",
                      Type: "Lineman#2",
                      uID: "o43468"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jonny",
                      LastName: "Urwin",
                      Type: "Fourth official",
                      uID: "o53027"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Suit Direct Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Hartlepool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p80281",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p154581",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t89"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p36951",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p549841",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t112"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:11:54+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257471"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t296",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "14457"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sam",
                      LastName: "Allison",
                      Type: "Referee",
                      uID: "o46099"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stephen",
                      LastName: "Brown",
                      Type: "Lineman#1",
                      uID: "o43362"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Kidd",
                      Type: "Lineman#2",
                      uID: "o46875"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Bishop",
                      Type: "Fourth official",
                      uID: "o48700"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "SKYex Community Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Hayes", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t2627"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p180923",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t296"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:12:44+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257445"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "56"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Hair",
                      Type: "Referee",
                      uID: "o43495"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Lineman#1",
                      uID: "o43328"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Kelly",
                      Type: "Lineman#2",
                      uID: "o43342"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Danaher",
                      Type: "Fourth official",
                      uID: "o58973"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Portman Road", "@attributes": { Type: "Venue" } },
                { "@value": "Ipswich", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p149929",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t40"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p231487",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t105"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T15:45:34+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257467"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t285",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "111"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gareth",
                      LastName: "Rhodes",
                      Type: "Referee",
                      uID: "o48652"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Hutton",
                      Type: "Lineman#1",
                      uID: "o57094"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Evans",
                      Type: "Lineman#2",
                      uID: "o49547"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Ellis",
                      Type: "Fourth official",
                      uID: "o48732"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Aggborough Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Kidderminster", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p57126",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t285"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t53"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-16T10:26:52+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257463"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t42",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "2614"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Lewis",
                      Type: "Referee",
                      uID: "o42106"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Da Costa",
                      Type: "Lineman#1",
                      uID: "o42750"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Declan",
                      LastName: "Ford",
                      Type: "Lineman#2",
                      uID: "o42104"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Humphries",
                      Type: "Fourth official",
                      uID: "o48733"
                    }
                  }
                ]
              },
              Stat: {
                "@value": "The Walks Stadium",
                "@attributes": { Type: "Venue" }
              },
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t6152"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p93665",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t42"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-19T10:28:40+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257472"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t85",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "2737"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Will",
                      LastName: "Finnie",
                      Type: "Referee",
                      uID: "o48562"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Wade",
                      Type: "Lineman#1",
                      uID: "o44468"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Leigh",
                      LastName: "Crowhurst",
                      Type: "Lineman#2",
                      uID: "o52126"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lloyd",
                      LastName: "Wood",
                      Type: "Fourth official",
                      uID: "o43612"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Brisbane Road", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p428683",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t85"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t585"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:16:58+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257478"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t83",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "81"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Edwards",
                      Type: "Referee",
                      uID: "o42747"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Wilding",
                      Type: "Lineman#1",
                      uID: "o49214"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Geoff",
                      LastName: "Russell",
                      Type: "Lineman#2",
                      uID: "o41349"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Massey-Ellis",
                      Type: "Fourth official",
                      uID: "o41915"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "LNER Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Lincoln", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p215062",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t83"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t9368"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:20:21+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257482"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "3678"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Mather",
                      Type: "Referee",
                      uID: "o43592"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Morris",
                      Type: "Lineman#1",
                      uID: "o56427"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Rushton",
                      Type: "Lineman#2",
                      uID: "o41179"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gregory",
                      LastName: "Read",
                      Type: "Fourth official",
                      uID: "o49099"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Stadium mk", "@attributes": { Type: "Venue" } },
                { "@value": "Milton Keynes", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p241279",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p459746",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t22"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p167807",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p214224",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t288"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:21:02+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257479"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t286",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "7483"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Chris",
                      LastName: "Sarginson",
                      Type: "Referee",
                      uID: "o41056"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Atkin",
                      Type: "Lineman#1",
                      uID: "o43323"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Jones",
                      Type: "Lineman#2",
                      uID: "o43351"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Holmes",
                      Type: "Fourth official",
                      uID: "o48713"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Mazuma Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Morecambe", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p73257",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t286"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t437"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:32:12+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257453"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "26"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Coy",
                      Type: "Referee",
                      uID: "o43570"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adrian",
                      LastName: "Tranter",
                      Type: "Lineman#1",
                      uID: "o43368"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marvyn",
                      LastName: "Amphlett",
                      Type: "Lineman#2",
                      uID: "o43376"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Howick",
                      Type: "Fourth official",
                      uID: "o48654"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Sixfields Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Northampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p168409",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p467291",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t75"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p199053",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p153477",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t101"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:37:44+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257457"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t50",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "66"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Speedie",
                      Type: "Referee",
                      uID: "o48271"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Bradley",
                      LastName: "Hall",
                      Type: "Lineman#1",
                      uID: "o49424"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Shaw",
                      Type: "Lineman#2",
                      uID: "o49143"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Hendley",
                      Type: "Fourth official",
                      uID: "o41488"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Vale Park", "@attributes": { Type: "Venue" } },
                { "@value": "Stoke-on-Trent", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p112139",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p112139",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p112139",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p232960",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p433172",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "5",
                    Side: "Home",
                    TeamRef: "t50"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p201083",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t888"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:52:36+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257480"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t47",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "63"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Tallis",
                      Type: "Referee",
                      uID: "o53028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Butler",
                      Type: "Lineman#1",
                      uID: "o43484"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Blunden",
                      Type: "Lineman#2",
                      uID: "o43578"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Ryan",
                      Type: "Fourth official",
                      uID: "o41016"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Fratton Park", "@attributes": { Type: "Venue" } },
                { "@value": "Portsmouth", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p167191",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t47"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t3120"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-06T17:07:05+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257451"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t72",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "8148"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Declan",
                      LastName: "Bourne",
                      Type: "Referee",
                      uID: "o46140"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Grant",
                      LastName: "Taylor",
                      Type: "Lineman#1",
                      uID: "o43415"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Williams",
                      Type: "Lineman#2",
                      uID: "o48703"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Aaron",
                      LastName: "Bannister",
                      Type: "Fourth official",
                      uID: "o55276"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "AESSEAL New York Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Rotherham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p94926",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p220200",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p59037",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t72"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2050"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T15:59:27+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257450"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 13:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t290",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "91"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Boyeson",
                      Type: "Referee",
                      uID: "o41007"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Wayne",
                      LastName: "Grunnill",
                      Type: "Lineman#1",
                      uID: "o43429"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kenwyn",
                      LastName: "Hughes",
                      Type: "Lineman#2",
                      uID: "o48978"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Moore",
                      Type: "Fourth official",
                      uID: "o45994"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Glanford Park", "@attributes": { Type: "Venue" } },
                { "@value": "Scunthorpe", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t93"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p40548",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t290"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-10T17:07:41+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257449"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t77",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "72"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Oldham",
                      Type: "Referee",
                      uID: "o49365"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Wigglesworth",
                      Type: "Lineman#1",
                      uID: "o42142"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Johnathon",
                      LastName: "Bickerdike",
                      Type: "Lineman#2",
                      uID: "o60149"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Dicicco",
                      Type: "Fourth official",
                      uID: "o43374"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Stadium of Light", "@attributes": { Type: "Venue" } },
                { "@value": "Sunderland", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t56"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p113804",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t77"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-10T16:00:51+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257444"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "2"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Middleton",
                      Type: "Referee",
                      uID: "o48048"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Billy",
                      LastName: "Smallwood",
                      Type: "Lineman#1",
                      uID: "o42019"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Oliver",
                      LastName: "Bickle",
                      Type: "Lineman#2",
                      uID: "o46868"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kristian",
                      LastName: "Silcock",
                      Type: "Fourth official",
                      uID: "o53259"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "DW Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Wigan", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t111"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t1493"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-06T16:57:48+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257464"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t283",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4326"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Russell",
                      Type: "Referee",
                      uID: "o53672"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Hugh",
                      LastName: "Gilroy",
                      Type: "Lineman#1",
                      uID: "o52174"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Myatt",
                      Type: "Lineman#2",
                      uID: "o52609"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Perry",
                      Type: "Fourth official",
                      uID: "o52486"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The Jelf Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Yate", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t3159"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p173796",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p197401",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p224946",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p92492",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p497375",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "3",
                    Score: "5",
                    Side: "Away",
                    TeamRef: "t283"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-08T16:22:18+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257615"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-06 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t3145",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "15483"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Aaron",
                      LastName: "Jackson",
                      Type: "Referee",
                      uID: "o48580"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jack",
                      LastName: "Forder",
                      Type: "Lineman#1",
                      uID: "o60888"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alex",
                      LastName: "Gray",
                      Type: "Lineman#2",
                      uID: "o49254"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jack",
                      LastName: "Hall",
                      Type: "Fourth official",
                      uID: "o54115"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "LNER Community Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "York", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t78"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p147891",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t3145"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-07T14:42:01+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257614"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "47"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Nield",
                      Type: "Referee",
                      uID: "o43417"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Woodward",
                      Type: "Lineman#1",
                      uID: "o43914"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Mulraine",
                      Type: "Lineman#2",
                      uID: "o43373"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Duckworth",
                      Type: "Fourth official",
                      uID: "o52426"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "University of Bolton Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Bolton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p87428",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p63497",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t30"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p149327",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p168758",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t48"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-09T14:25:37+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257474"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 13:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "2121"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Howard",
                      Type: "Referee",
                      uID: "o45995"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Wilkes",
                      Type: "Lineman#1",
                      uID: "o43278"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Morris",
                      Type: "Lineman#2",
                      uID: "o43909"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Chard",
                      Type: "Fourth official",
                      uID: "o55825"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Kassam Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Oxford", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p106257",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p183650",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t106"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p85885",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p205174",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t96"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-09T14:13:55+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257476"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "80"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ross",
                      LastName: "Joyce",
                      Type: "Referee",
                      uID: "o42163"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Parry",
                      Type: "Lineman#1",
                      uID: "o43353"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Cunliffe",
                      Type: "Lineman#2",
                      uID: "o46870"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Clayton",
                      Type: "Fourth official",
                      uID: "o43312"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Crown Oil Arena", "@attributes": { Type: "Venue" } },
                { "@value": "Rochdale", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p209793",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t82"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p194456",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t104"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-09T14:36:22+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257462"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 12:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "41"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Backhouse",
                      Type: "Referee",
                      uID: "o42686"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Chris",
                      LastName: "Isherwood",
                      Type: "Lineman#1",
                      uID: "o46138"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Newhouse",
                      Type: "Lineman#2",
                      uID: "o46457"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Kitchen",
                      Type: "Fourth official",
                      uID: "o53449"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Hillsborough", "@attributes": { Type: "Venue" } },
                { "@value": "Sheffield", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t19"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t76"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-07T14:40:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257446"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 17:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t2246",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4232"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Madley",
                      Type: "Referee",
                      uID: "o41412"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Williams",
                      Type: "Lineman#1",
                      uID: "o55040"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ryan",
                      LastName: "Whitaker",
                      Type: "Lineman#2",
                      uID: "o52991"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Will",
                      LastName: "Finnie",
                      Type: "Fourth official",
                      uID: "o48562"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Clarence Park", "@attributes": { Type: "Venue" } },
                { "@value": "St. Albans", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p231985",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p171561",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p74313",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t2246"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p200650",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p196402",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t302"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-08T10:38:16+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257459"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-07 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t74",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4471"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Toner",
                      Type: "Referee",
                      uID: "o42673"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Stonier",
                      Type: "Lineman#1",
                      uID: "o52992"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dumitru-Ravel",
                      LastName: "Cheosiaua",
                      Type: "Lineman#2",
                      uID: "o44331"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Fourth official",
                      uID: "o45993"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Arden Garages Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Stratford upon Avon", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p497145",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t3364"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p78608",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p120510",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p515846",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78608",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p41262",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "5",
                    Side: "Away",
                    TeamRef: "t74"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-08T13:10:40+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257458"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-08 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Regular",
                  MatchWinner: "t3448",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "3380"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Rock",
                      Type: "Referee",
                      uID: "o43493"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gregory",
                      LastName: "Read",
                      Type: "Lineman#1",
                      uID: "o49099"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Aaron",
                      LastName: "Farmer",
                      Type: "Lineman#2",
                      uID: "o53016"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Brook",
                      Type: "Fourth official",
                      uID: "o43332"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Chigwell Construction Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Dagenham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t457"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p168648",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t3448"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-08T22:38:04+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2257447"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t447",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "3612"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Woods",
                      Type: "Referee",
                      uID: "o48027"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Morland",
                      Type: "Lineman#1",
                      uID: "o54273"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steve",
                      LastName: "Durnall",
                      Type: "Lineman#2",
                      uID: "o52137"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Claudio",
                      LastName: "Gavillucci",
                      Type: "Fourth official",
                      uID: "o42157"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "J. Davidson Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Altrincham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p189599",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p442544",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t295"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p431956",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p431956",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p198235",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t447"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:11:56+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258642"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t96",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "1",
                  MatchType: "Replay",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "94"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Hair",
                      Type: "Referee",
                      uID: "o43495"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joe",
                      LastName: "Clark",
                      Type: "Lineman#1",
                      uID: "o43655"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Williams",
                      Type: "Lineman#2",
                      uID: "o48703"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dale",
                      LastName: "Wootton",
                      Type: "Fourth official",
                      uID: "o50078"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Memorial Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Bristol", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p85885",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p197394",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p461170",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p461170",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "3",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t96"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p106257",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p204143",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p82248",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "2",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t106"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T09:44:55+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258671"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t101",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "99"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Drysdale",
                      Type: "Referee",
                      uID: "o40946"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stephen",
                      LastName: "Finch",
                      Type: "Lineman#1",
                      uID: "o43472"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Cooper",
                      Type: "Lineman#2",
                      uID: "o43491"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Da Costa",
                      Type: "Fourth official",
                      uID: "o42750"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Abbey Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Cambridge", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p199053",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p215069",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p434137",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t101"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p96783",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t75"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:18:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258645"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t87",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "85"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Swabey",
                      Type: "Referee",
                      uID: "o42351"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Laver",
                      Type: "Lineman#1",
                      uID: "o43402"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Thomas",
                      LastName: "Harty",
                      Type: "Lineman#2",
                      uID: "o46867"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Plane",
                      Type: "Fourth official",
                      uID: "o43536"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Completely-Suzuki Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Cheltenham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p467311",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t87"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t100"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:22:29+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258640"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t82",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "102"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Referee",
                      uID: "o45993"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Freeman",
                      Type: "Lineman#1",
                      uID: "o52068"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Smith",
                      Type: "Lineman#2",
                      uID: "o49279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Oliver",
                      LastName: "Mackey",
                      Type: "Fourth official",
                      uID: "o56428"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Meadow Lane", "@attributes": { Type: "Venue" } },
                { "@value": "Nottingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p56240",
                      Type: "Own"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t104"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p448360",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p183386",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t82"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:36:14+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258669"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t40",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "103"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Boyeson",
                      Type: "Referee",
                      uID: "o41007"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Bartlett",
                      Type: "Lineman#1",
                      uID: "o43524"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joe",
                      LastName: "Simpson",
                      Type: "Lineman#2",
                      uID: "o42017"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Crusham",
                      Type: "Fourth official",
                      uID: "o49968"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Boundary Park", "@attributes": { Type: "Venue" } },
                { "@value": "Oldham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p169483",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t105"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p196411",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p441598",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t40"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:40:54+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258646"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t76",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "27"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Chris",
                      LastName: "Sarginson",
                      Type: "Referee",
                      uID: "o41056"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Samuel",
                      LastName: "Ogles",
                      Type: "Lineman#1",
                      uID: "o49212"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Robinson",
                      Type: "Lineman#2",
                      uID: "o55079"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Brett",
                      LastName: "Huxtable",
                      Type: "Fourth official",
                      uID: "o42682"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Home Park", "@attributes": { Type: "Venue" } },
                { "@value": "Plymouth", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p167724",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p222840",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p222840",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t76"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t19"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:45:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258668"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t111",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "1",
                  MatchType: "Replay",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "2616"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Charles",
                      LastName: "Breakspear",
                      Type: "Referee",
                      uID: "o42352"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Stonier",
                      Type: "Lineman#1",
                      uID: "o52992"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kenwyn",
                      LastName: "Hughes",
                      Type: "Lineman#2",
                      uID: "o48978"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Nield",
                      Type: "Fourth official",
                      uID: "o43417"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "ARMCO Arena", "@attributes": { Type: "Venue" } },
                { "@value": "Solihull", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p28585",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t1493"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p192829",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p222663",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t111"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-17T09:32:52+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258647"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t288",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "1",
                  MatchType: "Replay",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "2882"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Young",
                      Type: "Referee",
                      uID: "o43475"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Bonneywell",
                      Type: "Lineman#1",
                      uID: "o49545"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Garry",
                      LastName: "Maskell",
                      Type: "Lineman#2",
                      uID: "o46874"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Kidd",
                      Type: "Fourth official",
                      uID: "o46875"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Lamex Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Stevenage", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p122230",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p96998",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t288"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p241279",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t22"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:51:33+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258644"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-16 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t89",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "3"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Pollard",
                      Type: "Referee",
                      uID: "o43617"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Chard",
                      Type: "Lineman#1",
                      uID: "o55825"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Damith",
                      LastName: "Bandara",
                      Type: "Lineman#2",
                      uID: "o53827"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gregory",
                      LastName: "Read",
                      Type: "Fourth official",
                      uID: "o49099"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Adams Park", "@attributes": { Type: "Venue" } },
                { "@value": "Wycombe", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t112"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p80281",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t89"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-11-17T13:54:32+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258643"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-17 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t48",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "1",
                  MatchType: "Replay",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "64"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Samuel",
                      LastName: "Barrott",
                      Type: "Referee",
                      uID: "o49219"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Graham",
                      Type: "Lineman#1",
                      uID: "o42738"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Atkin",
                      Type: "Lineman#2",
                      uID: "o43323"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Oldham",
                      Type: "Fourth official",
                      uID: "o44065"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Edgeley Park", "@attributes": { Type: "Venue" } },
                { "@value": "Stockport", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p149327",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p76013",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p88044",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p149327",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p460025",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "2",
                    HalfScore: "2",
                    NinetyScore: "3",
                    Score: "5",
                    Side: "Home",
                    TeamRef: "t48"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p88044",
                        Type: "Own"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p176019",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p87428",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "3",
                    NinetyScore: "3",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t30"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-11-23T09:19:17+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258670"
              }
            },
            {
              MatchInfo: {
                Date: "2021-11-30 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "1",
                  MatchType: "Replay",
                  MatchWinner: "t71",
                  Period: "FullTime",
                  RoundNumber: "1",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "22"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Charles",
                      LastName: "Breakspear",
                      Type: "Referee",
                      uID: "o42352"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Derrien",
                      Type: "Lineman#1",
                      uID: "o43364"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alex",
                      LastName: "James",
                      Type: "Lineman#2",
                      uID: "o52674"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Jackson",
                      Type: "Fourth official",
                      uID: "o50230"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "St James Park", "@attributes": { Type: "Venue" } },
                { "@value": "Exeter", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p133741",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p73456",
                        Type: "Own"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t71"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p119230",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t55"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-06T12:23:06+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258641"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-03 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t33",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "270"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Reeves",
                      Type: "Referee",
                      uID: "o48725"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Johnathon",
                      LastName: "Bickerdike",
                      Type: "Lineman#1",
                      uID: "o60149"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Avent",
                      Type: "Lineman#2",
                      uID: "o43471"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Seb",
                      LastName: "Stockbridge",
                      Type: "Fourth official",
                      uID: "o42105"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Gateshead International Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Gateshead", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t447"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p78154",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78154",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t33"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-15T13:03:04+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258706"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-03 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t72",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "8148"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Yates",
                      Type: "Referee",
                      uID: "o43584"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Plane",
                      Type: "Lineman#1",
                      uID: "o43536"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Bradley",
                      LastName: "Hall",
                      Type: "Lineman#2",
                      uID: "o49424"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Smith",
                      Type: "Fourth official",
                      uID: "o49279"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "AESSEAL New York Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Rotherham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p82650",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t72"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t48"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-04T10:22:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258705"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t2623",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "15235"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Young",
                      Type: "Referee",
                      uID: "o43475"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Begley",
                      Type: "Lineman#1",
                      uID: "o53344"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Louis",
                      LastName: "Marks",
                      Type: "Lineman#2",
                      uID: "o46876"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Leigh",
                      LastName: "Crowhurst",
                      Type: "Fourth official",
                      uID: "o52126"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Cherry Red Records Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p488592",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p90938",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p90938",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p488592",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t2623"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p178161",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p114090",
                        Type: "Own"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p38187",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t87"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T14:46:38+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258700"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t96",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "94"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Drysdale",
                      Type: "Referee",
                      uID: "o40946"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Declan",
                      LastName: "O'Shea",
                      Type: "Lineman#1",
                      uID: "o55102"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Wade",
                      Type: "Lineman#2",
                      uID: "o44468"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Shaw",
                      Type: "Fourth official",
                      uID: "o49143"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Memorial Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Bristol", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p182038",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p197394",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t96"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p180923",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t296"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T15:00:06+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258696"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t50",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2877"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Coy",
                      Type: "Referee",
                      uID: "o43570"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Smedley",
                      Type: "Lineman#1",
                      uID: "o46139"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dumitru-Ravel",
                      LastName: "Cheosiaua",
                      Type: "Lineman#2",
                      uID: "o44331"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Massey-Ellis",
                      Type: "Fourth official",
                      uID: "o41915"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Pirelli Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Burton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p182744",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t587"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p242873",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p242873",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t50"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T15:29:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258697"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 12:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t286",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "4307"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Swabey",
                      Type: "Referee",
                      uID: "o42351"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Parry",
                      Type: "Lineman#1",
                      uID: "o43353"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Conor",
                      LastName: "Brown",
                      Type: "Lineman#2",
                      uID: "o48636"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Speedie",
                      Type: "Fourth official",
                      uID: "o48271"
                    }
                  }
                ]
              },
              Stat: {
                "@value": "The Silverlands",
                "@attributes": { Type: "Venue" }
              },
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t3145"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p109674",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t286"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-04T15:05:18+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258698"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t101",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "99"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Rock",
                      Type: "Referee",
                      uID: "o43493"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Yates",
                      Type: "Lineman#1",
                      uID: "o46878"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Ward",
                      Type: "Lineman#2",
                      uID: "o43907"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Fourth official",
                      uID: "o43328"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Abbey Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Cambridge", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p208969",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p215069",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t101"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p232271",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t71"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T15:35:38+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258996"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t74",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "19"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Samuel",
                      LastName: "Barrott",
                      Type: "Referee",
                      uID: "o49219"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Freeman",
                      Type: "Lineman#1",
                      uID: "o52068"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Newhouse",
                      Type: "Lineman#2",
                      uID: "o46457"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bancroft",
                      Type: "Fourth official",
                      uID: "o52172"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Brunton Park", "@attributes": { Type: "Venue" } },
                { "@value": "Carlisle", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p220355",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t68"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p515846",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78608",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t74"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T15:45:12+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258709"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t77",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "3314"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Seb",
                      LastName: "Stockbridge",
                      Type: "Referee",
                      uID: "o42105"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Williams",
                      Type: "Lineman#1",
                      uID: "o57108"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Chris",
                      LastName: "Isherwood",
                      Type: "Lineman#2",
                      uID: "o46138"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Atkin",
                      Type: "Fourth official",
                      uID: "o43323"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Eco-Power Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Doncaster", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p440001",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p40548",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t290"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200619",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p232606",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200619",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t77"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T16:01:45+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258703"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "56"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sam",
                      LastName: "Purkiss",
                      Type: "Referee",
                      uID: "o43423"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Wilson",
                      Type: "Lineman#1",
                      uID: "o46101"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Conor",
                      LastName: "Farrell",
                      Type: "Lineman#2",
                      uID: "o50264"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Butler",
                      Type: "Fourth official",
                      uID: "o43484"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Portman Road", "@attributes": { Type: "Venue" } },
                { "@value": "Ipswich", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t40"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t573"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-04T17:03:58+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258711"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t85",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2737"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Oldham",
                      Type: "Referee",
                      uID: "o49365"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Garry",
                      LastName: "Maskell",
                      Type: "Lineman#1",
                      uID: "o46874"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ryan",
                      LastName: "Whitaker",
                      Type: "Lineman#2",
                      uID: "o52991"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joseph",
                      LastName: "Stokes",
                      Type: "Fourth official",
                      uID: "o53378"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Brisbane Road", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p78612",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p232295",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p232295",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p428683",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t85"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t44"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-05T22:23:25+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2258702"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t89",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "81"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Madley",
                      Type: "Referee",
                      uID: "o41412"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Grant",
                      LastName: "Taylor",
                      Type: "Lineman#1",
                      uID: "o43415"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Oliver",
                      LastName: "Bickle",
                      Type: "Lineman#2",
                      uID: "o46868"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Jones",
                      Type: "Fourth official",
                      uID: "o43351"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "LNER Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Lincoln", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t83"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p242512",
                      Type: "Own"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t89"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T14:30:42+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258699"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t671",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "63"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Mather",
                      Type: "Referee",
                      uID: "o43592"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Justin",
                      LastName: "Amey",
                      Type: "Lineman#1",
                      uID: "o42102"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Bennett",
                      Type: "Lineman#2",
                      uID: "o42742"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Hughes",
                      Type: "Fourth official",
                      uID: "o54836"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Fratton Park", "@attributes": { Type: "Venue" } },
                { "@value": "Portsmouth", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p96607",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t47"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p132210",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p222015",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t671"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T21:17:40+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258712"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t46",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "58"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Wright",
                      Type: "Referee",
                      uID: "o43487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Clayton",
                      Type: "Lineman#1",
                      uID: "o43312"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Danny",
                      LastName: "Gratton",
                      Type: "Lineman#2",
                      uID: "o43474"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Wilding",
                      Type: "Fourth official",
                      uID: "o49214"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Poundland Bescot Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Walsall", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p214926",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t42"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p476773",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p465390",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t46"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-05T21:24:45+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258704"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-04 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Cup",
                  MatchWinner: "t283",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2688"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Hicks",
                      Type: "Referee",
                      uID: "o43403"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Howick",
                      Type: "Lineman#1",
                      uID: "o48654"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alex",
                      LastName: "James",
                      Type: "Lineman#2",
                      uID: "o52674"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Howard",
                      Type: "Fourth official",
                      uID: "o45995"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Huish Park", "@attributes": { Type: "Venue" } },
                { "@value": "Yeovil", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p173796",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t283"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t288"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-15T12:54:36+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258695"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-05 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t111",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "7934"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Brook",
                      Type: "Referee",
                      uID: "o43332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Geoff",
                      LastName: "Russell",
                      Type: "Lineman#1",
                      uID: "o41349"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Chard",
                      Type: "Lineman#2",
                      uID: "o55825"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Williams",
                      Type: "Fourth official",
                      uID: "o55040"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "JobServe Community Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Colchester", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p49997",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t99"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p222663",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p222663",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t111"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2021-12-06T15:43:55+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258701"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-05 16:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t285",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "111"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Brett",
                      LastName: "Huxtable",
                      Type: "Referee",
                      uID: "o42682"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Christopher",
                      LastName: "Kidd",
                      Type: "Lineman#1",
                      uID: "o46875"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Aylott",
                      Type: "Lineman#2",
                      uID: "o43414"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Williams",
                      Type: "Fourth official",
                      uID: "o48703"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Aggborough Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Kidderminster", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p50262",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p57126",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t285"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t69"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-15T12:51:17+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258708"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-05 12:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t76",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "80"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Kitchen",
                      Type: "Referee",
                      uID: "o53449"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Moore",
                      Type: "Lineman#1",
                      uID: "o45994"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Gooch",
                      Type: "Lineman#2",
                      uID: "o43422"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Fourth official",
                      uID: "o45993"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Crown Oil Arena", "@attributes": { Type: "Venue" } },
                { "@value": "Rochdale", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p231908",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t82"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p222840",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p442243",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t76"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-05T14:22:43+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258997"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-05 17:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t98",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4563"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ross",
                      LastName: "Joyce",
                      Type: "Referee",
                      uID: "o42163"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lewis",
                      LastName: "Raper",
                      Type: "Lineman#1",
                      uID: "o48854"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Joe",
                      LastName: "Clark",
                      Type: "Lineman#2",
                      uID: "o43655"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Wright",
                      Type: "Fourth official",
                      uID: "o43487"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Peninsula Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Salford", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t3448"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p176808",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p109639",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t98"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-06T10:54:43+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258710"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-06 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Regular",
                  MatchWinner: "t2479",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "1",
                  Venue_id: "4259"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Speedie",
                      Type: "Referee",
                      uID: "o48271"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Stevens",
                      Type: "Lineman#1",
                      uID: "o54845"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dean",
                      LastName: "Treleaven",
                      Type: "Lineman#2",
                      uID: "o42678"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Alan",
                      LastName: "Young",
                      Type: "Fourth official",
                      uID: "o43475"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Meadow Park", "@attributes": { Type: "Venue" } },
                { "@value": "Borehamwood", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p158872",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p162775",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p172452",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p158872",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t2479"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2246"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-06T21:53:57+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2258707"
              }
            },
            {
              MatchInfo: {
                Date: "2021-12-15 19:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "2",
                  MatchType: "Replay",
                  MatchWinner: "t573",
                  Period: "FullTime",
                  RoundNumber: "2",
                  RoundType: "Round",
                  TimeFrameLengthId: "9",
                  Venue_id: "7802"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Referee",
                      uID: "o45993"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Newhouse",
                      Type: "Lineman#1",
                      uID: "o46457"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lewis",
                      LastName: "Raper",
                      Type: "Lineman#2",
                      uID: "o48854"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Coy",
                      Type: "Fourth official",
                      uID: "o43570"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "SO Legal Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Barrow-in-Furness", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p434138",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p436760",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t573"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t40"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2021-12-15T21:51:37+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260769"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-07 20:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t43",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "62"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Betts",
                      Type: "Lineman#1",
                      uID: "o43274"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Harry",
                      LastName: "Lennard",
                      Type: "Lineman#2",
                      uID: "o43273"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Fourth official",
                      uID: "o41028"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The County Ground", "@attributes": { Type: "Venue" } },
                { "@value": "Swindon", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p173927",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t46"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p165809",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p205651",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p244851",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p59859",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t43"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-17T10:54:50+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260833"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t37",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "53"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ross",
                      LastName: "Joyce",
                      Type: "Referee",
                      uID: "o42163"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Hristo",
                      LastName: "Karaivanov",
                      Type: "Lineman#1",
                      uID: "o49220"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dumitru-Ravel",
                      LastName: "Cheosiaua",
                      Type: "Lineman#2",
                      uID: "o44331"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Hair",
                      Type: "Fourth official",
                      uID: "o43495"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Oakwell", "@attributes": { Type: "Venue" } },
                { "@value": "Barnsley", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p215974",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p208904",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p104532",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p156700",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p156700",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "2",
                    NinetyScore: "4",
                    Score: "5",
                    Side: "Home",
                    TeamRef: "t37"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p195471",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p220258",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p208759",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p87111",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "4",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t573"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-08T17:42:54+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260840"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t76",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "57"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Rebecca",
                      LastName: "Welch",
                      Type: "Referee",
                      uID: "o47827"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Williams",
                      Type: "Lineman#1",
                      uID: "o55040"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Aylott",
                      Type: "Lineman#2",
                      uID: "o43414"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ollie",
                      LastName: "Yates",
                      Type: "Fourth official",
                      uID: "o43584"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "St. Andrew's Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Birmingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t41"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "ExtraFirstHalf",
                      PlayerRef: "p442237",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t76"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T09:06:05+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260836"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t2479",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "4259"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Bell",
                      Type: "Referee",
                      uID: "o45993"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ryan",
                      LastName: "Whitaker",
                      Type: "Lineman#1",
                      uID: "o52991"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Conor",
                      LastName: "Farrell",
                      Type: "Lineman#2",
                      uID: "o50264"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sam",
                      LastName: "Purkiss",
                      Type: "Fourth official",
                      uID: "o43423"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Meadow Park", "@attributes": { Type: "Venue" } },
                { "@value": "Borehamwood", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p96747",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p162775",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t2479"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2623"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-09T11:40:30+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260814"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t54",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "4"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jonathan",
                      LastName: "Moss",
                      Type: "Referee",
                      uID: "o40936"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adam",
                      LastName: "Crysell",
                      Type: "Lineman#1",
                      uID: "o43427"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graham",
                      LastName: "Kane",
                      Type: "Lineman#2",
                      uID: "o43840"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Lewis",
                      Type: "Fourth official",
                      uID: "o42106"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Ashton Gate", "@attributes": { Type: "Venue" } },
                { "@value": "Bristol", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t113"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "ExtraFirstHalf",
                      PlayerRef: "p153682",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t54"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T07:58:54+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260829"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t38",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "88"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andre",
                      LastName: "Marriner",
                      Type: "Referee",
                      uID: "o19485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "West",
                      Type: "Lineman#1",
                      uID: "o42098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Hodskinson",
                      Type: "Lineman#2",
                      uID: "o43528"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steve",
                      LastName: "Martin",
                      Type: "Fourth official",
                      uID: "o41489"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Video Assistant Referee",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Assistant VAR Official",
                      uID: "o43358"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Turf Moor", "@attributes": { Type: "Venue" } },
                { "@value": "Burnley", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p44683",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t90"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p110698",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p225257",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t38"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T03:30:32+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260822"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t8",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "35"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jarred",
                      LastName: "Gillett",
                      Type: "Referee",
                      uID: "o42752"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Wade",
                      LastName: "Smith",
                      Type: "Lineman#1",
                      uID: "o48726"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Jones",
                      Type: "Lineman#2",
                      uID: "o43913"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Josh",
                      LastName: "Smith",
                      Type: "Fourth official",
                      uID: "o46098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Video Assistant Referee",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Natalie",
                      LastName: "Aspinall",
                      Type: "Assistant VAR Official",
                      uID: "o41695"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Stamford Bridge", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p135363",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p165153",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p209046",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p66749",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p124183",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "4",
                    Score: "5",
                    Side: "Home",
                    TeamRef: "t8"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p90515",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t98"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-09T00:09:20+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "3",
                uID: "g2260818"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t9",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2880"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Referee",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Wilkes",
                      Type: "Lineman#1",
                      uID: "o43278"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Taylor",
                      Type: "Lineman#2",
                      uID: "o45992"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Speedie",
                      Type: "Fourth official",
                      uID: "o48271"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Coventry Building Society Arena",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Coventry", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p154128",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t9"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t24"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T14:04:32+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260821"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t89",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "87"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Donohue",
                      Type: "Referee",
                      uID: "o44348"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Philip",
                      LastName: "Dermott",
                      Type: "Lineman#1",
                      uID: "o43416"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Wilson",
                      Type: "Lineman#2",
                      uID: "o43522"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Kitchen",
                      Type: "Fourth official",
                      uID: "o53449"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "The Suit Direct Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Hartlepool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p111352",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p499072",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t89"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p204216",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t92"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-13T08:44:14+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260827"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t11",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2638"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Referee",
                      uID: "o40928"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Lineman#1",
                      uID: "o43313"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adrian",
                      LastName: "Holmes",
                      Type: "Lineman#2",
                      uID: "o43281"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Webb",
                      Type: "Fourth official",
                      uID: "o41050"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The MKM Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Hull", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p225991",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p232774",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t88"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p172632",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p120250",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p60252",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "2",
                    NinetyScore: "2",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t11"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-09T11:18:10+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260828"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t285",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "111"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gavin",
                      LastName: "Ward",
                      Type: "Referee",
                      uID: "o40968"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "McGrath",
                      Type: "Lineman#1",
                      uID: "o43494"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Hyde",
                      Type: "Lineman#2",
                      uID: "o43533"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Mather",
                      Type: "Fourth official",
                      uID: "o43592"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Aggborough Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Kidderminster", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p50262",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p195143",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t285"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p174654",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t108"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T14:26:53+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260824"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t13",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2516"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mike",
                      LastName: "Dean",
                      Type: "Referee",
                      uID: "o10508"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Hussin",
                      Type: "Lineman#1",
                      uID: "o43464"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Derek",
                      LastName: "Eaton",
                      Type: "Lineman#2",
                      uID: "o43561"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Linington",
                      Type: "Fourth official",
                      uID: "o41053"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Attwell",
                      Type: "Video Assistant Referee",
                      uID: "o40959"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Perry",
                      Type: "Assistant VAR Official",
                      uID: "o43634"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "King Power Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Leicester", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p172780",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p166989",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p201666",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p51938",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t13"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p475168",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t57"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-09T16:25:10+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260825"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t25",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "75"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Keith",
                      LastName: "Stroud",
                      Type: "Referee",
                      uID: "o32005"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Dallison",
                      Type: "Lineman#1",
                      uID: "o49284"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "George",
                      Type: "Lineman#2",
                      uID: "o43581"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Backhouse",
                      Type: "Fourth official",
                      uID: "o42686"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "One Call Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Mansfield", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p193161",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p113804",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t77"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p225277",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p165203",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p97311",
                        Type: "Own"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t25"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T13:53:32+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260826"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 12:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t31",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "101"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Taylor",
                      Type: "Referee",
                      uID: "o40933"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gary",
                      LastName: "Beswick",
                      Type: "Lineman#1",
                      uID: "o43269"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adam",
                      LastName: "Nunn",
                      Type: "Lineman#2",
                      uID: "o43329"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Fourth official",
                      uID: "o41180"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The Den", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p88498",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t103"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p443661",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p231747",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t31"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-13T15:55:59+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260831"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t101",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "31"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Salisbury",
                      Type: "Referee",
                      uID: "o42732"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Scholes",
                      Type: "Lineman#1",
                      uID: "o43277"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Geoffrey",
                      LastName: "Liddle",
                      Type: "Lineman#2",
                      uID: "o43392"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Busby",
                      Type: "Fourth official",
                      uID: "o43481"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Bankes",
                      Type: "Video Assistant Referee",
                      uID: "o43279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Burt",
                      Type: "Assistant VAR Official",
                      uID: "o43257"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "St. James' Park", "@attributes": { Type: "Venue" } },
                { "@value": "Newcastle", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t4"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p123709",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t101"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T01:28:10+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260839"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t73",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "24"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Davies",
                      Type: "Referee",
                      uID: "o41483"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Bhupinder",
                      LastName: "Gill",
                      Type: "Lineman#1",
                      uID: "o50076"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Laver",
                      Type: "Lineman#2",
                      uID: "o43402"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Chris",
                      LastName: "Sarginson",
                      Type: "Fourth official",
                      uID: "o41056"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Weston Homes Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Peterborough", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p172453",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p220686",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t73"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p54769",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t96"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T14:34:12+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260841"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t94",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "66"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Thomas",
                      LastName: "Bramall",
                      Type: "Referee",
                      uID: "o43906"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adrian",
                      LastName: "Waters",
                      Type: "Lineman#1",
                      uID: "o45996"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Dwyer",
                      Type: "Lineman#2",
                      uID: "o43583"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Edwards",
                      Type: "Fourth official",
                      uID: "o42747"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Vale Park", "@attributes": { Type: "Venue" } },
                { "@value": "Stoke-on-Trent", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p461505",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t50"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p216620",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p446008",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p446008",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p446008",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t94"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T08:02:32+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260832"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t52",
                  GameWinnerType: "ShootOut",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "68"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dean",
                      LastName: "Whitestone",
                      Type: "Referee",
                      uID: "o40952"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Justin",
                      LastName: "Amey",
                      Type: "Lineman#1",
                      uID: "o42102"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Cooper",
                      Type: "Lineman#2",
                      uID: "o43577"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Carl",
                      LastName: "Brook",
                      Type: "Fourth official",
                      uID: "o43332"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Loftus Road", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p194213",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p169734",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p73426",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p218023",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p194213",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p49773",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p168764",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p176442",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p61916",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "0",
                    PenaltyScore: "8",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t52"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p115560",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p217583",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p82650",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p115560",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p183654",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p433168",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p184836",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p183424",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "0",
                    PenaltyScore: "7",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t72"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T21:45:13+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260843"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  AdditionalInfo: "Behind Closed Doors",
                  GameWinner: "t20",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2867"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Hooper",
                      Type: "Referee",
                      uID: "o41055"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andrew",
                      LastName: "Fox",
                      Type: "Lineman#1",
                      uID: "o42734"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "Wild",
                      Type: "Lineman#2",
                      uID: "o44350"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tom",
                      LastName: "Reeves",
                      Type: "Fourth official",
                      uID: "o48725"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Swansea.com Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Swansea", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p235826",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p171771",
                        Type: "Own"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t80"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p83283",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p20452",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p96787",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "2",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t20"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T01:10:09+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260817"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t36",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "51"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Referee",
                      uID: "o43421"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Cann",
                      Type: "Lineman#1",
                      uID: "o40997"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Meredith",
                      Type: "Lineman#2",
                      uID: "o43360"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Toner",
                      Type: "Fourth official",
                      uID: "o42673"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The Hawthorns", "@attributes": { Type: "Venue" } },
                { "@value": "West Bromwich", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p171975",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t35"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p243505",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p115382",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t36"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T21:49:34+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260823"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t111",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tim",
                      LastName: "Robinson",
                      Type: "Referee",
                      uID: "o41917"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nigel",
                      LastName: "Lugg",
                      Type: "Lineman#1",
                      uID: "o43490"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Merchant",
                      Type: "Lineman#2",
                      uID: "o42099"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Coy",
                      Type: "Fourth official",
                      uID: "o43570"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "DW Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Wigan", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p180801",
                        Type: "Own"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p449415",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p86590",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t111"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p465572",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p75880",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t5"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-09T14:54:56+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260834"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-08 17:45:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t91",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2688"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jeremy",
                      LastName: "Simpson",
                      Type: "Referee",
                      uID: "o42103"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Rob",
                      LastName: "Smith",
                      Type: "Lineman#1",
                      uID: "o43915"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Russell",
                      Type: "Lineman#2",
                      uID: "o43530"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Swabey",
                      Type: "Fourth official",
                      uID: "o42351"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Huish Park", "@attributes": { Type: "Venue" } },
                { "@value": "Yeovil", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p200372",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t283"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p133845",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p133845",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p133845",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t91"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-08T19:37:54+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260815"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  AdditionalInfo: "Behind Closed Doors",
                  GameWinner: "t97",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "3",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "5040"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Woolmer",
                      Type: "Referee",
                      uID: "o40939"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Venamore",
                      Type: "Lineman#1",
                      uID: "o43428"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Pottage",
                      Type: "Lineman#2",
                      uID: "o42740"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sam",
                      LastName: "Allison",
                      Type: "Fourth official",
                      uID: "o46099"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Cardiff City Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Cardiff", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p461132",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p222256",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t97"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p90590",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t107"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-10T16:44:44+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260820"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t45",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "49"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Josh",
                      LastName: "Smith",
                      Type: "Referee",
                      uID: "o46098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jonathan",
                      LastName: "Hunt",
                      Type: "Lineman#1",
                      uID: "o43486"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Barnard",
                      Type: "Lineman#2",
                      uID: "o43919"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Drysdale",
                      Type: "Fourth official",
                      uID: "o40946"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The Valley", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t33"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p212723",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t45"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-17T12:54:21+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260844"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t14",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "39"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Referee",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Hopton",
                      Type: "Lineman#1",
                      uID: "o44332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Shaun",
                      LastName: "Hudson",
                      Type: "Lineman#2",
                      uID: "o41919"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Geoff",
                      LastName: "Eltringham",
                      Type: "Fourth official",
                      uID: "o41175"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tony",
                      LastName: "Harrington",
                      Type: "Video Assistant Referee",
                      uID: "o42094"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sian",
                      LastName: "Massey-Ellis",
                      Type: "Assistant VAR Official",
                      uID: "o41912"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Anfield", "@attributes": { Type: "Venue" } },
                { "@value": "Liverpool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p116643",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p496185",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p92217",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p116643",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t14"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p194265",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t74"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T13:29:15+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260819"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t102",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "100"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Linington",
                      Type: "Referee",
                      uID: "o41053"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Leach",
                      Type: "Lineman#1",
                      uID: "o43586"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Webb",
                      Type: "Lineman#2",
                      uID: "o43311"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Trevor",
                      LastName: "Kettle",
                      Type: "Fourth official",
                      uID: "o40918"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Kenilworth Road", "@attributes": { Type: "Venue" } },
                { "@value": "Luton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p174310",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p82738",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p18804",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p88611",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t102"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t671"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-10T16:41:07+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260835"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 17:10:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t17",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "40"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Pawson",
                      Type: "Referee",
                      uID: "o41051"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Lineman#1",
                      uID: "o43328"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Mainwaring",
                      Type: "Lineman#2",
                      uID: "o43539"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Fourth official",
                      uID: "o40928"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The City Ground", "@attributes": { Type: "Venue" } },
                { "@value": "Nottingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p21246",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t17"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t3"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T13:34:51+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260845"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t110",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "1"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Samuel",
                      LastName: "Barrott",
                      Type: "Referee",
                      uID: "o49219"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matthew",
                      LastName: "Smith",
                      Type: "Lineman#1",
                      uID: "o49279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lisa",
                      LastName: "Rashid",
                      Type: "Lineman#2",
                      uID: "o41918"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Wright",
                      Type: "Fourth official",
                      uID: "o43487"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "bet365 Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Stoke", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p86176",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200884",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t110"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t85"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-01-10T16:51:08+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260816"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t6",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "13213"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Referee",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Perry",
                      Type: "Lineman#1",
                      uID: "o43634"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Natalie",
                      LastName: "Aspinall",
                      Type: "Lineman#2",
                      uID: "o41695"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gavin",
                      LastName: "Ward",
                      Type: "Fourth official",
                      uID: "o40968"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Salisbury",
                      Type: "Video Assistant Referee",
                      uID: "o42732"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Timothy",
                      LastName: "Wood",
                      Type: "Assistant VAR Official",
                      uID: "o43485"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Tottenham Hotspur Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p157668",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78830",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p95715",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t6"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p125144",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t286"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T10:26:25+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260830"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t21",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "11249"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Attwell",
                      Type: "Referee",
                      uID: "o40959"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Ledger",
                      Type: "Lineman#1",
                      uID: "o43256"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Lineman#2",
                      uID: "o43358"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jarred",
                      LastName: "Gillett",
                      Type: "Fourth official",
                      uID: "o42752"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Bankes",
                      Type: "Video Assistant Referee",
                      uID: "o43279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Betts",
                      Type: "Assistant VAR Official",
                      uID: "o43274"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "London Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p86934",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p178186",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t21"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T12:46:17+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2260842"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-09 14:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t39",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "55"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Referee",
                      uID: "o41180"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Kirkup",
                      Type: "Lineman#1",
                      uID: "o43263"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Akil",
                      LastName: "Howson",
                      Type: "Lineman#2",
                      uID: "o43905"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dean",
                      LastName: "Whitestone",
                      Type: "Fourth official",
                      uID: "o40952"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Video Assistant Referee",
                      uID: "o43421"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Scholes",
                      Type: "Assistant VAR Official",
                      uID: "o43277"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Molineux Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Wolverhampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p200600",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200402",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p200600",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t39"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t49"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-10T16:16:14+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "4",
                uID: "g2260838"
              }
            },
            {
              MatchInfo: {
                Date: "2022-01-10 19:55:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "3",
                  MatchType: "Cup",
                  MatchWinner: "t1",
                  Period: "FullTime",
                  RoundNumber: "3",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "28"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Oliver",
                      Type: "Referee",
                      uID: "o40962"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Burt",
                      Type: "Lineman#1",
                      uID: "o43257"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Bennett",
                      Type: "Lineman#2",
                      uID: "o43280"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Fourth official",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Video Assistant Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Constantine",
                      LastName: "Hatzidakis",
                      Type: "Assistant VAR Official",
                      uID: "o43523"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Old Trafford", "@attributes": { Type: "Venue" } },
                { "@value": "Manchester", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p195851",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t1"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t7"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-01-11T14:58:37+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "3",
                uID: "g2260837"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-04 20:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t25",
                  GameWinnerType: "ShootOut",
                  MatchDay: "4",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "28"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Taylor",
                      Type: "Referee",
                      uID: "o40933"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gary",
                      LastName: "Beswick",
                      Type: "Lineman#1",
                      uID: "o43269"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Cann",
                      Type: "Lineman#2",
                      uID: "o40997"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Atkinson",
                      Type: "Fourth official",
                      uID: "o18757"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Attwell",
                      Type: "Video Assistant Referee",
                      uID: "o40959"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Assistant VAR Official",
                      uID: "o43358"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Old Trafford", "@attributes": { Type: "Venue" } },
                { "@value": "Manchester", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p209243",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p14937",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p141746",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p216051",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p195851",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p101582",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p95658",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p43670",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "1",
                    PenaltyScore: "7",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t1"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p101282",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p19523",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p160817",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p38716",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p201658",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p38297",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p115854",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p433779",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p169061",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "1",
                    PenaltyScore: "8",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t25"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-02T16:10:10+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268107"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 17:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t102",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "99"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Josh",
                      LastName: "Smith",
                      Type: "Referee",
                      uID: "o46098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graham",
                      LastName: "Kane",
                      Type: "Lineman#1",
                      uID: "o43840"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Taylor",
                      Type: "Lineman#2",
                      uID: "o45992"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sam",
                      LastName: "Purkiss",
                      Type: "Fourth official",
                      uID: "o43423"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Abbey Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Cambridge", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t101"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p156658",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p453537",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p174595",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t102"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-02-06T14:52:39+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268102"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t8",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "4",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "35"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Hooper",
                      Type: "Referee",
                      uID: "o41055"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Lineman#1",
                      uID: "o43358"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Derek",
                      LastName: "Eaton",
                      Type: "Lineman#2",
                      uID: "o43561"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Davies",
                      Type: "Fourth official",
                      uID: "o41483"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mike",
                      LastName: "Dean",
                      Type: "Video Assistant Referee",
                      uID: "o10508"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Assistant VAR Official",
                      uID: "o43313"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Stamford Bridge", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p41328",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraFirstHalf",
                        PlayerRef: "p82263",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t8"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p155508",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t76"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T11:32:26+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268104"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t31",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "48"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Bankes",
                      Type: "Referee",
                      uID: "o43279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Mainwaring",
                      Type: "Lineman#1",
                      uID: "o43539"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Steven",
                      LastName: "Meredith",
                      Type: "Lineman#2",
                      uID: "o43360"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gavin",
                      LastName: "Ward",
                      Type: "Fourth official",
                      uID: "o40968"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Video Assistant Referee",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Natalie",
                      LastName: "Aspinall",
                      Type: "Assistant VAR Official",
                      uID: "o41695"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Selhurst Park", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p443661",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p209036",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t31"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t89"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T12:30:14+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268098"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t11",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "37"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Oliver",
                      Type: "Referee",
                      uID: "o40962"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Burt",
                      Type: "Lineman#1",
                      uID: "o43257"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Ledger",
                      Type: "Lineman#2",
                      uID: "o43256"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Fourth official",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andre",
                      LastName: "Marriner",
                      Type: "Video Assistant Referee",
                      uID: "o19485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Harry",
                      LastName: "Lennard",
                      Type: "Assistant VAR Official",
                      uID: "o43273"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Goodison Park", "@attributes": { Type: "Venue" } },
                { "@value": "Liverpool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p164511",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p60252",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p212319",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p194164",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t11"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p144485",
                      Type: "Penalty"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t94"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T12:43:59+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268105"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t38",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "54"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jeremy",
                      LastName: "Simpson",
                      Type: "Referee",
                      uID: "o42103"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Shaun",
                      LastName: "Hudson",
                      Type: "Lineman#1",
                      uID: "o41919"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Leach",
                      Type: "Lineman#2",
                      uID: "o43586"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Coy",
                      Type: "Fourth official",
                      uID: "o43570"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "John Smith's Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Huddersfield", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p152893",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t38"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t37"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-02-06T14:47:11+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268100"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t21",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "4",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "111"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jonathan",
                      LastName: "Moss",
                      Type: "Referee",
                      uID: "o40936"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Perry",
                      Type: "Lineman#1",
                      uID: "o43634"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Timothy",
                      LastName: "Wood",
                      Type: "Lineman#2",
                      uID: "o43485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Leigh",
                      LastName: "Doughty",
                      Type: "Fourth official",
                      uID: "o49216"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Aggborough Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Kidderminster", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p156517",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t285"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p204480",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p178186",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t21"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T14:22:02+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268106"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t43",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2691"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jarred",
                      LastName: "Gillett",
                      Type: "Referee",
                      uID: "o42752"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Betts",
                      Type: "Lineman#1",
                      uID: "o43274"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "West",
                      Type: "Lineman#2",
                      uID: "o42098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Atkinson",
                      Type: "Fourth official",
                      uID: "o18757"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Mason",
                      Type: "Video Assistant Referee",
                      uID: "o28689"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Cann",
                      Type: "Assistant VAR Official",
                      uID: "o40997"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Etihad Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Manchester", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p97299",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p59859",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p103025",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p103025",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t43"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p244858",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t54"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T14:11:06+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268112"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t73",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "24"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Webb",
                      Type: "Referee",
                      uID: "o41050"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adam",
                      LastName: "Crysell",
                      Type: "Lineman#1",
                      uID: "o43427"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Hodskinson",
                      Type: "Lineman#2",
                      uID: "o43528"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ben",
                      LastName: "Speedie",
                      Type: "Fourth official",
                      uID: "o48271"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Weston Homes Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Peterborough", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p160584",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p492072",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t73"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t52"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-10T12:16:02+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268101"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t20",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "4",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2370"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tony",
                      LastName: "Harrington",
                      Type: "Referee",
                      uID: "o42094"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Beck",
                      Type: "Lineman#1",
                      uID: "o43258"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Wilkes",
                      Type: "Lineman#2",
                      uID: "o43278"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Donohue",
                      Type: "Fourth official",
                      uID: "o44348"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Video Assistant Referee",
                      uID: "o40928"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Scholes",
                      Type: "Assistant VAR Official",
                      uID: "o43277"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "St. Mary's Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Southampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p91047",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ExtraSecondHalf",
                        PlayerRef: "p158534",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t20"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p224117",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "1",
                    NinetyScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t9"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-05T18:10:40+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268103"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t110",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "1"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Geoff",
                      LastName: "Eltringham",
                      Type: "Referee",
                      uID: "o41175"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nick",
                      LastName: "Greenhalgh",
                      Type: "Lineman#1",
                      uID: "o43316"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Hyde",
                      Type: "Lineman#2",
                      uID: "o43533"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Backhouse",
                      Type: "Fourth official",
                      uID: "o42686"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "bet365 Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Stoke", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p195480",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p242453",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t110"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t111"
                  }
                }
              ],
              "@attributes": {
                detail_id: "3",
                last_modified: "2022-02-05T19:42:43+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268110"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 20:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t6",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "13213"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Attwell",
                      Type: "Referee",
                      uID: "o40959"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Lineman#1",
                      uID: "o43328"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dan",
                      LastName: "Robathan",
                      Type: "Lineman#2",
                      uID: "o43345"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Fourth official",
                      uID: "o43421"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Video Assistant Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sian",
                      LastName: "Massey-Ellis",
                      Type: "Assistant VAR Official",
                      uID: "o41912"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Tottenham Hotspur Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p109345",
                        Type: "Own"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p78830",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p78830",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t6"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p227127",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t36"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T12:25:28+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268108"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-05 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t45",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "55"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Referee",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Hopton",
                      Type: "Lineman#1",
                      uID: "o44332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Wade",
                      LastName: "Smith",
                      Type: "Lineman#2",
                      uID: "o48726"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Salisbury",
                      Type: "Fourth official",
                      uID: "o42732"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Video Assistant Referee",
                      uID: "o41180"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Constantine",
                      LastName: "Hatzidakis",
                      Type: "Assistant VAR Official",
                      uID: "o43523"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Molineux Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Wolverhampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t39"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p78607",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t45"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-06T14:08:26+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268113"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-06 18:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t2479",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2734"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graham",
                      LastName: "Scott",
                      Type: "Referee",
                      uID: "o41049"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Harry",
                      LastName: "Lennard",
                      Type: "Lineman#1",
                      uID: "o43273"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Scholes",
                      Type: "Lineman#2",
                      uID: "o43277"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Josh",
                      LastName: "Smith",
                      Type: "Fourth official",
                      uID: "o46098"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Vitality Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Bournemouth", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t91"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p27709",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t2479"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-07T15:41:18+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268099"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-06 12:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t14",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "39"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Referee",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adrian",
                      LastName: "Holmes",
                      Type: "Lineman#1",
                      uID: "o43281"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Lineman#2",
                      uID: "o43313"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mike",
                      LastName: "Dean",
                      Type: "Fourth official",
                      uID: "o10508"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Video Assistant Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Timothy",
                      LastName: "Wood",
                      Type: "Assistant VAR Official",
                      uID: "o43485"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Anfield", "@attributes": { Type: "Venue" } },
                { "@value": "Liverpool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p157882",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p444884",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p194634",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t14"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p461128",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t97"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-07T12:36:05+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268109"
              }
            },
            {
              MatchInfo: {
                Date: "2022-02-06 16:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "4",
                  MatchType: "Cup",
                  MatchWinner: "t17",
                  Period: "FullTime",
                  RoundNumber: "4",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "40"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Referee",
                      uID: "o41180"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Hussin",
                      Type: "Lineman#1",
                      uID: "o43464"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Constantine",
                      LastName: "Hatzidakis",
                      Type: "Lineman#2",
                      uID: "o43523"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Fourth official",
                      uID: "o43421"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The City Ground", "@attributes": { Type: "Venue" } },
                { "@value": "Nottingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p242898",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p208912",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p162344",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p232859",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "3",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t17"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p173515",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t13"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-02-07T13:27:09+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2268111"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-01 19:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t31",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "48"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Referee",
                      uID: "o43421"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Ian",
                      LastName: "Hussin",
                      Type: "Lineman#1",
                      uID: "o43464"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Derek",
                      LastName: "Eaton",
                      Type: "Lineman#2",
                      uID: "o43561"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Leigh",
                      LastName: "Doughty",
                      Type: "Fourth official",
                      uID: "o49216"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Video Assistant Referee",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sian",
                      LastName: "Massey-Ellis",
                      Type: "Assistant VAR Official",
                      uID: "o41912"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Selhurst Park", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p173954",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p55037",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t31"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p221267",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t110"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-05-18T14:23:45+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277259"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-01 19:55:00",
                TZ: "GMT",
                "@attributes": {
                  GameWinner: "t25",
                  GameWinnerType: "AfterExtraTime",
                  MatchDay: "5",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "46"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Bennett",
                      Type: "Lineman#1",
                      uID: "o43280"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Wade",
                      LastName: "Smith",
                      Type: "Lineman#2",
                      uID: "o48726"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Pawson",
                      Type: "Fourth official",
                      uID: "o41051"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Riverside Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Middlesbrough", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "ExtraSecondHalf",
                      PlayerRef: "p474776",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    ExtraScore: "1",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t25"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t6"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-10T14:04:31+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277263"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-01 19:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t43",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "24"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Referee",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Lineman#1",
                      uID: "o43313"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Adrian",
                      LastName: "Holmes",
                      Type: "Lineman#2",
                      uID: "o43281"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Josh",
                      LastName: "Smith",
                      Type: "Fourth official",
                      uID: "o46098"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "Weston Homes Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Peterborough", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t73"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p103025",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p114283",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t43"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-10T10:32:01+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277260"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-02 20:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t14",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "39"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Martin",
                      LastName: "Atkinson",
                      Type: "Referee",
                      uID: "o18757"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Burt",
                      Type: "Lineman#1",
                      uID: "o43257"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Hopton",
                      Type: "Lineman#2",
                      uID: "o44332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mike",
                      LastName: "Dean",
                      Type: "Fourth official",
                      uID: "o10508"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Video Assistant Referee",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Marc",
                      LastName: "Perry",
                      Type: "Assistant VAR Official",
                      uID: "o43634"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Anfield", "@attributes": { Type: "Venue" } },
                { "@value": "Liverpool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p157882",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p157882",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t14"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p76306",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t45"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-10T12:08:35+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277261"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-02 19:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t8",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "100"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Peter",
                      LastName: "Bankes",
                      Type: "Referee",
                      uID: "o43279"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Lineman#1",
                      uID: "o43358"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Mainwaring",
                      Type: "Lineman#2",
                      uID: "o43539"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graham",
                      LastName: "Scott",
                      Type: "Fourth official",
                      uID: "o41049"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Kenilworth Road", "@attributes": { Type: "Venue" } },
                { "@value": "Luton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p156658",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p150707",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t102"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p89335",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p165153",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p66749",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t8"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-11T19:52:40+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277258"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-02 19:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t20",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "2370"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andre",
                      LastName: "Marriner",
                      Type: "Referee",
                      uID: "o19485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Scott",
                      LastName: "Ledger",
                      Type: "Lineman#1",
                      uID: "o43256"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Wilkes",
                      Type: "Lineman#2",
                      uID: "o43278"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Salisbury",
                      Type: "Fourth official",
                      uID: "o42732"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Hooper",
                      Type: "Video Assistant Referee",
                      uID: "o41055"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Timothy",
                      LastName: "Wood",
                      Type: "Assistant VAR Official",
                      uID: "o43485"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "St. Mary's Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Southampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p244560",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p440323",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p101178",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "3",
                    Side: "Home",
                    TeamRef: "t20"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p57531",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t21"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-10T12:06:29+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277262"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-03 20:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t11",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "37"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Tony",
                      LastName: "Harrington",
                      Type: "Referee",
                      uID: "o42094"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gary",
                      LastName: "Beswick",
                      Type: "Lineman#1",
                      uID: "o43269"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "Cann",
                      Type: "Lineman#2",
                      uID: "o40997"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Fourth official",
                      uID: "o40928"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Jonathan",
                      LastName: "Moss",
                      Type: "Video Assistant Referee",
                      uID: "o40936"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Natalie",
                      LastName: "Aspinall",
                      Type: "Assistant VAR Official",
                      uID: "o41695"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Goodison Park", "@attributes": { Type: "Venue" } },
                { "@value": "Liverpool", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p57134",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p57134",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t11"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t2479"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-11T19:56:34+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277265"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-07 19:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "5",
                  MatchType: "Cup",
                  MatchWinner: "t17",
                  Period: "FullTime",
                  RoundNumber: "5",
                  RoundType: "Round",
                  TimeFrameLengthId: "2",
                  Venue_id: "40"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Graham",
                      LastName: "Scott",
                      Type: "Referee",
                      uID: "o41049"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Neil",
                      LastName: "Davies",
                      Type: "Lineman#1",
                      uID: "o43358"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mark",
                      LastName: "Scholes",
                      Type: "Lineman#2",
                      uID: "o43277"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Webb",
                      Type: "Fourth official",
                      uID: "o41050"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The City Ground", "@attributes": { Type: "Venue" } },
                { "@value": "Nottingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p204968",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p217331",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t17"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p73545",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t38"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-14T08:49:38+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2277264"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-19 17:15:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "6",
                  MatchType: "Cup",
                  MatchWinner: "t8",
                  Period: "FullTime",
                  RoundNumber: "6",
                  RoundType: "Quarter-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "46"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Referee",
                      uID: "o41180"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Betts",
                      Type: "Lineman#1",
                      uID: "o43274"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "James",
                      LastName: "Mainwaring",
                      Type: "Lineman#2",
                      uID: "o43539"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Robert",
                      LastName: "Jones",
                      Type: "Fourth official",
                      uID: "o43421"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Video Assistant Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Wade",
                      LastName: "Smith",
                      Type: "Assistant VAR Official",
                      uID: "o48726"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Riverside Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "Middlesbrough", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t25"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p66749",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p124183",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "2",
                    Side: "Away",
                    TeamRef: "t8"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-28T12:06:21+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2281143"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-20 12:30:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "6",
                  MatchType: "Cup",
                  MatchWinner: "t31",
                  Period: "FullTime",
                  RoundNumber: "6",
                  RoundType: "Quarter-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "48"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Attwell",
                      Type: "Referee",
                      uID: "o40959"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Constantine",
                      LastName: "Hatzidakis",
                      Type: "Lineman#1",
                      uID: "o43523"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Lineman#2",
                      uID: "o43328"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Fourth official",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Mason",
                      Type: "Video Assistant Referee",
                      uID: "o28689"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Matt",
                      LastName: "Wilkes",
                      Type: "Assistant VAR Official",
                      uID: "o43278"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Selhurst Park", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p209036",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p231747",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p108413",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p82403",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "2",
                    Score: "4",
                    Side: "Home",
                    TeamRef: "t31"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t11"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-05-18T14:21:08+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2281141"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-20 18:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "6",
                  MatchType: "Cup",
                  MatchWinner: "t14",
                  Period: "FullTime",
                  RoundNumber: "6",
                  RoundType: "Quarter-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "40"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Pawson",
                      Type: "Referee",
                      uID: "o41051"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Richard",
                      LastName: "West",
                      Type: "Lineman#1",
                      uID: "o42098"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dan",
                      LastName: "Robathan",
                      Type: "Lineman#2",
                      uID: "o43345"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Fourth official",
                      uID: "o40928"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andy",
                      LastName: "Madley",
                      Type: "Video Assistant Referee",
                      uID: "o41487"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Bennett",
                      Type: "Assistant VAR Official",
                      uID: "o43280"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "The City Ground", "@attributes": { Type: "Venue" } },
                { "@value": "Nottingham", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t17"
                  }
                },
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "SecondHalf",
                      PlayerRef: "p194634",
                      Type: "Goal"
                    }
                  },
                  "@attributes": {
                    HalfScore: "0",
                    Score: "1",
                    Side: "Away",
                    TeamRef: "t14"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-29T09:11:01+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2281142"
              }
            },
            {
              MatchInfo: {
                Date: "2022-03-20 15:00:00",
                TZ: "GMT",
                "@attributes": {
                  MatchDay: "6",
                  MatchType: "Cup",
                  MatchWinner: "t43",
                  Period: "FullTime",
                  RoundNumber: "6",
                  RoundType: "Quarter-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "2370"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Mike",
                      LastName: "Dean",
                      Type: "Referee",
                      uID: "o10508"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Timothy",
                      LastName: "Wood",
                      Type: "Lineman#1",
                      uID: "o43485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Lineman#2",
                      uID: "o43313"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Hooper",
                      Type: "Fourth official",
                      uID: "o41055"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Andre",
                      LastName: "Marriner",
                      Type: "Video Assistant Referee",
                      uID: "o19485"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Sian",
                      LastName: "Massey-Ellis",
                      Type: "Assistant VAR Official",
                      uID: "o41912"
                    }
                  }
                ]
              },
              Stat: [
                {
                  "@value": "St. Mary's Stadium",
                  "@attributes": { Type: "Venue" }
                },
                { "@value": "Southampton", "@attributes": { Type: "City" } }
              ],
              TeamData: [
                {
                  Goal: {
                    "@value": "",
                    "@attributes": {
                      Period: "FirstHalf",
                      PlayerRef: "p146941",
                      Type: "Own"
                    }
                  },
                  "@attributes": {
                    HalfScore: "1",
                    Score: "1",
                    Side: "Home",
                    TeamRef: "t20"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p103955",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p61366",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p103025",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p209244",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "1",
                    Score: "4",
                    Side: "Away",
                    TeamRef: "t43"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-03-29T08:26:07+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2281144"
              }
            },
            {
              MatchInfo: {
                Date: "2022-04-16 15:30:00",
                TZ: "BST",
                "@attributes": {
                  MatchDay: "7",
                  MatchType: "Cup",
                  MatchWinner: "t14",
                  Period: "FullTime",
                  RoundNumber: "7",
                  RoundType: "Semi-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "5"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Michael",
                      LastName: "Oliver",
                      Type: "Referee",
                      uID: "o40962"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Stuart",
                      LastName: "Burt",
                      Type: "Lineman#1",
                      uID: "o43257"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Bennett",
                      Type: "Lineman#2",
                      uID: "o43280"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Kevin",
                      LastName: "Friend",
                      Type: "Fourth official",
                      uID: "o40928"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Darren",
                      LastName: "England",
                      Type: "Video Assistant Referee",
                      uID: "o43268"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Lee",
                      LastName: "Betts",
                      Type: "Assistant VAR Official",
                      uID: "o43274"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Wembley Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } },
                { "@value": "True", "@attributes": { Type: "Neutral" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p114283",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p165809",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t43"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p110979",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p204716",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "FirstHalf",
                        PlayerRef: "p110979",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "3",
                    Score: "3",
                    Side: "Away",
                    TeamRef: "t14"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-04-20T13:15:09+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2282504"
              }
            },
            {
              MatchInfo: {
                Date: "2022-04-17 16:30:00",
                TZ: "BST",
                "@attributes": {
                  MatchDay: "7",
                  MatchType: "Cup",
                  MatchWinner: "t8",
                  Period: "FullTime",
                  RoundNumber: "7",
                  RoundType: "Semi-Finals",
                  TimeFrameLengthId: "2",
                  Venue_id: "5"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Anthony",
                      LastName: "Taylor",
                      Type: "Referee",
                      uID: "o40933"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Gary",
                      LastName: "Beswick",
                      Type: "Lineman#1",
                      uID: "o43269"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Nicholas",
                      LastName: "Hopton",
                      Type: "Lineman#2",
                      uID: "o44332"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Fourth official",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "John",
                      LastName: "Brooks",
                      Type: "Video Assistant Referee",
                      uID: "o43267"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Dan",
                      LastName: "Robathan",
                      Type: "Assistant VAR Official",
                      uID: "o43345"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Wembley Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } },
                { "@value": "True", "@attributes": { Type: "Neutral" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p184341",
                        Type: "Goal"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "SecondHalf",
                        PlayerRef: "p126187",
                        Type: "Goal"
                      }
                    }
                  ],
                  "@attributes": {
                    HalfScore: "0",
                    Score: "2",
                    Side: "Home",
                    TeamRef: "t8"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    HalfScore: "0",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t31"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-04-25T12:59:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2282505"
              }
            },
            {
              MatchInfo: {
                Date: "2022-05-14 16:45:00",
                TZ: "BST",
                "@attributes": {
                  GameWinner: "t14",
                  GameWinnerType: "ShootOut",
                  MatchDay: "8",
                  MatchType: "Cup",
                  Period: "FullTime",
                  RoundNumber: "8",
                  RoundType: "Final",
                  TimeFrameLengthId: "2",
                  Venue_id: "5"
                }
              },
              MatchOfficials: {
                MatchOfficial: [
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Craig",
                      LastName: "Pawson",
                      Type: "Referee",
                      uID: "o41051"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Daniel",
                      LastName: "Cook",
                      Type: "Lineman#1",
                      uID: "o43328"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Edward",
                      LastName: "Smart",
                      Type: "Lineman#2",
                      uID: "o43313"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "David",
                      LastName: "Coote",
                      Type: "Fourth official",
                      uID: "o41028"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Paul",
                      LastName: "Tierney",
                      Type: "Video Assistant Referee",
                      uID: "o41180"
                    }
                  },
                  {
                    "@value": "",
                    "@attributes": {
                      FirstName: "Simon",
                      LastName: "Bennett",
                      Type: "Assistant VAR Official",
                      uID: "o43280"
                    }
                  }
                ]
              },
              Stat: [
                { "@value": "Wembley Stadium", "@attributes": { Type: "Venue" } },
                { "@value": "London", "@attributes": { Type: "City" } },
                { "@value": "True", "@attributes": { Type: "Neutral" } }
              ],
              TeamData: [
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p124183",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p88894",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p85955",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p82263",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p225796",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "0",
                    PenaltyScore: "5",
                    Score: "0",
                    Side: "Home",
                    TeamRef: "t8"
                  }
                },
                {
                  Goal: [
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p194634",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p92217",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p61558",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p169187",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p214285",
                        Type: "Penalty"
                      }
                    },
                    {
                      "@value": "",
                      "@attributes": {
                        Period: "ShootOut",
                        PlayerRef: "p15157",
                        Type: "Penalty"
                      }
                    }
                  ],
                  "@attributes": {
                    ExtraScore: "0",
                    HalfScore: "0",
                    NinetyScore: "0",
                    PenaltyScore: "6",
                    Score: "0",
                    Side: "Away",
                    TeamRef: "t14"
                  }
                }
              ],
              "@attributes": {
                detail_id: "1",
                last_modified: "2022-06-20T14:42:46+00:00",
                timestamp_accuracy_id: "1",
                timing_id: "1",
                uID: "g2284552"
              }
            }
          ],
          TimingTypes: {
            DetailTypes: {
              DetailType: [
                {
                  "@value": "",
                  "@attributes": { detail_id: "0", name: "Unknown" }
                },
                {
                  "@value": "",
                  "@attributes": {
                    detail_id: "1",
                    name: "All Events (with positions)"
                  }
                },
                {
                  "@value": "",
                  "@attributes": { detail_id: "2", name: "All Events" }
                },
                {
                  "@value": "",
                  "@attributes": { detail_id: "3", name: "Key Events" }
                },
                {
                  "@value": "",
                  "@attributes": { detail_id: "4", name: "Lineups, Goals & Cards" }
                },
                { "@value": "", "@attributes": { detail_id: "5", name: "Goals" } },
                { "@value": "", "@attributes": { detail_id: "6", name: "Scores" } }
              ]
            },
            TimestampAccuracyTypes: {
              TimestampAccuracyType: [
                {
                  "@value": "",
                  "@attributes": { name: "Unknown", timestamp_accuracy_id: "0" }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "Fully Accurate Timestamps (24hr + Match Times)",
                    timestamp_accuracy_id: "1"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "Accurate Match Times only",
                    timestamp_accuracy_id: "2"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "To The Minute Accurate",
                    timestamp_accuracy_id: "3"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "Low Level Accurate Match Times",
                    timestamp_accuracy_id: "4"
                  }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "No Match Time Accuracy",
                    timestamp_accuracy_id: "5"
                  }
                }
              ]
            },
            TimingType: {
              TimingType: [
                {
                  "@value": "",
                  "@attributes": { name: "Unknown", timing_id: "0" }
                },
                { "@value": "", "@attributes": { name: "Live", timing_id: "1" } },
                {
                  "@value": "",
                  "@attributes": { name: "Live Delayed", timing_id: "2" }
                },
                {
                  "@value": "",
                  "@attributes": {
                    name: "Post Match (less than 24 hours)",
                    timing_id: "3"
                  }
                },
                {
                  "@value": "",
                  "@attributes": { name: "Post Match (24 hours+)", timing_id: "4" }
                }
              ]
            }
          },
          Team: [
            { Name: "Bowers & Pitsea", "@attributes": { uID: "t9368" } },
            { Name: "Brentford", "@attributes": { uID: "t94" } },
            { Name: "Bolton Wanderers", "@attributes": { uID: "t30" } },
            { Name: "Coventry City", "@attributes": { uID: "t9" } },
            { Name: "Nottingham Forest", "@attributes": { uID: "t17" } },
            { Name: "Notts County", "@attributes": { uID: "t104" } },
            { Name: "AFC Wimbledon", "@attributes": { uID: "t2623" } },
            { Name: "Lincoln City", "@attributes": { uID: "t83" } },
            { Name: "West Ham United", "@attributes": { uID: "t21" } },
            { Name: "Barnsley", "@attributes": { uID: "t37" } },
            { Name: "Manchester City", "@attributes": { uID: "t43" } },
            { Name: "Bristol Rovers", "@attributes": { uID: "t96" } },
            { Name: "Horsham", "@attributes": { uID: "t2515" } },
            { Name: "Mansfield Town", "@attributes": { uID: "t77" } },
            { Name: "Blackpool", "@attributes": { uID: "t92" } },
            { Name: "Yeovil Town", "@attributes": { uID: "t283" } },
            { Name: "Buxton", "@attributes": { uID: "t3145" } },
            { Name: "Eastleigh", "@attributes": { uID: "t2625" } },
            { Name: "Crewe Alexandra", "@attributes": { uID: "t51" } },
            { Name: "Hull City", "@attributes": { uID: "t88" } },
            { Name: "FC Halifax Town", "@attributes": { uID: "t69" } },
            { Name: "Stockport County", "@attributes": { uID: "t48" } },
            { Name: "Southampton", "@attributes": { uID: "t20" } },
            { Name: "Wrexham", "@attributes": { uID: "t109" } },
            { Name: "Chesterfield", "@attributes": { uID: "t98" } },
            { Name: "Everton", "@attributes": { uID: "t11" } },
            { Name: "Crystal Palace", "@attributes": { uID: "t31" } },
            { Name: "Rochdale", "@attributes": { uID: "t82" } },
            { Name: "Oxford United", "@attributes": { uID: "t106" } },
            { Name: "St Albans City", "@attributes": { uID: "t2246" } },
            { Name: "Walsall", "@attributes": { uID: "t42" } },
            { Name: "Altrincham", "@attributes": { uID: "t295" } },
            { Name: "Burton Albion", "@attributes": { uID: "t587" } },
            { Name: "Reading", "@attributes": { uID: "t108" } },
            { Name: "Port Vale", "@attributes": { uID: "t50" } },
            { Name: "Scunthorpe United", "@attributes": { uID: "t93" } },
            { Name: "Swindon Town", "@attributes": { uID: "t46" } },
            { Name: "Fleetwood Town", "@attributes": { uID: "t2048" } },
            { Name: "Sheffield Wednesday", "@attributes": { uID: "t19" } },
            { Name: "Exeter City", "@attributes": { uID: "t71" } },
            { Name: "Leyton Orient", "@attributes": { uID: "t85" } },
            { Name: "Crawley Town", "@attributes": { uID: "t1525" } },
            { Name: "Sutton United", "@attributes": { uID: "t296" } },
            { Name: "Accrington Stanley", "@attributes": { uID: "t888" } },
            { Name: "Watford", "@attributes": { uID: "t57" } },
            { Name: "Chelsea", "@attributes": { uID: "t8" } },
            { Name: "Hayes & Yeading", "@attributes": { uID: "t2627" } },
            { Name: "Norwich City", "@attributes": { uID: "t45" } },
            { Name: "Oldham Athletic", "@attributes": { uID: "t105" } },
            { Name: "Tranmere Rovers", "@attributes": { uID: "t44" } },
            { Name: "Wolverhampton Wanderers", "@attributes": { uID: "t39" } },
            { Name: "Harrow Borough", "@attributes": { uID: "t3120" } },
            { Name: "Newcastle United", "@attributes": { uID: "t4" } },
            { Name: "York City", "@attributes": { uID: "t78" } },
            { Name: "Swansea City", "@attributes": { uID: "t80" } },
            { Name: "Harrogate Town", "@attributes": { uID: "t671" } },
            { Name: "Stratford Town", "@attributes": { uID: "t3364" } },
            { Name: "Colchester United", "@attributes": { uID: "t99" } },
            { Name: "Ipswich Town", "@attributes": { uID: "t40" } },
            { Name: "Leicester City", "@attributes": { uID: "t13" } },
            { Name: "Sunderland", "@attributes": { uID: "t56" } },
            { Name: "Bradford City", "@attributes": { uID: "t55" } },
            { Name: "Kidderminster Harriers", "@attributes": { uID: "t285" } },
            { Name: "Newport County", "@attributes": { uID: "t437" } },
            { Name: "Fulham", "@attributes": { uID: "t54" } },
            { Name: "Charlton Athletic", "@attributes": { uID: "t33" } },
            { Name: "MK Dons", "@attributes": { uID: "t22" } },
            { Name: "Cheltenham Town", "@attributes": { uID: "t87" } },
            { Name: "Wigan Athletic", "@attributes": { uID: "t111" } },
            { Name: "Blackburn Rovers", "@attributes": { uID: "t5" } },
            { Name: "Preston North End", "@attributes": { uID: "t107" } },
            { Name: "Bristol City", "@attributes": { uID: "t113" } },
            { Name: "Gillingham", "@attributes": { uID: "t100" } },
            { Name: "Havant & Waterlooville", "@attributes": { uID: "t586" } },
            { Name: "Portsmouth", "@attributes": { uID: "t47" } },
            { Name: "Bournemouth", "@attributes": { uID: "t91" } },
            { Name: "Barrow", "@attributes": { uID: "t573" } },
            { Name: "Wycombe Wanderers", "@attributes": { uID: "t112" } },
            { Name: "Peterborough United", "@attributes": { uID: "t73" } },
            { Name: "Kings Lynn Town", "@attributes": { uID: "t6152" } },
            { Name: "Ebbsfleet United", "@attributes": { uID: "t585" } },
            { Name: "Middlesbrough", "@attributes": { uID: "t25" } },
            { Name: "Derby County", "@attributes": { uID: "t24" } },
            { Name: "Queens Park Rangers", "@attributes": { uID: "t52" } },
            { Name: "Stevenage", "@attributes": { uID: "t288" } },
            { Name: "Banbury United", "@attributes": { uID: "t3149" } },
            { Name: "Leeds United", "@attributes": { uID: "t2" } },
            { Name: "Huddersfield Town", "@attributes": { uID: "t38" } },
            { Name: "Doncaster Rovers", "@attributes": { uID: "t290" } },
            { Name: "Tottenham Hotspur", "@attributes": { uID: "t6" } },
            { Name: "Morecambe", "@attributes": { uID: "t286" } },
            { Name: "Aston Villa", "@attributes": { uID: "t7" } },
            { Name: "Guiseley", "@attributes": { uID: "t1520" } },
            { Name: "Northampton Town", "@attributes": { uID: "t75" } },
            { Name: "Shrewsbury Town", "@attributes": { uID: "t74" } },
            { Name: "Cambridge United", "@attributes": { uID: "t101" } },
            { Name: "Stoke City", "@attributes": { uID: "t110" } },
            { Name: "Millwall", "@attributes": { uID: "t103" } },
            { Name: "Maidenhead United", "@attributes": { uID: "t820" } },
            { Name: "Boreham Wood", "@attributes": { uID: "t2479" } },
            { Name: "Southend United", "@attributes": { uID: "t81" } },
            { Name: "Salford City", "@attributes": { uID: "t3448" } },
            { Name: "Birmingham City", "@attributes": { uID: "t41" } },
            { Name: "Plymouth Argyle", "@attributes": { uID: "t76" } },
            { Name: "Yate Town", "@attributes": { uID: "t3159" } },
            { Name: "Cardiff City", "@attributes": { uID: "t97" } },
            { Name: "Bromley", "@attributes": { uID: "t2050" } },
            { Name: "Rotherham United", "@attributes": { uID: "t72" } },
            { Name: "Solihull Moors", "@attributes": { uID: "t1493" } },
            { Name: "Forest Green Rovers", "@attributes": { uID: "t302" } },
            { Name: "Manchester United", "@attributes": { uID: "t1" } },
            { Name: "AFC Sudbury", "@attributes": { uID: "t3265" } },
            { Name: "Brighton and Hove Albion", "@attributes": { uID: "t36" } },
            { Name: "Liverpool", "@attributes": { uID: "t14" } },
            { Name: "Burnley", "@attributes": { uID: "t90" } },
            { Name: "Dagenham and Redbridge", "@attributes": { uID: "t457" } },
            { Name: "West Bromwich Albion", "@attributes": { uID: "t35" } },
            { Name: "Gateshead", "@attributes": { uID: "t447" } },
            { Name: "Sheffield United", "@attributes": { uID: "t49" } },
            { Name: "Grimsby Town", "@attributes": { uID: "t53" } },
            { Name: "Luton Town", "@attributes": { uID: "t102" } },
            { Name: "Arsenal", "@attributes": { uID: "t3" } },
            { Name: "Hartlepool United", "@attributes": { uID: "t89" } },
            { Name: "Carlisle United", "@attributes": { uID: "t68" } }
          ],
          "@attributes": {
            Type: "RESULTS Latest",
            competition_code: "EN_FA",
            competition_id: "1",
            competition_name: "English FA Cup",
            game_system_id: "1",
            season_id: "2021",
            season_name: "Season 2021/2022",
            timestamp: "2022-08-09 09-00-50"
          }
        }
      }
};
export default stats;
