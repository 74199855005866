export const VolumeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 48.666 46.148">
    <g id="volume-unmute" transform="translate(-201 -1000.852)">
      <path id="Path_16856" data-name="Path 16856" d="M345.6,241.347a2,2,0,0,1-1.41-3.41,7.166,7.166,0,0,0,0-10.135,2,2,0,1,1,2.83-2.83,11.174,11.174,0,0,1,0,15.785A2,2,0,0,1,345.6,241.347Z" transform="translate(-109.271 791.629)" fill="#fff"/>
      <path id="Path_16857" data-name="Path 16857" d="M391.494,207.7a2,2,0,0,1-1.415-3.415,15.839,15.839,0,0,0,0-22.375,2,2,0,1,1,2.83-2.83,19.848,19.848,0,0,1,0,28.035A2,2,0,0,1,391.494,207.7Z" transform="translate(-149.04 831.399)" fill="#fff"/>
      <path id="Path_16461" data-name="Path 16461" d="M26.229,0a4.826,4.826,0,0,1,2.324,1.2A4.037,4.037,0,0,1,29.729,3.8c.018.239.014.48.014.72q0,18.507,0,37.013c0,2.582-.965,3.864-3.437,4.567a.348.348,0,0,0-.077.046H25.147a5.917,5.917,0,0,1-2.586-1.375c-3.483-2.927-6.987-5.83-10.476-8.751a1.326,1.326,0,0,0-.928-.338c-2.313.016-4.626.014-6.939.006a3.869,3.869,0,0,1-3.4-1.682A10.677,10.677,0,0,1,0,32.088V14.061a8.643,8.643,0,0,1,.411-1.276A3.994,3.994,0,0,1,4.219,10.46q3.447-.025,6.893,0a1.436,1.436,0,0,0,1.008-.367c3.361-2.816,6.739-5.612,10.1-8.432A6.486,6.486,0,0,1,25.147,0Zm.81,23.073q0-9.394,0-18.787a1.423,1.423,0,0,0-.8-1.44,1.382,1.382,0,0,0-1.577.3q-5.411,4.519-10.833,9.024a.8.8,0,0,0-.326.681q.014,10.227,0,20.454a.793.793,0,0,0,.327.68q2.414,1.993,4.814,4Q21.665,40.494,24.676,43a1.361,1.361,0,0,0,1.656.26,1.463,1.463,0,0,0,.712-1.4q-.012-9.394,0-18.787M10.784,33V13.164c-2.283,0-4.535-.014-6.786.008a1.322,1.322,0,0,0-1.292,1.444q-.006,8.447,0,16.895A1.364,1.364,0,0,0,4.189,33c1.1.007,2.193,0,3.289,0h3.305" transform="translate(201 1000.852)" fill="#fff"/>
    </g>
  </svg>
)

export const VolumeMuteIcon=()=>(
  <svg id="volume" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 46 46">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_3424" data-name="Rectangle 3424" width="46" height="46" fill="#fff"/>
      </clipPath>
    </defs>
    <g id="Group_7292" data-name="Group 7292" clipPath="url(#clip-path)">
      <path id="Path_16461" data-name="Path 16461" d="M26.229,0a4.826,4.826,0,0,1,2.324,1.2A4.037,4.037,0,0,1,29.729,3.8c.018.239.014.48.014.72q0,18.507,0,37.013c0,2.582-.965,3.864-3.437,4.567a.348.348,0,0,0-.077.046H25.147a5.917,5.917,0,0,1-2.586-1.375c-3.483-2.927-6.987-5.83-10.476-8.751a1.326,1.326,0,0,0-.928-.338c-2.313.016-4.626.014-6.939.006a3.869,3.869,0,0,1-3.4-1.682A10.677,10.677,0,0,1,0,32.088V14.061a8.643,8.643,0,0,1,.411-1.276A3.994,3.994,0,0,1,4.219,10.46q3.447-.025,6.893,0a1.436,1.436,0,0,0,1.008-.367c3.361-2.816,6.739-5.612,10.1-8.432A6.486,6.486,0,0,1,25.147,0Zm.81,23.073q0-9.394,0-18.787a1.423,1.423,0,0,0-.8-1.44,1.382,1.382,0,0,0-1.577.3q-5.411,4.519-10.833,9.024a.8.8,0,0,0-.326.681q.014,10.227,0,20.454a.793.793,0,0,0,.327.68q2.414,1.993,4.814,4Q21.665,40.494,24.676,43a1.361,1.361,0,0,0,1.656.26,1.463,1.463,0,0,0,.712-1.4q-.012-9.394,0-18.787M10.784,33V13.164c-2.283,0-4.535-.014-6.786.008a1.322,1.322,0,0,0-1.292,1.444q-.006,8.447,0,16.895A1.364,1.364,0,0,0,4.189,33c1.1.007,2.193,0,3.289,0h3.305" transform="translate(0 -0.149)" fill="#fff"/>
      <path id="Path_16462" data-name="Path 16462" d="M375.536,214.335a2.087,2.087,0,0,0-.117.176,1.336,1.336,0,0,1-2.215.379c-.846-.809-1.661-1.65-2.489-2.478-.635-.635-1.268-1.271-1.924-1.929-.148.138-.269.244-.383.358q-1.975,1.974-3.947,3.95a1.418,1.418,0,0,1-1.327.5,1.3,1.3,0,0,1-1.051-.937,1.317,1.317,0,0,1,.383-1.4q1.474-1.487,2.959-2.964c.466-.465.934-.928,1.426-1.416-.134-.145-.239-.267-.351-.38-1.336-1.338-2.682-2.668-4.008-4.016a1.346,1.346,0,0,1,.523-2.327,1.411,1.411,0,0,1,1.456.48q1.953,1.964,3.912,3.921a4.632,4.632,0,0,1,.332.428c.183-.174.3-.281.414-.393q1.9-1.894,3.79-3.789c1.043-1.043,1.887-.948,2.617.295v.721c-.29.354-.554.733-.873,1.058-1.2,1.219-2.412,2.421-3.62,3.63-.112.112-.218.229-.349.367,1.362,1.358,2.694,2.674,4.008,4.008a12.891,12.891,0,0,1,.834,1.033Z" transform="translate(-329.388 -183.748)" fill="#fff"/>
    </g>
  </svg>
)
