import React, { Fragment } from "react";
import { Grid } from "@mui/material";

function Card(props) {
  const {
    image,
    title,
    ctitle,
    csubtitle,
    isLive,
    yearOfRelease,
    type = "potrait",
    id,
    railId,
    getCardDetail,
    item,
    showCategory
  } = props;
  const clickOnCard = () => {
    getCardDetail(item,id);
  };
  const renderPortraitCard = () => {
    return (
      <div className="category-card-content" id={id} onClick={clickOnCard}>
          <img
          className="upper-sport-image"
          src={
              "https://dn8wbd49q0doa.cloudfront.net/" +
              image
            }
            alt="movie-poster"
            style={{ height: "100%" }}
          />
          <div className="details">
            <div className="details-content">
              <span className="content-yor">{yearOfRelease}</span>
              <span className="content-movie-name">{title} </span>
            </div>
          </div>
      </div>
    );
  };
  const renderLandscapeCard = () => {
    return (
      // <div className="tab-main-card">
      //   <img src={image} style={{ position: "absolute" }} />
      //   <Grid className="sportCategory" style={{ display: "inline-block" }}>
      //     <div className="sportCategory-text">{title}</div>
      //   </Grid>
      //   <Grid className="badge-live">
      //     <div className="icon"></div>
      //     <div className="text-live">Live</div>
      //   </Grid>
      //   <Grid className="card-headings">
      //     <span className="content-title">EURO Cup</span>
      //     <span className="content-subtitle">Brazil vs Argentina</span>
      //   </Grid>
      // </div>


      <div className="tab-main-card">
        <img src={image} style={{ position: "absolute" }} />
        {showCategory == "true" ?
        <Grid className="sportCategory">
        <div className="sportCategory-text">{title}</div>
      </Grid>:  null
        }

        
     
        {isLive == "true" ? 
        (<>
        <Grid className="badge-live">
          <div className="icon"></div>
          <div className="text-live">Live</div>
        </Grid>
        </>)
        : null
        }
           <Grid className="card-headings">
          <span className="content-title">{ctitle}</span>
          <span className="content-subtitle">{csubtitle}</span>
        </Grid>
       
      </div>



    );
  };
  return (
    <Fragment>
      {type == "potrait" ? renderPortraitCard() : renderLandscapeCard()}
    </Fragment>
  );
}

export default Card;
