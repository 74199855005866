import GroupsSharpIcon from "@mui/icons-material/GroupsSharp";
import { Grid } from "@mui/material";
import axios from "axios";
import WatchTogether from "components/common/WatchTogether";
import { getParamsFromUrl } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { stringToSlug } from "store/utils";
import InviteBySMS from "./InviteBySms";
import "./style.scss";

export default function WatchParty({ enableSidebar }) {
  const [syncToken, setSyncToken] = useState(null);
  const [streamToken, setStreamToken] = useState(null);
  const [watchPartyEnabled, setWatchPartyEnabled] = useState(false);
  const [roomName, setRoomName] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const params = getParamsFromUrl(history);
    console.log("params :: ", params);
    if (params["room"]) {
      const room = params["room"];
      console.log("params :: ", room);
      enableWatchParty(room, true);
    }
  }, []);

  const enableWatchParty = (roomName, showSidebar = false) => {
    const onTokenReponse = (syncToken, streamToken) => {
      setSyncToken(syncToken);
      setStreamToken(streamToken);
      setWatchPartyEnabled(true);
      setRoomName(roomName);
      if (showSidebar) {
        enableSidebar(true, "WatchParty");
      }
    };

    const roomNameSlug = stringToSlug(roomName);

    axios
      .get(
        `https://vrsports-tothenew.videoready.tv/gateway/content/watchparty/v1/sceenic/token/${roomNameSlug}`
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status == true) {
          const { syncToken, streamToken } = res.data.data;
          onTokenReponse(syncToken, streamToken);
        }
      })
      .catch(() => {
        const syncToken =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyaWQiOiI3ODBlOTE3OC0wNzgzLTRhNTMtODcxYS0zMGUzMmY1MWYzMjkiLCJ1bmN0IjoiN2RjN2QxNDEtYWY4OS00NDJlLWI5M2MtY2FkZDU1NWU2NWIxIiwiaG9zdCI6InN5bmMuczEuc2NlZW5pYy5jbyIsImV4cCI6MTY1ODMwNDgzOX0.BfYWH7rlh1anNfJg2ExpwhWRjFLOVWL7VqO6f9VQhT8";
        const streamToken =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyaWQiOiJmYWZmODhlOC02YTYzLTQyYTUtYWJiZS0yZGY2NDNlYzI2ZmYiLCJ1bmN0IjoiN2RjN2QxNDEtYWY4OS00NDJlLWI5M2MtY2FkZDU1NWU2NWIxIiwiZ3JvdXQiOiJodHRwczovL2dyb3V0LnMxLnNjZWVuaWMuY28vYXBpL3NlbGVjdCIsImVudiI6InByb2QiLCJleHAiOjE2NTgzMDQ4MjJ9.4Ve1-r2ugtrq1wREpMo1Ynf_Z7EtFXN46EclFn5Fm_U";
        onTokenReponse(syncToken, streamToken);
      });
  };

  return (
    <>
      {!watchPartyEnabled && (
        <div className="watch-party-container" id="watch-party-container">
          <Grid className="watch-party-title">
            <div className="participant-left-div">
              <div className="icon-container">
                <GroupsSharpIcon className="icons" />
              </div>
              <span>Watch Party</span>
            </div>
          </Grid>
          <div className="line-2"></div>
          <Grid>
            <p className="watch-party-title2">Let the party begin</p>
            <p className="watch-party-subtitle2">
              Watch and chat with your friends and family.
            </p>
            <InviteBySMS enableWatchParty={enableWatchParty} />
          </Grid>
        </div>
      )}
      {watchPartyEnabled && (
        <WatchTogether
          roomName={roomName}
          syncToken={syncToken}
          streamToken={streamToken}
          enableSidebar={enableSidebar}
          setWatchPartyEnabled={setWatchPartyEnabled}
        />
      )}
    </>
  );
}
