import { useState, useContext } from "react";
// import VideoPlayerControls from "./.VideoPlayerControls";
import { Typography } from "antd";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./style.scss";
import PipContext from "../../../helpers/contexts/PipContext";

const { Title } = Typography;

export default function MultiViewPlayer(props) {
  const { players, enableSidebar, enabledSidebar, setTLMPlayer, isTLMEnabled, toggleMultiView, showMultiView, pipFullScreenHandler, brandingData } =
    props;
  const [playersArray, addToPlayersArray] = useState([]);
  const {isVisible} = useContext(PipContext);

  const pushIsMainMethodToArray = (setIsMainPlayer) => {
    const list = playersArray;
    list.push(setIsMainPlayer);
    addToPlayersArray(list);
  };

  const resetMainPlayer = () => {
    playersArray.forEach((setIsMainPlayer) => {
      setIsMainPlayer(false);
    });
  };

  return (
    <div
      className={`mutiview-sidebar ${
        enabledSidebar == "MultiView" ? "" : "hidden"
      }`}
    >
      <div className={`sidebar-background  ${isVisible ? "" : "display-none"}`}></div>
      {isVisible ? <div className="title-container">
        <Title className="multiview-title" level={5} style={{ color: "white" }}>
          MULTICAMERA VIEW
        </Title>
      </div>: null}
      <div className="multiview-list">
        {players &&
          players.map((config, index) => {
            if (index > 0) {
              if(showMultiView) return (<></>);
              config.muted = JSON.parse(localStorage.getItem("muted"));
              // config.autoplay = false;
            }
            return (
              <VideoPlayer
                playerIndex={index}
                key={"player" + index}
                playerConfig={config}
                enableSidebar={enableSidebar}
                enabledSidebar={enabledSidebar}
                resetMainPlayer={resetMainPlayer}
                pushIsMainMethodToArray={pushIsMainMethodToArray}
                setTLMPlayer={setTLMPlayer}
                isTLMEnabled={isTLMEnabled}
                toggleMultiView={toggleMultiView}
                showMultiView={showMultiView}
                pipFullScreenHandler={pipFullScreenHandler}
                brandingData={brandingData}
              />
            );
          })}
      </div>
    </div>
  );
}
