import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useReelsHooks = (videoPlayerInstance) => {
  const history = useHistory();
  const [isMute, setIsMute] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [reelDuration, setReelDuration] = useState(0);
  const [reelCurrentPlayTime, setReelCurrentPlayTime] = useState(0);
  const [isReelEnded, setIsReelEnd] = useState(false);
  const [showReelsLoader, setShowReelsLoader] = useState(true);

  useEffect(()=>{
    if(videoPlayerInstance){
      videoPlayerInstance.subscribeExternal({
        playerCallback: (events)=>playerCallBack(events),
        subscriberName: "REELS"
      });
    }
  },[videoPlayerInstance]);

  const playerCallBack=(event)=>{
    switch (event){
      case "loadeddata": {
        setShowReelsLoader(false);
        setReelDuration(videoPlayerInstance.playerObj.duration());        
        break;
      }
      case "seekbarupdate": {
        setReelCurrentPlayTime(Math.floor(videoPlayerInstance.currentTime()))
        break;
      }
      case "ended": {
        setReelCurrentPlayTime(100);
        setIsReelEnd(true);
        break
      }
      default:
        break;
    }
  }

  const muteToggle = () => {
    if (videoPlayerInstance) {
      setIsMute((mute) => {
        videoPlayerInstance.muted(!mute);
        return !mute;
      });
    }
  };

  const playPause = (id) => {
    if (videoPlayerInstance) {
      setIsPlaying((playing) => {
        if (playing) {
          videoPlayerInstance.pause();
        } else {
          videoPlayerInstance.play();
        }
        return !playing;
      });
    }
    history.push(`/voddetail-${id}-0`);
  };

  const like = () => {};

  const share = () => {};

  return {
    isMute,
    isPlaying,
    muteToggle,
    playPause,
    like,
    share,
    reelDuration,
    reelCurrentPlayTime,
    isReelEnded,
    showReelsLoader
  };
};

export default useReelsHooks;
