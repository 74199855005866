import MatchDetailPlayer from "components/common/MatchDetailPlayer";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddLBand from "../../components/common/AdLBand";
import Pip from "../../components/common/Pip";
import PipContext from "../../helpers/contexts/PipContext";
import VODInfo from "./VODInfo";
import Recommendations from "./Recommendations";
import "./style.scss";
import reels from "../../components/common/Reels/assets/reels.mock.json";
import data from "../../components/common/HomePage/continueWatching.json";
import { useSelector } from "react-redux";

const videoPlayerConfig = {
  multiViewEnabled: true,
  players: [
    {
      playerName: "Default",
      isLive: false,
      isEncrypted: false,
      autoplay: true,
      videoId: "ttn-player1",
      videoWrapperID: "video-player-wrapper1",
      videoUrl:
        "https://automatedtranscodingwatchfolder-mediabucket-18dj1vf6cp2np.s3.amazonaws.com/It_s_the_Match_we_have_all_been_Waiting_For_Orange_Cassidy_vs_Matt_Hardy_AEW_Dynamite_8_25_21/Default/HLS/It_s_the_Match_we_have_all_been_Waiting_For_Orange_Cassidy_vs_Matt_Hardy_AEW_Dynamite_8_25_21.m3u8"
    }
  ]
};

function VODDetailPage() {
  const { vodid, isLowerCard } = useParams();
  const [vodData, setVODData] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [videoPlayerConfiguration, setVideoPlayerConfig] = useState({});
  const lowerCardData = useSelector((state) => state.voddata.data);

  useEffect(() => {
    setPageData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible, isAdVisible]);

  const setPageData = () => {
    let vodDetails = {};
    const vodResponse = reels.filter((vod) => vod.id == vodid);
    if (isLowerCard == 1) {
      let videoURL = "";
      let videoData = {};
      lowerCardData.forEach((rail) => {
        rail.contentEditorial.forEach((card) => {
          if (card.contentId === vodid) {
            videoURL = card.content.detail.playUrl;
            videoData.title = card.content.title;
            videoData.subTitle = card.content.description;
          }
        });
      });
      videoPlayerConfig.players[0].videoUrl = videoURL;
      vodDetails.metadata = videoData;
    } else if (vodResponse.length <= 0) {
      vodDetails = data.filter((vod) => vod.id == vodid)[0];
      videoPlayerConfig.players[0].videoUrl = vodDetails.url;
    } else {
      vodDetails = vodResponse[0];
      videoPlayerConfig.players[0].videoUrl = vodDetails.url;
    }
    setVideoPlayerConfig({ ...videoPlayerConfig });
    setVODData({ ...vodDetails });
  };

  const listenToScroll = () => {
    const heightToHideFrom = document.getElementById("pipVideo").offsetTop;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const dp = document.getElementsByClassName("player-height");
    const leftband = document.getElementById("leftBanner");
    const fulEl = document.fullscreenElement;
    const display = document.getElementsByClassName("detail-player-wrapper");

    if (fulEl && fulEl.getAttribute("id") === "player-with-lband") {
      if (!p && dp && dp.length) {
        leftband.setAttribute("style", "width: 20%");
        dp[0].style.transition = "height 500ms linear";
        dp[0].style.height = "85vh";
      } else {
        leftband.setAttribute("style", "width: 0");
        dp[0].style.height = "100vh";
      }
    } else {
      leftband?.removeAttribute("style");
    }
    if (winScroll > heightToHideFrom) {
      if (dp && dp.length) {
        dp[0].style.height = "36vh";
        dp[0].style.minHeight = "0";
        display[0].style.minHeight = "0";
        dp[0].style.transition = "none";
      }
      setIsVisible(false);
    } else {
      dp[0].style.display = "flex";
      if (isAdVisible) {
        dp[0].style.height = "55vh";
        dp[0].style.minHeight = "450px";
        display[0].style.minHeight = "450px";
        setIsVisible(true);
      } else {
        dp[0].style.height = "85vh";
        dp[0].style.minHeight = "450px";
        display[0].style.minHeight = "450px";
        setIsVisible(true);
      }
    }
  };

  return (
    <Fragment>
      {Object.keys(vodData).length && (
        <PipContext.Provider
          value={{ isVisible, setIsVisible, isAdVisible, setIsAdVisible }}
        >
          <Pip>
            <AddLBand isForMatchDetailPlayer={true}>
              <MatchDetailPlayer videoPlayerConfig={videoPlayerConfiguration} />
            </AddLBand>
          </Pip>
          <VODInfo
            title={vodData.metadata.title}
            description={vodData.metadata.subTitle}
          />
          <Recommendations />
        </PipContext.Provider>
      )}
    </Fragment>
  );
}

export default VODDetailPage;
