import { Col, Row, Tooltip } from "antd";
import SocialShare from "../../components/common/SocialShare";

export default function VODInfo(props) {
  const { title, description } = props;
  const url = `https://www.tothenew.com/`;
  return (
    <Row id="pipVideo" style={{ marginBottom: "40px" }}>
      <Col span={22} offset={1} className="match-info">
        <Row wrap={false}>
          <Col span={20}>
            <div className="left-match-info-div">
              <div className="match-info-detail">
                {/* <p className="match-info-detail-series">{title} </p> */}
                <p className="match-info-detail-name">{title}</p>
                <p className="match-info-detail-detail">{description}</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="add-and-share-icon">
              <Tooltip
                placement="top"
                align={{ offset: [0, 5] }}
                title={"Add to WatchList"}
              >
                <div className="icon">
                  <img src="/images/watchlist.svg" />
                </div>
              </Tooltip>
              <SocialShare url={url} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
