import { useState, useEffect } from "react";
import { Button } from "antd";
import muteIcon from "../../../images/mute_icon.svg";
import unMuteIcon from "../../../images/unmute_icon.svg";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./style.scss";

export default function MultiScreenView(props) {
  const { toggleMultiView } = props;
  const [showDualScreen, setShowDualScreen] = useState(true);
  const [showOverlayPlayer, setShowOverlayPlayer] = useState(false);
  const [currentlyActiveIcon, setCurrentlyActiveIcon] = useState('dualIcon');
  const [play, setPlay] = useState(true);
  const [mute, setMuteFlag] = useState({player1: true, player2: true});
  
  const videoPlayerConfig1 = {
    playerName: "Default",
    isLive: true,
    isEncrypted: false,
    autoplay: true,
    videoId: "ttn-player-multi-view-1",
    videoWrapperID: "video-player-wrapper-multi-view-1",
    contentId: "test-content",
    videoUrl:
      "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/football/football-manu-vs-middlesb-fa-cup-2021-22.m3u8",
    showControls: false
    // videoUrl:
    // "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
  };
  const videoPlayerConfig2 = {
    playerName: "Default",
    isLive: true,
    isEncrypted: false,
    autoplay: true,
    videoId: "ttn-player-multi-view-2",
    videoWrapperID: "video-player-wrapper-multi-view-2",
    contentId: "test-content",
    videoUrl:
    "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/2-football/chelsea-vs-manu-fa-cup-2020-21.m3u8",
    showControls: false
    // videoUrl:
    // "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
  };
  const videoPlayerConfig3 = {
    playerName: "Default",
    isLive: true,
    isEncrypted: false,
    autoplay: true,
    videoId: "ttn-player-multi-view-3",
    videoWrapperID: "video-player-wrapper-multi-view-3",
    contentId: "test-content",
    videoUrl:
    "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/2-football/chelsea-vs-manu-fa-cup-2020-21.m3u8",
    showControls: false
    // videoUrl:
    // "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
  };

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
  }, [])

  const toggleSingleScreen = (e) => {
    // setShowSingleScreen(true);
    document.getElementById(currentlyActiveIcon).classList.remove('active');
    setTimeout(() => {
      setCurrentlyActiveIcon(e.target.id);
    }, 200);
    document.getElementById(e.target.id).classList.add('active');
    document.getElementById('video-player-wrapper-multi-view-1').classList.add('singleScreen-padding');
    setShowDualScreen(false);
    setShowOverlayPlayer(false);
  }

  const toggleDualScreen = (e) => {
    // setShowSingleScreen(false);
    document.getElementById(currentlyActiveIcon).classList.remove('active');
    setTimeout(() => {
      setCurrentlyActiveIcon(e.target.id);
    }, 200);
    document.getElementById(e.target.id).classList.add('active');
    document.getElementById('video-player-wrapper-multi-view-1').classList.remove('singleScreen-padding');
    setShowDualScreen(true);
    setShowOverlayPlayer(false);
    setMuteFlag((state) => {
      return {...state, player2: true}
    }); 
  }

  const toggleOverlayPlayer = (e) =>{
    document.getElementById(currentlyActiveIcon).classList.remove('active');
    setTimeout(() => {
      setCurrentlyActiveIcon(e.target.id);
    }, 200);
    document.getElementById(e.target.id).classList.add('active');
    document.getElementById('video-player-wrapper-multi-view-1').classList.add('singleScreen-padding');
    setShowDualScreen(false);
    setShowOverlayPlayer(true);
  }

  const setMute = (index) => {
    const currentPlayer = window.VIDEO_PLAYERS[index];
    const isMuted = currentPlayer.muted();
    const otherPlayerIndex = index == 1 ? 2 : 1;
    const otherPlayer = window.VIDEO_PLAYERS[otherPlayerIndex];
    const otherPlayerMuteState = otherPlayer.muted();
    if(isMuted && !otherPlayerMuteState){
      currentPlayer.muted(!isMuted);
      otherPlayer.muted(!otherPlayerMuteState);
      setMuteFlag({[`player${index}`]: !isMuted, [`player${otherPlayerIndex}`] : !otherPlayerMuteState});
    } else {
      currentPlayer.muted(!isMuted);
      setMuteFlag((state) => {
        return {...state, [`player${index}`]: !isMuted}
      }); 
    }
  };
  return (
    <div className="wrapper-multi-screen">
      <div className="multi-view-btns">
        <div className="multi-view-btns-left">Multiview</div>
        <div className="multi-view-btns-center">
          <div className="multi-view-icons">
            <img
              src="images/camera angle.svg"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              id="singleIcon"
              onClick={(e) => toggleSingleScreen(e)}
              // onClick={toggleSingleScreen}
            />
          </div>
          <div className="multi-view-icons">
            <img
              src="images/camera angle-2.svg"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              className="active"
              id="dualIcon"
              onClick={(e) => toggleDualScreen(e)}
            />
          </div>
          <div className="multi-view-icons">
            <img
              src="images/camera angle-1.svg"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              id="overlayIcon"
              onClick={(e) => toggleOverlayPlayer(e)}
            />
          </div>
          <div className="multi-view-icons">
            <img
              src="images/camera angle-3.svg"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
            />
          </div>
        </div>
        <div className="multi-view-btns-right">
          <div className="close-multi-view" onClick={toggleMultiView}>
            Close
          </div>
        </div>
      </div>
      <div className="multi-screen-videos-wrapper">
        <div
          className={
            `${showDualScreen ? "dual-screen-multi-view " : ""}` +
            "multi-screen-player"
          }
        >
          <VideoPlayer
            playerIndex={1}
            key={"player" + 1}
            playerConfig={videoPlayerConfig1}
            enableSidebar={false}
          />
          <div className="multi-screen-mute-icon">
            <Button
              size="large"
              shape="circle"
              type="text"
              style={{ padding: "0" }}
              id="unmuteicon"
              className="hoverClass"
              onClick={(e) => setMute(1)}
            >
              {mute.player1 ? <img
                src={muteIcon}
                style={{ width: "26px", height: "26px" }}
              />: <img
                src={unMuteIcon}
                style={{ width: "26px", height: "26px" }}
              />}
              </Button>
          </div>
        </div>
        {showDualScreen && (
          <div
            className={
              `${showDualScreen ? "dual-screen-multi-view " : ""}` +
              `${showOverlayPlayer ? "dual-screen-overlay " : ""}` +
              "multi-screen-player"
            }
          >
            <VideoPlayer
              playerIndex={2}
              key={"player" + 2}
              playerConfig={videoPlayerConfig2}
              enableSidebar={false}
            />
           <div className="multi-screen-mute-icon">
             <Button
              size="large"
              shape="circle"
              type="text"
              style={{ padding: "0" }}
              id="unmuteicon"
              className="hoverClass"
              onClick={(e) => setMute(2)}
            >
              {mute.player2 ? <img
                src={muteIcon}
                style={{ width: "26px", height: "26px" }}
              />: <img
                src={unMuteIcon}
                style={{ width: "26px", height: "26px" }}
              />}
              </Button>
            </div>
          </div>
        )}
        {showDualScreen ||
          (showOverlayPlayer && (
            <div
              className={
                `${showOverlayPlayer ? "dual-screen-overlay " : ""}` +
                "multi-screen-player"
              }
            >
              <VideoPlayer
                playerIndex={2}
                key={"player" + 2}
                playerConfig={videoPlayerConfig2}
                enableSidebar={false}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
