import TwitterIcon from "@mui/icons-material/Twitter";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Col, Row } from "antd";
import axios from "axios";
import MatchDetailPlayer from "components/common/MatchDetailPlayer";
import stats from "pages/SportsFixture/Stats";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setTeams } from "store/actions/teamAction";
import { getPlayerName, getTeamFlag } from "store/utils";
import AddLBand from "../../components/common/AdLBand";
import Pip from "../../components/common/Pip";
import PipContext from "../../helpers/contexts/PipContext";
import LiveData from "../../pages/SportsFixture/LiveData.json";
import MatchInfo from "./MatchInfo";
import Recommendations from "./Recommendations";
import SportsPoll from "./SportsPoll";
import "./style.scss";
import FootballTab from "./Tab";
let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              transform: "rotateY(180deg)",
              marginRight: "6px",
              width: "12px"
            }}
          />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow - 1 && (
        <div className={className} onClick={onClick}>
          <img
            src="/images/Fill-35.svg"
            style={{
              color: "black",
              marginLeft: "6px",
              width: "12px"
              // //padding: "3px 0"
            }}
          />
        </div>
      )}
    </>
  );
};

function MatchDetailPage({ setTeams }) {
  const settings = {
    className: "slider variable-width",
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    speed: 300,
    swipeToSlide: true,
    swipeable: true,
    horrizontalSwiping: true,
    swipeToSlide: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />
  };
  const shareUrl = `https://twitter.com/intent/tweet?url=`;
  const openLink = () => {
    let finalShareUrl = shareUrl + "&hashtags=premierleague";

    window.open(finalShareUrl, "_blank");
  };

  const location = useLocation();
  const { matchId, teamSlug1, teamSlug2, isLive } = useParams();
  const [allTeams, setAllTeams] = useState([]);
  const [team1, setTeam1] = useState(null);
  const [team2, setTeam2] = useState(null);
  const [matchData, setMatchData] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const isLiveFlag = parseInt(isLive) ? true : false;
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [brandingData, setBrandingData] = useState([]);
  const [currentSelectedTab, setCurrentSelectedTab] = useState("Lineups");
  const imagePathPrefix = "https://dn8wbd49q0doa.cloudfront.net/";

  const getTeamName = (teamId) => {
    const { teams } = matchData;
    const team = teams.filter((Team) => Team["@attributes"].uID === teamId);
    if (team.length > 0) {
      return team[0].Name;
    }
    return teamId;
  };

  // const setTeamData = (matchdata, teams) => {
  //   setAllTeams(teams);
  // };

  useEffect(() => {
    const { matchdata } = matchData;
    if (matchdata) {
      const team1 = {
        teamdata: matchdata.TeamData[0],
        teamName: getTeamName(matchdata.TeamData[0]["@attributes"].TeamRef)
      };

      const team2 = {
        teamdata: matchdata.TeamData[1],
        teamName: getTeamName(matchdata.TeamData[1]["@attributes"].TeamRef)
      };
      setTeam1(team1);
      setTeam2(team2);
      setTeams([team1, team2]);
    }
  }, [matchData]);

  const setPageData = () => {
    const state = location.state;
    let { matchdata, Teams } = state || {};

    const onResponse = (feed) => {
      const { Team, MatchData } = feed;
      let filteredMatchData = MatchData.filter(
        (_item) => _item["@attributes"].uID == matchId
      );
      if (filteredMatchData.length > 0) {
        const matchdata = filteredMatchData[0];
        setMatchData({ matchdata, teams: Team });
        // setAllTeams(Team);
      }
    };

    if (matchdata && Teams) {
      setMatchData({ matchdata, teams: Teams });
      // setAllTeams(Teams);
    } else {
      axios({
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        url: "https://omo.akamai.opta.net/competition.php?competition=1&season_id=2021&feed_type=F1&user=ToTheNew&psw=ToTheNew2022&json"
      })
        .then((response) => {
          if ("SoccerFeed" in response.data) {
            onResponse(response.data.SoccerFeed.SoccerDocument);
          } else {
            onResponse(stats.SoccerFeed.SoccerDocument);
          }
        })
        .catch((error) => {
          onResponse(stats.SoccerFeed.SoccerDocument);
        });
    }
  };

  const renderScoredPlayerData = (data) => {
    if (data) {
      if (data.Goal) {
        if (data.Goal.length > 1) {
          return (
            <>
              {data.Goal &&
                data.Goal.map((goalData, key) => {
                  return (
                    <p key={key}>
                      {getPlayerName(goalData["@attributes"].PlayerRef)} -{" "}
                      <span>{goalData["@attributes"].Period}</span>
                    </p>
                  );
                })}
            </>
          );
        } else {
          return (
            <p>
              {data.Goal["@attributes"].PlayerRef} -{" "}
              <span>{data.Goal["@attributes"].Period}</span>
            </p>
          );
        }
      }
    }
  };

  const renderFootBallIcon = () => {
    return (
      <div className="row">
        <div className="wrapper">
          {/* <div className="vertical-line"></div> */}

          <div className="circle">
            <img src="/images/football-2.svg" className="football-image" />
          </div>
        </div>
      </div>
    );
  };
  const currentLiveData = LiveData.filter((data) => data.id == matchId);
  const liveVideoUrl =
    currentLiveData &&
    currentLiveData.length > 0 &&
    currentLiveData[0].state.videoUrl
      ? currentLiveData[0].state.videoUrl
      : "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/football/football-manu-vs-middlesb-fa-cup-2021-22.m3u8";
  const cam1 = currentLiveData[0].state.cam1 ? currentLiveData[0].state.cam1 : liveVideoUrl;
  const cam2 = currentLiveData[0].state.cam2 ? currentLiveData[0].state.cam2 : liveVideoUrl;
      const liveVideoPlayerConfig = {
    multiViewEnabled: true,
    players: [
      {
        playerName: "Default",
        isLive: true,
        isEncrypted: false,
        autoplay: true,
        videoId: "ttn-player1",
        videoWrapperID: "video-player-wrapper1",
        // videoUrl: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
        videoUrl: liveVideoUrl
        // "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/football/football-manu-vs-middlesb-fa-cup-2021-22.m3u8"
        // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
      },
      {
        playerName: "Stream1",
        isLive: true,
        isEncrypted: false,
        autoplay: true,
        videoId: "ttn-player2",
        videoWrapperID: "video-player-wrapper2",
        // videoUrl: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
        videoUrl: cam1
        // "https://ttn-ott-videoready-transcoding.s3.amazonaws.com/vr-sports/output/football/football-manu-vs-middlesb-fa-cup-2021-22.m3u8"
        // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
      },
      {
        playerName: "Stream2",
        isLive: true,
        isEncrypted: false,
        autoplay: true,
        videoId: "ttn-player3",
        videoWrapperID: "video-player-wrapper3",
        // videoUrl: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
        videoUrl: cam2
        // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
      },
      {
        playerName: "Stream3",
        isLive: true,
        isEncrypted: false,
        autoplay: true,
        videoId: "ttn-player4",
        videoWrapperID: "video-player-wrapper4",
        // videoUrl: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
        videoUrl: liveVideoUrl
        // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
      }
    ]
  };
  const videoPlayerConfig = {
    multiViewEnabled: true,
    players: [
      {
        playerName: "Default",
        isLive: false,
        isEncrypted: false,
        autoplay: true,
        videoId: "ttn-player1",
        videoWrapperID: "video-player-wrapper1",
        videoUrl:
          "https://bitmovin-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
      }
    ]
  };

  useEffect(() => {
    setPageData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible, isAdVisible]);

  const setBrandingDataForDetailPage = (data) => {
    setBrandingData(data);
  };
  const listenToScroll = () => {
    const heightToHideFrom = document.getElementById("pipVideo").offsetTop;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const dp = document.getElementsByClassName("player-height");
    const leftband = document.getElementById("leftBanner");
    const fulEl = document.fullscreenElement;
    const display = document.getElementsByClassName("detail-player-wrapper");

    if (fulEl && fulEl.getAttribute("id") === "player-with-lband") {
      if (!p && dp && dp.length) {
        leftband.setAttribute("style", "width: 20%");
        dp[0].style.transition = "height 500ms linear";
        dp[0].style.height = "85vh";
      } else {
        leftband.setAttribute("style", "width: 0");
        dp[0].style.height = "100vh";
      }
    } else {
      leftband?.removeAttribute("style");
    }
    if (winScroll > heightToHideFrom) {
      if (dp && dp.length) {
        dp[0].style.height = "36vh";
        dp[0].style.minHeight = "0";
        display[0].style.minHeight = "0";
        dp[0].style.transition = "none";
      }
      setIsVisible(false);
    } else {
      dp[0].style.display = "flex";
      if (isAdVisible) {
        dp[0].style.height = "55vh";
        dp[0].style.minHeight = "450px";
        display[0].style.minHeight = "450px";
        setIsVisible(true);
      } else {
        dp[0].style.height = "85vh";
        dp[0].style.minHeight = "450px";
        display[0].style.minHeight = "450px";
        setIsVisible(true);
      }
    }
  };
  const setSelectedTab = (tabName) => setCurrentSelectedTab(tabName);

  return (
    <Fragment>
      <PipContext.Provider
        value={{ isVisible, setIsVisible, isAdVisible, setIsAdVisible }}
      >
        <Pip>
          <AddLBand isForMatchDetailPlayer={true}>
            <MatchDetailPlayer
              videoPlayerConfig={
                isLiveFlag ? liveVideoPlayerConfig : videoPlayerConfig
              }
              setBrandingDataForDetailPage={setBrandingDataForDetailPage}
              matchId={matchId}
            />
          </AddLBand>
        </Pip>
        {team1 && team2 && matchData && (
          <>
            <MatchInfo
              team1={team1}
              team2={team2}
              matchdata={matchData}
              isLive={isLiveFlag}
            />
            {isLiveFlag && (
              <div style={{ position: "relative", marginTop: "50px" }}>
                <div
                  className="centerContent twitter-widget"
                  onClick={() => openLink(shareUrl)}
                >
                  <TwitterIcon sx={{ color: grey[100] }} />
                  <p>#UCL</p>
                </div>
                <Row style={{ position: "relative", marginBottom: "50px" }}>
                  {brandingData.length > 0 && (
                    <img
                      src={
                        imagePathPrefix + brandingData[0].partnerBackgroundLogo
                      }
                      className={`partnerBackgroundLogo ${currentSelectedTab === 'Stats' ? 'partnerBackgroundLogo-stats': ''}`}
                    />
                  )}
                  <Col
                    span={22}
                    offset={1}
                    className="match-preview-container"
                    style={{
                      background:
                        brandingData.length > 0
                          ? `${brandingData[0].partnerThemeCode}`
                          : `url(${"/images/bg.png"})`,
                      position: "relative",
                      borderRadius: "8px"
                    }}
                  >
                    <Row>
                      <Col
                        span={10}
                        className="grid-conatiner grid-conatiner-1"
                      >
                        {brandingData.length ? (
                          <div className="top-section-logo">
                            <img
                              src={
                                imagePathPrefix +
                                brandingData[0].partnerScorecardLogo
                              }
                            />
                          </div>
                        ) : null}
                        <div className="score-section">
                          <div className="team-score">
                            <div>
                              <div className="team-score-a">
                                {/* <p>{team1.teamName}</p> */}
                                <p>
                                  {matchId == "g2257442"
                                    ? "Manchester City"
                                    : "Manchester United"}
                                </p>
                                <img
                                  src={getTeamFlag(
                                    team1.teamdata["@attributes"].TeamRef
                                  )}
                                  className="flag-img"
                                />
                              </div>
                              <div className="goal-container-left-div">
                                {/* {renderScoredPlayerData(team1.teamdata)} */}
                              </div>
                            </div>
                            <span className="score-point">0 </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="middle-football-icon">
                          {renderFootBallIcon()}
                          <p>
                            {matchId == "g2257442" ? "Chelsea" : "Middlesrough"}{" "}
                            won toss
                          </p>
                        </div>
                      </Col>
                      <Col
                        span={10}
                        className="grid-conatiner grid-conatiner-2"
                      >
                        <div className="score-section">
                          <div className="team-score">
                            <span className="score-point">
                              {/* {team2.teamdata["@attributes"].Score} */}0
                            </span>
                            <div>
                              <div className="team-score-b">
                                <img
                                  src={getTeamFlag(
                                    team2.teamdata["@attributes"].TeamRef
                                  )}
                                  className="flag-img"
                                />
                                <p>
                                  {matchId == "g2257442"
                                    ? "Chelsea"
                                    : "Middlesrough"}
                                </p>
                              </div>
                              <div className="goal-container-right-div">
                                {/* {renderScoredPlayerData(team2.teamdata)} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={24} className="goal-container">
                        <div></div>
                      </Col>
                    </Row>
                    <Grid>
                      <FootballTab team1={team1} team2={team2} setSelectedTab={setSelectedTab} isBrandingAvailable={brandingData.length > 0}/>
                    </Grid>
                  </Col>
                </Row>
                <SportsPoll team1={team1} team2={team2} matchdata={matchData} />
              </div>
            )}
            {brandingData.length ? (
              <Row>
                <Col span={22} offset={1}>
                  <a href={brandingData[0].ctaUrl} target="_blank">
                    <img
                      src={
                        imagePathPrefix + brandingData[0].partnerPromotionBanner
                      }
                      style={{ width: "100%" }}
                    />
                  </a>
                </Col>
              </Row>
            ) : null}
            <div style={{ position: "relative", margin: "0px 10px 30px" }}>
              <Recommendations />
            </div>
          </>
        )}
      </PipContext.Provider>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setTeams: (teams) => dispatch(setTeams(teams))
});

export default connect((state) => {
  return {};
}, mapDispatchToProps)(MatchDetailPage);
