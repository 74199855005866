const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "current-teams":
        if(action.state) return [...action.state];
      else [ ...state];
    default:
      return state;
  }
};

export default reducer;