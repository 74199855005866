import { Fragment, useEffect, useState , useContext} from "react";
import { connect } from "react-redux";
import VideoPlayerControls from "./VideoPlayerControls";
import PipContext from "../../../helpers/contexts/PipContext";
import loader from "../../../images/vr_loader.gif"


function VideoPlayer(props) {
  const {
    playerIndex,
    playerConfig,
    enableSidebar,
    enabledSidebar,
    resetMainPlayer,
    setTLMPlayer,
    toggleMultiViewSidebar,
    pushIsMainMethodToArray,
    isTLMEnabled,
    tlmMarkers,
    toggleMultiView,
    showMultiView,
    pipFullScreenHandler,
    brandingData
  } = props;

  const {
    isLive,
    isEncrypted,
    videoUrl,
    muted,
    videoId,
    videoWrapperID,
    autoplay,
    contentId,
  } = playerConfig;
  let showControls, showGoLive;

  if(playerConfig.showControls === undefined) showControls = true;
  if(playerConfig.showGoLive) showGoLive = true;
  else showGoLive = false;
  const {isVisible} = useContext(PipContext);


  const [videoPlayer, setVideoPlayer] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isMainPlayer, setIsMainPlayer] = useState(playerIndex == 0);
  // const [tlmConfig, setTLMConfig] = useState({enabled: false});
  const [test, setTest] = useState(false);
  // const [watchTogetherFlag, setWatchTogetherFlag] = useState(false);
  // const [streamToken, setStreamToken] = useState(null);

  const videoPlayerEvents = window.TTNPlayer.listPlayerEvents;
  const { NATIVE_EVENTS, CUSTOM_EVENTS } = videoPlayerEvents;
  // console.log("events : ", videoPlayerEvents);

  // const handleConnectClick = () => {
  //   setStreamToken();
  //   setWatchTogetherFlag(true);
  // };
  // const togglesidebar = () => {
  //   setSidebarOpen(!sidebarOpen);
  // }

  pushIsMainMethodToArray && pushIsMainMethodToArray(setIsMainPlayer);

  useEffect(() => {
    const playerCallback = (eventName, eventData) => {
      switch (eventName) {
        case NATIVE_EVENTS.ENDED:
          if (!isLive) {
            const videoPlayerConfig = {
              playerName: "Default",
              isLive: false,
              isEncrypted: false,
              autoplay: true,
              videoId: "ttn-player-tlm",
              videoWrapperID: "video-player-wrapper-tlm",
              videoUrl: videoUrl,
              contentId: contentId
              // videoUrl:
              //   "https://bitmovin-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
              // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
            };
            const contentIndex = tlmMarkers.findIndex(
              (tlmMarker) => tlmMarker.id == contentId
            );
            const newContent = tlmMarkers[contentIndex + 1];
            videoPlayer.destroy();
            setTLMPlayer({ enabled: false, playerConfig: "" });
            if (newContent) {
              videoPlayerConfig.contentId = newContent.id;
              videoPlayerConfig.videoUrl =
                newContent.metadata.emfAttributes.videoURL;
              setTLMPlayer({ enabled: true, playerConfig: videoPlayerConfig });
            }
          }
          break;
        case NATIVE_EVENTS.SEEKBAR_UPDATE:
          setShowLoader(false);
          break;  
      }
    };
    let muted;
    if (
      localStorage.getItem("muted") !== null &&
      videoWrapperID.toString() === "video-player-wrapper1"
    ) {
      if (JSON.parse(localStorage.getItem("muted"))) muted = true;
      else muted = false;
    } else {
      if (videoWrapperID.toString() === "video-player-wrapper1") muted = false;
      else muted = true;
    }

    let id = document.getElementById('video-player-wrapper1');
    let video = document.getElementById(id?.children[2]?.children[0]?.id);
    if(showControls == undefined && video) {
      video.muted = true;
    } else if(video && localStorage.getItem("muted") !== null){
      video.muted = JSON.parse(localStorage.getItem("muted"));
    }

    const playerOptions = {
      muted: muted,
      preload: "",
      autoplay: autoplay,
      videoId: videoId,
      videoWrapperID: videoWrapperID,
      playerMetadata: {
        url: videoUrl,
        isLive: isLive,
        licenseUrl: "",
        isEncrypted: isEncrypted,
        startTime: Date.now() - 1800000,
        playerCallback,
        customHeaders: {
          // "x-playback-session-id": "eb621450-c9e5-11ec-91d7-af709c77e36e"
        }
      }
    };

    const videoPlayer = new window.TTNPlayer(playerOptions);
    setVideoPlayer(videoPlayer);

    if (!window.VIDEO_PLAYERS) {
      window.VIDEO_PLAYERS = {};
    }

    window.VIDEO_PLAYERS[playerIndex] = videoPlayer;

    // console.log("Player ::", videoPlayer);
    document.getElementById("video-player-wrapper-tlm") &&
      document
        .getElementById("video-player-wrapper-tlm")
        .classList.add("main-player");

    return () => {
      videoPlayer.destroy();
    };
  }, []);

  useEffect(() => {
    try {
      if (isMainPlayer) {
        videoPlayer?.muted(JSON.parse(localStorage.getItem("muted")));
      } else {
        videoPlayer?.muted(true);
        videoPlayer?.play();
      }
    } catch (error) {}
  }, [isMainPlayer]);

  useEffect(() => {
    if (!isTLMEnabled && isMainPlayer && videoPlayer) {
      videoPlayer.play();
    }
  }, [isTLMEnabled]);

  const handlePlayerClick = () => {
    if (isMainPlayer) return;
    resetMainPlayer();
    setIsMainPlayer(true);
  };

  return (
    <Fragment>
      <div
        id={videoWrapperID}
        className={
          `${isMainPlayer ? "main-player " : ""}` + "video-player-wrapper"
        }
        onClick={handlePlayerClick}
      >
        { showControls &&<div className="stream-name">{playerConfig.playerName}</div>}
        { showLoader && <div className="video-loader"><img src={loader} /></div> }
        { showControls && <VideoPlayerControls
          player={videoPlayer}
          playerIndex={playerIndex}
          isMainPlayer={isMainPlayer}
          enableSidebar={enableSidebar}
          enabledSidebar={enabledSidebar}
          videoWrapperID={videoWrapperID}
          videoPlayerEvents={videoPlayerEvents}
          toggleMultiViewSidebar={toggleMultiViewSidebar}
          setTLMPlayer={setTLMPlayer}
          isTLMEnabled={isTLMEnabled}
          showGoLive={showGoLive}
          isLive={isLive}
          toggleMultiView={toggleMultiView}
          pipFullScreenHandler={pipFullScreenHandler}
          brandingData={brandingData}
        />}
      </div>
    </Fragment>
  );
}

const mapDispatchToProps = () => ({});

export default connect((state) => {
  const { tlmMarkers } = state;
  return {
    tlmMarkers
  };
}, mapDispatchToProps)(VideoPlayer);
