const statTabData = [
  {
    teamA: "29",
    statName: "Shots",
    teamB: "7"
  },

  {
    teamA: "8",
    statName: "Shots on target",
    teamB: "5"
  },
  {
    teamA: "669",
    statName: "Passes",
    teamB: "400"
  },
  {
    teamA: "63%",
    statName: " Possession",
    teamB: "37%"
  },
  {
    teamA: "88%",
    statName: "Pass accuracy",
    teamB: "80%"
  },
  {
    teamA: "6",
    statName: "Fouls",
    teamB: "3"
  },
  {
    teamA: "1",
    statName: "Yellow cards",
    teamB: "0"
  },
  {
    teamA: "0",
    statName: "Red cards",
    teamB: "0"
  },
  {
    teamA: "3",
    statName: "Offsides",
    teamB: "1"
  },
  {
    teamA: "5",
    statName: "Corners",
    teamB: "3"
  }
];
export default statTabData;
