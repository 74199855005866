import { Grid } from "@mui/material";
import { liveStreamData } from "components/common/HomePage/LiveStreamingdata";
import moment from "moment";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { getTeamFlag, stringToSlug } from "store/utils";
import "./style.scss";
// import liveCardData from "./liveCardData.json";

fixtureCards.propTypes = {
  _item: PropTypes.object,
  //   key: PropTypes.number,
  Teams: PropTypes.array,
  matchType: PropTypes.string,
  sports: PropTypes.string,
  live: PropTypes.bool,
  competitionName: PropTypes.object,
  renderDetailPage: PropTypes.func
};

export default function fixtureCards({
  _item,
  //   key,
  Teams,
  matchType,
  sports,
  redirect,
  live,
  competitionName,
  renderDetailPage
}) {
  const MATCH_TYPE = {
    UPCOMING: "PreMatch",
    HIGHLIGHTS: "FullTime"
  };

  const [teamName, setTeamName] = useState("");

  let clubName = "";

  const getTeamName = (teamName) => {
    // console.log(teamName, "teamNameteamName", Teams)

    Teams.map((_item) => {
      if (_item["@attributes"].uID === teamName) {
        clubName = _item.Name;
      }
    });
    return clubName;
  };

  const renderMatchSchedule = () => {
    if (matchType === MATCH_TYPE.HIGHLIGHTS) {
      return "Completed";
    } else if (matchType === MATCH_TYPE.UPCOMING) {
      return "Upcoming";
    } else {
      return "Live";
    }
  };

  const getUrl = () => {
    // console.log("Checking");
    if (matchType === MATCH_TYPE.UPCOMING) return null;
    if (_item?.TeamData?.length) {
      localStorage.removeItem('team');
      const teamName1 = getTeamName(_item.TeamData[0]["@attributes"].TeamRef);
      const teamName2 = getTeamName(_item.TeamData[1]["@attributes"].TeamRef);
      const matchId = _item["@attributes"].uID;
      return {
        pathname: `matchdetail-${stringToSlug(teamName1)}-vs-${stringToSlug(
          teamName2
        )}-${matchId}-${live ? 1 : 0}`,
        // search: "?sort=name",
        // hash: "#the-hash",
        state: { matchdata: _item, Teams }
      };
    } else {
      return {};
    }
  };

  return (
    <Fragment>
      <Link to={getUrl()}>
        <Grid
          className="fixture-main-card"
          // onClick={() => redirect(_item, Teams)}
        >
          <div className="top-wrapper">
            <div style={{ display: "flex" }}>
              <div className="sportCategory">
                <div className="sportCategory-text">{sports}</div>
              </div>
              {live ? (
                <div className="live-badge">
                  <div className="icon"></div>
                  <div className="live-text">{renderMatchSchedule()}</div>
                </div>
              ) : null}
            </div>

            <p className="fixture-time">
              {live
                ? moment().format("LLL")
                : null
              // : moment(_item.MatchInfo.Date).format("LLL")
              }{" "}
            </p>
          </div>
          <p className="league-name">{competitionName.competition_name}</p>
          <div className="mid-wrapper">
            <div className="left-side">
              <img
                src={getTeamFlag(_item.TeamData[0]["@attributes"].TeamRef)}
                className="flag-img"
              />
              <p className="team-name">
                {getTeamName(_item.TeamData[0]["@attributes"].TeamRef)}
              </p>
              <p className="team-goal">
                {_item.TeamData[0]["@attributes"].Score}
              </p>
            </div>
            <div className="mid-side">
              <i>Vs</i>
              <div style={{ marginTop: "14px" }}>
                <p className="match-type">
                  <span>{_item.MatchInfo["@attributes"].MatchType}</span>
                </p>
                <p className="game-time">
                  Time: {live ? `22:04` : _item.MatchInfo["@attributes"].Period}
                </p>
              </div>
            </div>
            <div className="right-side">
              <img
                src={getTeamFlag(_item.TeamData[1]["@attributes"].TeamRef)}
                className="flag-img"
              />
              <p className="team-name">
                {getTeamName(_item.TeamData[1]["@attributes"].TeamRef)}
              </p>
              <p className="team-goal">
                {_item.TeamData[1]["@attributes"].Score}
              </p>
            </div>
          </div>

          {/* <div className="lower-wrapper">
                <div className="team-left">
                    {
                        renderScoredPlayerData(_item.TeamData[0])
                    }
                </div>
                <div className="team-right">
                    {
                        renderScoredPlayerData(_item.TeamData[1])
                    }
                </div>
            </div> */}
        </Grid>
      </Link>
    </Fragment>
  );
}
