import React, {memo} from "react";
import {Button, Col, message, Row, Tooltip} from "antd";
import socialProfiles from "./social-profiles.json";

/**
 * Social share list component
 * @description Used to share the specified url or link to social platforms like
 * Facebook, WhatsApp and Twitter.
 * @props url {string}
 * @type {React.NamedExoticComponent<{readonly url?: *}>}
 */
const SocialShare = memo(({url}) => {

  const copyOrOpenLink = ({id, shareUrl}) => {
    if (id === "copy") {
      return navigator.clipboard.writeText(url).then(d => {
        message.success("Link copied to clipboard!").then();
      }).catch(err => console.log(err));
    }
    let finalShareUrl = shareUrl + encodeURIComponent(url);
    // if(id == "twitter"){
    //   finalShareUrl += "&hashtags=AskTheExpert"
    // }
    window.open(finalShareUrl, "_blank")
  }

  return (
    <Row gutter={[10, 0]} key={socialProfiles.id}>
      {socialProfiles?.map((socialProfile) => (
        <Col span={6}>
          <Tooltip title={socialProfile.name}>
            <Button key={socialProfile.id} shape={"circle"} type={"link"} onClick={() => copyOrOpenLink(socialProfile)}>
              <img src={socialProfile.imageSource} alt={socialProfile.name} className={"anticon"}/>
            </Button>
          </Tooltip>
        </Col>
      ))}
    </Row>
  )
}, (p, n) => p.url !== n.url);

export default SocialShare;