import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Card from "./Card";
import Carousel from "react-grid-carousel";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Col, Row } from "antd";
import SocialShare from "../SocialShare";
// import "antd/dist/antd.css";
import { Tooltip } from "antd";

const settings = {
  className: "slider variable-width",
  slidesToShow: 1,
  slidesToScroll: 5,
  variableWidth: true,
  infinite: false,
  speed: 1300,
  horrizontalSwiping: true,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        height: "auto",
        width: "35px",
        background: "white"
      }}
      onClick={onClick}
    />
  );
}
export default function Description() {
  const url = `https://www.tothenew.com/`;

  const history = useHistory();

  const getCardDetail = (item) => {
    history.push(`/details/${item.id}`);
  };

  const [bannerData, setBannerData] = useState([]);
  const [lowerCardData, setLowerCardData] = useState([]);
  let { id } = useParams();

  const clickOnBack = () => {
    history.push(`/home`);
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/content/${id}`
    })
      .then((response) => {
        console.log(response, "Response in Description------");
        setBannerData(response.data.data.meta);
      })
      .catch((error) => {
        console.log(error, "Error in Description--------");
      });
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: "https://vrsports-tothenew.videoready.tv/gateway/api-provider/v1/search?search=W"
    })
      .then((response) => {
        setLowerCardData(response.data.data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <Fragment>
      <div id="detail-page" className="upper-container">
        <div className="description-container">
          <div
            className="movies-banner"
            style={{
              backgroundImage: `url(${
                `https://dn8wbd49q0doa.cloudfront.net/` +
                bannerData.coverImage
              })`
            }}
          ></div>
          <div>
            <img
              src="/images/Group 9495.svg"
              onClick={clickOnBack}
              className="back-icon"
              style={{ width: "66px", height: "58px", zIndex: "1" }}
            />
          </div>
          <Row className="movie-details-section">
            <Col span={5} className="column left">
              <img
                src={
                  `https://dn8wbd49q0doa.cloudfront.net/` +
                  bannerData.posterImage
                }
                className="movie-thumbNail"
              />
            </Col>
            <Col span={15} className="column center">
              <div className="movie-title">{bannerData.title}</div>
              <div className="movie-description">{bannerData.description}</div>
              <table className="genre-and-rating">
                <tr>
                  <td>Genre: </td>
                  {bannerData &&
                    bannerData.genre?.length > 0 &&
                    bannerData.genre.map((genres, index) => (
                      <td key={index}>{genres}</td>
                    ))}
                </tr>
                <tr>
                  <td>Date: </td>
                  <td> {bannerData.dateCreated}</td>
                </tr>
                <tr>
                  <td>Language: </td>
                  {bannerData &&
                    bannerData.language?.length > 0 &&
                    bannerData.language.map((language, index) => (
                      <td key={index}>{language}</td>
                    ))}
                </tr>
              </table>
              <div className="add-and-share-icon">
                <Tooltip placement="top" title={"Add to WatchList"}>
                  <div className="icon">
                    <img src="/images/watchlist.svg" />
                  </div>
                </Tooltip>
                <SocialShare url={url} />
              </div>
              <div
                className="banner-watch-now"
                style={{ justifyContent: "space-around" }}
              >
                <div className="banner-play">
                  <img
                    src="/images/play.png"
                    style={{ width: "17.5px", height: "20px" }}
                  />
                </div>
                <p style={{ margin: "20px 20px 20px 0" }}>Watch now</p>
              </div>
            </Col>
          </Row>

          <div className="movie-gradient"></div>
        </div>
      </div>
      <div
        id="lower-div-container"
        className="small-slider"
        style={{ marginTop: "9%" }}
      >
        <div className="category-header">
          <div>
            <Slider {...settings}>
              {lowerCardData?.length &&
                lowerCardData.map((items, index) => (
                  <>
                    <div className="category-header-text">
                      You May Also Like
                    </div>
                    <div className="category-card">
                      <div style={{ width: 250 }}>
                        <Card
                          // key={`lowerCard` + index}
                          image={items.posterImage}
                          title={items.title}
                          getCardDetail={getCardDetail}
                          item={items}
                        />
                      </div>
                    </div>
                  </>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
