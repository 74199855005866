import { Layout } from "antd";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  AuthRoute,
  Header,
  Loader,
  PrivateRoute,
  Footer
} from "components/common";
import * as Pages from "pages";
import * as Component from "components/common";

const { Content } = Layout;

export default function Router() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Loader />
      <Layout style={{ minHeight: "100vh" }}>
        <Header />
        <Content
          style={{ paddingBottom: "25px", backgroundColor: "rgb(0 17 34)" }}
        >
          <Switch>
            {/* Unauth routes start here */}
            <Route exact path={["/home", "/", ""]}>
              <Pages.Home />
            </Route>
            <Route
              path="/details/:id"
              render={(props) => (
                <Component.Description key={props.match.params.id} {...props} />
              )}
            />
            {/* <Component.Description/>
						</Route> */}
            <Route path="/matchdetail">
              <Pages.MatchDetailPage />
            </Route>
            <Route path="/matchdetail-:teamSlug1-vs-:teamSlug2-:matchId-:isLive">
              <Pages.MatchDetailPage />
            </Route>
            <Route path="/voddetail-:vodid-:isLowerCard">
              <Pages.VODDetailPage />
            </Route>
            {/* Unauth routes ends here */}
            <AuthRoute exact path="/login">
              <Pages.Login />
            </AuthRoute>
            <AuthRoute exact path="/signup">
              <Pages.Signup />
            </AuthRoute>
            {/* authenticated pages starts */}
            <PrivateRoute exact path="/tasks">
              <Pages.Tasks />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Pages.Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/tasks-list">
              <Pages.TasksList />
            </PrivateRoute>
            {/* authenticated pages ends */}
            <Route path="*">
              <Pages.NotFound />
            </Route>
          </Switch>
        </Content>
        <Footer />
      </Layout>
    </BrowserRouter>
  );
}
