import React, { useState } from "react";
import "./style.scss";
import { Button, Input } from "antd";
// import "antd/dist/antd.css";

export default function InviteBySMS(props) {
  const { enableWatchParty } = props;
  const [roomName, setRoomName] = useState(null);

  return (
    <div className={"invite-by-sms"}>
      <span>Enter Party Name</span>
      <div>
        <Input
          size="large"
          placeholder="Enter"
          style={{ marginTop: "15px", marginLeft: 0 }}
          onChange={(e) => {
            setRoomName(e.target.value);
          }}
        />
        <button
          className="create-party"
          onClick={() => {
            if (!roomName) {
              return;
            }
            enableWatchParty(roomName);
          }}
        >
          <p>Create &amp; Start Watch Party</p>
        </button>
      </div>
    </div>
  );
}
