import { PoweroffOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';
import { userLogout } from 'store/userSlice';
import Grid from '@mui/material/Grid';
import './style.scss'
import { Button, FormGroup, Stack, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import './style.scss'

export default (props) => {

    return (
        <div className="footer">
            <div style={{ display: 'flex', justifyContent: 'space-between',padding:"60px" }}>
                <Grid item={true} md={7} className='footer-list'>
                    <ul className='footer-text'>
                        <li>
                            About VR
                        </li>
                        <li>
                            Terms Of Use
                        </li>
                        <li>
                            Privacy Policy
                        </li>
                        <li>
                            FAQ
                        </li>
                        <li>
                            Feedback
                        </li>
                        <li>
                            Help
                        </li>
                    </ul>
                    <Grid mt={4}>
                        <p className='footer-text'>
                            Connect with us -
                        </p>
                        <a href='' target={'_blank'} className="socialLinks"><img src='/images/facebook.svg' alt="Instagram" /></a>
                        <a href='' target={'_blank'} className="socialLinks"><img src='/images/youtube.svg' alt="Instagram" /></a>
                    </Grid>
                </Grid>
                <Grid item={true} md={5} className="download-opt">
                    <p className='footer-text' >
                        Download our VR App -
                    </p>
                    <span>
                        <img className='google-badge' src="/images/google-badge.png" onClick={()=> window.open("https://qa-vr-sports.qa6.tothenew.net/")} />
                        <img className='badges' src="/images/apple-badge.png" />
                    </span>
                    <p style={{ marginTop: '28px' }}>© 2022 VideoReady. All Rights Reserved.</p>
                </Grid>
            </div>
        </div>
    );
}
