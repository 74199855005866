import { Col, Row } from "antd";
import { useEffect, useState, useContext } from "react";
// import VideoPlayerControls from "./.VideoPlayerControls";
import { Button } from "antd";
import WatchParty from "pages/MatchPlayer/WatchParty/WatchParty";
import MultiViewPlayer from "../MultiViewPlayer";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./style.scss";
import PipContext from "../../../helpers/contexts/PipContext";
import MultiScreenView from "../MultiScreenView";
import axios from "axios";

export default function MatchDetailPlayer({ videoPlayerConfig, setBrandingDataForDetailPage ,matchId}) {
  const { players } = videoPlayerConfig;
  const [brandingData, setBrandingData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  // const [showMultiViewIcons, setShowMultiViewIcons] = useState(false);
  const [enabledSidebar, setEnabledSidebar] = useState(null);
  const [tlmConfig, setTLMConfig] = useState({
    enabled: false,
    playerConfig: null
  });
  const [isTLMEnabled, setTLMEnabled] = useState(false);
  const [showMultiView, setShowMultiView] = useState(false);
  const [isPIPFullScreen, setPIPFullScreen] = useState(false);
  const {isVisible} = useContext(PipContext);

  const enableSidebar = (enabledState, enabledView) => {
    setShowSidebar(enabledState);
    if (enabledState) {
      setEnabledSidebar(enabledView);
    } else {
      setEnabledSidebar(null);
    }
  };
  useEffect(() => {
    if(matchId  == "g2257442"){
      fetchBrandingData();
    }
  }, []);

  useEffect(() => {
    if (tlmConfig.enabled) {
      setTLMEnabled(true);
    } else {
      setTLMEnabled(false);
    }
  }, [tlmConfig]);

  useEffect(() => {
    if(showMultiView) {
      document.getElementsByTagName("body")[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName("body")[0].style.overflow = 'visible';
    }
  }, [showMultiView])

  const fetchBrandingData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://vrsports-tothenew.videoready.tv/gateway/editorial/branding/list"
      });
      if (response?.data) {
        if(response.data.data[0].active){
          setBrandingData(response.data.data);
          setBrandingDataForDetailPage(response.data.data);
        }
      }
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const toggleMultiView = () => {
    setShowMultiView(!showMultiView);
  }

  const pipFullScreenHandler = (flag) => {
    setPIPFullScreen(flag)
  }
  return (
    <>
      <Row wrap={false} className="player-height">
        <Col
          flex={showSidebar && enabledSidebar == "WatchParty" ? 73 : 100}
          // span={showSidebar && enabledSidebar == "WatchParty" ? 18 : 24}
          className="h-100 detail-player-wrapper"
        >
          {players && players.length > 1 && (
            <MultiViewPlayer
              enableSidebar={enableSidebar}
              enabledSidebar={enabledSidebar}
              players={players}
              setTLMPlayer={setTLMConfig}
              isTLMEnabled={isTLMEnabled}
              toggleMultiView={toggleMultiView}
              showMultiView={showMultiView}
              // showMultiView={showMultiView}
              pipFullScreenHandler={pipFullScreenHandler}
              brandingData={brandingData}
            />
          )}
          {players && players.length === 1 && (
            <VideoPlayer
              playerIndex={0}
              key={"player" + 0}
              playerConfig={players[0]}
              enableSidebar={enableSidebar}
              enabledSidebar={enabledSidebar}
              addPlayerRefToList={() => {}}
              resetMainPlayer={() => {}}
              setTLMPlayer={setTLMConfig}
              isTLMEnabled={isTLMEnabled}
              pipFullScreenHandler={pipFullScreenHandler}
              brandingData={brandingData}

            />
          )}
          {showMultiView && <MultiScreenView toggleMultiView={toggleMultiView}/>}
          {tlmConfig.enabled && (
            <VideoPlayer
              playerConfig={tlmConfig.playerConfig}
              playerIndex={0}
              setTLMPlayer={setTLMConfig}
              isTLMEnabled={isTLMEnabled}
              enableSidebar={enableSidebar}
              enabledSidebar={enabledSidebar}
              pipFullScreenHandler={pipFullScreenHandler}
              brandingData={brandingData}
            />
          )}
        </Col>
        <Col
          className={`${
            showSidebar && enabledSidebar == "WatchParty"
              ? "h-100"
              : "h-100 display-none"
          } ${!isVisible && !isPIPFullScreen ? "display-none": ""}`}
          flex={27}
          style={{ minWidth: "27%" }}
        >
          <div
            style={{ height: "100%" }}
            className={`${
              enabledSidebar == "WatchParty"
                ? "watch-party-sidebar"
                : "watch-party-sidebar display-none"
            }`}
          >
            <WatchParty enableSidebar={enableSidebar} />
          </div>
        </Col>
      </Row>
    </>
  );
}
