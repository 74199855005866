import React, { useEffect, useRef, useState } from "react";
import { timelineMarkerMockDAta } from "assets/tlm.assest.data";
import "./style.scss";

export default function TLMMarker(props) {
  const {
    left,
    keyMoment,
    tl_marker,
    registerPopup,
    closeAllTLMPopups,
    setTLMPlayer,
    player,
    index
  } = props;
  const marginRight = left > 95 ? "100px" : "";
  const marginLeft = left < 10 ? "5.20vw" : (left > 90 && left < 95) ? "-7vw" : left > 95 ? "-10vw" : "";
  const [showPopup, setShowPopup] = useState(false);
  const contentId = keyMoment.metadata.contentId;

  const getMarkerCSS = (tlMarker) => {
    return (
      timelineMarkerMockDAta.find(
        (obj) =>
          obj.marker_id.toLowerCase() == tlMarker.split("|")[0].toLowerCase()
      ) || null
    );
  };
  const markerCSS = getMarkerCSS(tl_marker);
  const handleTLMClick = () => {
    closeAllTLMPopups();
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    registerPopup(setShowPopup);
  }, []);

  const videoPlayerConfig = {
    playerName: "Default",
    isLive: false,
    isEncrypted: false,
    autoplay: true,
    videoId: "ttn-player-tlm",
    videoWrapperID: "video-player-wrapper-tlm",
    contentId: contentId
    // videoUrl:
    //   "https://bitmovin-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
    // videoUrl: "https://origin-irp-vos360-apac-b-01.vos360.video/Content/HLS/Live/channel(5a66f9f6-dde2-7395-4d55-6620986e90ac)/index.m3u8"
  };

  const handleThumbnailClick = (keyMoment) => {
    localStorage.removeItem('team');
    videoPlayerConfig.videoUrl= keyMoment.metadata.emfAttributes.videoURL;
    player.pause();
    videoPlayerConfig.showGoLive = true;
    setTLMPlayer({ enabled: true, playerConfig: videoPlayerConfig });
  };

  return (
    // const margin = left > 85 ? '4%' : '';
    <div
      className="timeline-marker cursor--pointer"
      style={{
        left: left + "%",
        backgroundColor: "#" + markerCSS.background_colour,
        fontSize: markerCSS.letter_size + "rem",
        color: "#" + markerCSS.title_colour,
        width: markerCSS.size + "px",
        height: markerCSS.size + "px",
      }}
      posterurl={keyMoment.metadata.emfAttributes.thumbnail}
      videourl="//commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
      onClick={() => {
        handleTLMClick();
      }}
    >
      <img src = {markerCSS.image}/>
      {showPopup && (
        <div
          className="tlmPopup"
          onClick={() => handleThumbnailClick(keyMoment)}
          style={{
            // marginRight: marginRight,
            marginLeft: marginLeft}}
        >
          <img src={keyMoment.metadata.emfAttributes.tv_background_image}/>
          <span className="tlm_description">
            {keyMoment.metadata.shortDescription}
          </span>
        </div>
      )}
    </div>
  );
}
